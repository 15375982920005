import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Checkbox from '@material-ui/core/Checkbox';
import { CONFIG } from '../../../../../Utils/config';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { CreateUser } from '../CreateUser';
import { Link } from "react-router-dom";
import './AssignAgent.css';
import { Pagination } from '../../../../Shared/Pagination';

export default class AssignAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
            storeId: props.storeID,
            // agentID: props.agentID,
            isLoading: false,
            selectedAgentIDs: props.agentIDs ? props.agentIDs.split(',').map(Number) : []
        }
        this.getSellers = this.getSellers.bind(this);
        this.updatePropertyAgent = this.updatePropertyAgent.bind(this);
    }

    componentDidMount() {
        const { isOpen } = this.state;
        if (isOpen) {
            this.getSellers();
        }
    }

    toggleAgentModalClose = () => {
        this.setState({ isOpen: false, isLoading: false }, () => {
            this.props.parentMethod();
        });
    }

    getSellers() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.setState({ searchSellerError: '' });

        axios.get(CONFIG.API_URL + 'admin/agents')
            .then(response => {
                let result = response.data;
                this.setState({
                    agents: result.Agents
                });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                    const searchSellerError = err.response.data;
                    this.setState({ searchSellerError });
                }
                else {
                    const searchSellerError = "Something went wrong.";
                    this.setState({ searchSellerError });
                }
            });
    }

    onChangeAgent(e, agentID) {
        if (e.target.checked) {
            this.state.selectedAgentIDs.push(agentID);
        }
        else {
            this.state.selectedAgentIDs.splice(this.state.selectedAgentIDs.indexOf(agentID), 1);
        }

        this.setState({ agents: this.state.agents });
    }

    updatePropertyAgent() {
        this.setState({ isLoading: true });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        let data = {
            AgentIds: this.state.selectedAgentIDs.toString(),
            StoreId: this.state.storeId,
        }

        axios.post(CONFIG.API_URL + 'admin/store/agents', data)
            .then(response => {
                this.setState({ isOpen: false, isLoading: false }, () => {
                    this.props.parentMethod();
                });
            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { isOpen, agents, agentID, isLoading, selectedAgentIDs } = this.state;
        return (
            <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isOpen} toggle={this.toggleAgentModalClose.bind(this)} className="create-new edit-market-dashboard">
                <ModalHeader toggle={this.toggleAgentModalClose.bind(this)}>Assign Listing Agent(s)
                </ModalHeader>
                <ModalBody className="overflow-scroll basic-details">
                    {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                    <div className="table-scroll table-padding-assignseller table-assign-agent">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="width-80seller">Select</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell className="email-width">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agents && agents.length > 0 ? agents.map((agent) =>
                                    <React.Fragment>
                                        <TableRow>
                                            {/* <TableCell className="width-80seller">
                                                {
                                                    agentID !== agent.Id ?
                                                        <Button className="view-btn" onClick={() => this.setAgent(agent.Id)}>Select</Button>
                                                        :
                                                        <Label className="select-seller">Select</Label>
                                                }
                                            </TableCell> */}
                                            <TableCell className="table-width-col">
                                                <Checkbox key={agent.Id} name="selectCheck" className="track-check"
                                                    checked={this.state.selectedAgentIDs != [] && this.state.selectedAgentIDs.indexOf(agent.Id) !== -1}
                                                    onClick={event => this.onChangeAgent(event, agent.Id)}
                                                />
                                            </TableCell>
                                            <TableCell>{agent.FirstName}</TableCell>
                                            <TableCell>{agent.LastName}</TableCell>
                                            <TableCell className="email-width">{agent.UserName}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                                    :
                                    <React.Fragment>
                                        <TableRow>
                                            <TableCell colSpan="5">
                                                <h6 className="no-records-found">No agents found</h6>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <Row className="save-right margin-top-buyer">
                        <Col md={12}>
                            {agents && agents.length > 0 ? <Button onClick={this.updatePropertyAgent.bind(this)} >Submit</Button> : ''}
                            <Button onClick={this.toggleAgentModalClose.bind(this)} className="btn-reset" id="btnCancel">Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}