import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG, DEALSTATUS } from '../../../../Utils/config';
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Label, Input } from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { getFormattedInt, getFormattedDecimal } from "../../../../Utils/utils";
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import arrow_r from '../../../../Assets/Img/arrow-right.png';
import edit_r from '../../../../Assets/Img/edit-buy.png';
import viewicon from '../../../../Assets/Img/view.png';
import './SellerProperty.css';
import { Pagination } from '../../../Shared/Pagination';

var fileDownload = require('react-file-download');

const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

const SellerWrapped = withStyles(actionsStyles, { withTheme: true })(
    Pagination,
);

export default class SellerProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeId: parseInt(this.props.match.params.propertyId),
            sellerData: [],
            pageCount: 0,
            documentData: [],
            buyerData: [],
            storeData: [],
            addModal: false,
            buyerDeal: {},

        };
    }

    dealPopup(deal, dealDetail) {
        if (deal == "accept") {
            this.setState(state => ({ addModal: !state.addModal, buyerDeal: dealDetail }))
        } else if (deal == "reject") {
            this.setState(state => ({ rejectModal: !state.addModal, buyerDeal: dealDetail }))
        }
    }

    acceptDeal(deal) {
        this.setState({ isLoading: true })
        const data = {
            'Id': parseInt(deal.ID),
            'StoreId': parseInt(deal.StoreID),
            'BuyerId': parseInt(deal.BuyerID),
            'Price': deal.Price
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'seller/deal/accept/', data)
            .then(res => {
                this.toggleClose();
                this.propertyDeals();
                this.setState({ isLoading: false })
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false })
            })

    }

    rejectDeal(deal) {
        this.setState({ isLoading: true })
        const data = {
            'Id': parseInt(deal.ID),
            'StoreId': parseInt(deal.StoreID),
            'BuyerId': parseInt(deal.BuyerID),
            'Price': deal.Price
        }

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'seller/deal/reject/', data)
            .then(res => {
                this.toggleClose();
                this.setState({ isLoading: false })
                this.propertyDeals();

            })
            .catch((err) => {
                this.setState({ isLoading: false })
                console.log(err);

            })

    }

    componentWillReceiveProps(nextprops) {

        let storeId = parseInt(nextprops.match.params.propertyId)
        this.setState({ isLoading: true, storeId: storeId }, () => {
            this.propertyDeals();
        });
    }

    propertyDeals() {
        this.state.isLoading = false;
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'seller/store/buyers/' + this.state.storeId)
            .then(res => {

                this.setState({ buyerData: res.data.buyerStores, documentData: res.data.documents, storeData: res.data.stores, isLoading: false });
            })
            .catch((err) => {
                console.log(err);

            })
    }

    toggleClose() {
        this.setState({ addModal: false, rejectModal: false });
        this.propertyDeals();
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.propertyDeals();
    }

    handleChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount });
    };

    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Properties';
        const { isLoading, propertyData, imageName, sellerData, buyerData, documentData, pageCount, storeData, buyerDeal } = this.state;

        return (
            <main className="dashboard-layout-height">
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="seller-dashboard-perperty">
                    <Row className="seller-property-head">
                        <Col md={12}>
                            <div className="heading">
                                <h5>{storeData.StoreName}</h5>
                                {/* <div><span className="heading-broder"></span></div> */}
                            </div>
                            <div className="prpertyaddress">
                                <p>{storeData.Address1}, {storeData.Address2 ? storeData.Address2 + ',' : ''} {storeData.City + ", "}{storeData.StateCode + ","} {storeData.ZipCode}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {buyerData.length > 0 ? buyerData.map(buyer => {
                            return (
                                <Col md={3} className="widthpad">
                                    <div className="border-seller">
                                        <div className="offers-presend">  {/* {property.Status === 'Available' ? 'Available' :property.Status === 'Sold'? 'Sale':property.Status==='Due Diligence' ? 'Contract':''} */}
                                            Offer Presented: ${getFormattedInt(buyer.Price)}
                                        </div>
                                        <div className="deal-price-details-table">
                                            <h3 className="font-14p">Details</h3>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>Offer Date: <span>{moment(buyer.CreatedDate).format('L')}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name: <span>{buyer.LOIName}</span></td>

                                                    </tr>
                                                    <tr>
                                                        <td>Company Name: <span>{buyer.LOICompanyName}</span></td>

                                                    </tr>
                                                    <tr>
                                                        <td>Source of Financing: <span>{buyer.SourceOfFinancing}</span></td>

                                                    </tr>
                                                    <tr>
                                                        <td>Diligence Period: <span>{buyer.DiligencePeriod}</span></td>

                                                    </tr>
                                                    <tr>
                                                        <td>Earnest Money Deposit:{buyer.EarnestMoneyDeposit ? <span>{getFormattedDecimal(buyer.EarnestMoneyDeposit)}%</span> : ''}</td>

                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="btn-center-align">
                                            <Row>
                                                {buyer.DealStatusID == DEALSTATUS.Evaluation || buyer.DealStatusID == DEALSTATUS.UnderLOI ?
                                                    <React.Fragment>
                                                        <Col md={6}><Link className="button-accept" onClick={this.dealPopup.bind(this, 'accept', buyer)}>Accept Offer</Link></Col>
                                                        <Col md={6}><Link className="button-reject" onClick={this.dealPopup.bind(this, 'reject', buyer)}>Reject Offer</Link></Col>
                                                    </React.Fragment> :
                                                    <h4 className="font-14p margin-10p">{buyer.DealStatus}</h4>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) : <Col className="no-document-for-seller">No Deals</Col>}
                    </Row>
                    <br />
                    <Container>
                        <div className="documents-list-seller">
                            <h5>Documents List</h5>
                        </div>
                    </Container>
                    {documentData.length === 0 ? <Row className="no-document-for-seller"><Col>No documents for this property</Col></Row> :
                        <Row className="document-list-seller">
                            <Col md={8}>

                                <div className="table-seller-property">
                                    <Table className="table custom-table table-bordered store-count-popup">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>S.No</TableCell> */}
                                                <TableCell>Document Name</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documentData ? documentData.slice(pageCount * 10, pageCount * 10 + 10).map((document) =>
                                                <TableRow key={document.DocumentTypeID}>
                                                    {/* <TableCell>{document.DocumentTypeID}</TableCell> */}
                                                    <TableCell>{document.DocumentType}</TableCell>
                                                    <TableCell><a href={'/StoreDocuments/' + document.FileName} download={document.ActualFileName != null ? document.ActualFileName : document.FileName} className="link-view">View</a> </TableCell>
                                                </TableRow>
                                            ) :
                                                <TableRow></TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    }
                    <div>
                        <Modal size="md" isOpen={this.state.addModal} toggle={this.toggleClose.bind(this)} className="confirm-btn create-new edit-market-dashboard">
                            <ModalHeader className="header-confirm" toggle={this.toggleClose.bind(this)}>{storeData.StoreName}
                            </ModalHeader>
                            <ModalBody className="overflow-scroll basic-details">
                                <div className="confirm-box">
                                    <p>Please note that you are accepting the offer of ${getFormattedInt(buyerDeal.Price)} </p>
                                    <p>from the buyer and ready to move the property into the due dilligence state</p>
                                </div>
                                <Row className="save-right">
                                    <Col md={12}>
                                        <Button className="offer-confirm-btn" onClick={this.acceptDeal.bind(this, buyerDeal)}>Confirm</Button>
                                        <Button className="offer-cantl-btn" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                                    </Col>
                                </Row>

                            </ModalBody>
                        </Modal>
                        <Modal size="md" isOpen={this.state.rejectModal} toggle={this.toggleClose.bind(this)} className="confirm-btn create-new edit-market-dashboard">
                            <ModalHeader className="header-confirm" toggle={this.toggleClose.bind(this)}>{storeData.StoreName}
                            </ModalHeader>
                            <ModalBody className="overflow-scroll basic-details">
                                <div className="confirm-box">
                                    <p>Please note that you are rejecting the offer of ${getFormattedInt(buyerDeal.Price)} from the buyer</p>
                                </div>
                                <Row className="save-right">
                                    <Col md={12}>
                                        <Button className="offer-confirm-btn" onClick={this.rejectDeal.bind(this, buyerDeal)}>Confirm</Button>
                                        <Button className="offer-cantl-btn" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                                    </Col>
                                </Row>

                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </main>
        );
    }
}
