// export const CONFIG = {
//     //endpoint call
//     PAGE_TITLE: 'Storage Exchange | ',
//     API_URL: 'http://localhost:5000/api/',
//     sessionTimeout: 1000 * 60 * 60,
//     PAGE_LENGTH: 10,
//     TINY_MCE_KEY: '5frswc3jdh4z6cipxq9ytnvyfk9c25701f66n84n6pib9pw2',
//     GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4',
//     GeolocationApiKey: 'AIzaSyAb8UR1jppXwZw1_B_4WLneVP6MxdS6PMU',
//     StorTrackMVPURL: 'http://stexg.stortrack.com/'
// }

// //Staging
// export const CONFIG = {
//     //endpoint call
//     PAGE_TITLE: 'Storage Exchange | ',
//     API_URL: 'https://api-staging.storageexchange.com/api/',
//     sessionTimeout: 1000 * 60 * 60,
//     PAGE_LENGTH: 10,
//     TINY_MCE_KEY: '5frswc3jdh4z6cipxq9ytnvyfk9c25701f66n84n6pib9pw2',
//     GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4',
//     GeolocationApiKey: 'AIzaSyAb8UR1jppXwZw1_B_4WLneVP6MxdS6PMU',
//     StorTrackMVPURL: 'http://stexg.stortrack.com/'
// }

//Live
export const CONFIG = {
    //endpoint call
    PAGE_TITLE: 'Storage Exchange | ',
    API_URL: 'https://api.storageexchange.com/api/',
    sessionTimeout: 1000 * 60 * 60,
    PAGE_LENGTH: 10,
    TINY_MCE_KEY: '5frswc3jdh4z6cipxq9ytnvyfk9c25701f66n84n6pib9pw2',
    GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4',
    GeolocationApiKey: 'AIzaSyAb8UR1jppXwZw1_B_4WLneVP6MxdS6PMU',
    StorTrackMVPURL: 'https://marketreport.storageexchange.com/'
}

export const CONTACT = {
    Name: 'Seth Beth',
    Address1: '4770 Baseline Road',
    Address2: 'Suite 200',
    Address3: '888 Prospect Street',
    Address4: 'Suite 200',
    City: 'Boulder',
    State: 'CO',
    Country: 'USA',
    ZipCode: '80303',
    ContactNo: ' 720-310-0113',
    Email: 'info@storageexchange.com'
}

export const USERTYPES = {
    Admin: 1,
    Buyer: 2,
    Seller: 3,
    Both: 4
}

export const DEALSTATUS = {
    Accepted: 1,//Accept Offer
    Rejected: 2,//Reject Offer
    OnHold: 3,//On Hold
    Evaluation: 4,//Review Financials
    Closed: 5,//Closed
    NDAExecuted: 6,//NDA Executed
    UnderLOI: 7,//Offer Review changed into Under LOI
}

export const PROPERTYSTATUS = {
    Available: 1,
    Sold: 2,
    DueDiligence: 3,
    Draft: 4,
    UnderLOI: 5
}