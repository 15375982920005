import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG, USERTYPES } from '../../../../Utils/config';
import { normalizeInput } from '../../../../Utils/utils';
import axios from 'axios';
import './BuyerRegistration.css';

const validateProperties = (value, ctx) => {
    if (isNaN(ctx.NumberOfProperties)) {
        return "No Of Properties should be a number";
    } else if (ctx.RentableSQFT <= 0 && ctx.RentableSQFT != "") {
        return "No Of Properties should be greater than zero";
    }
    return true;
}


const validateInput = value => {
    let error = ""

    if (!value) error = "Required!"
    else if (value.length !== 14) error = "Invalid phone format. ex: 555-555-5555";

    return error;
};

export default class BuyerRegistration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            registerError: '',
            existError: '',
            registerSuccess: '',
            isRegisterSuccess: false,
            storeID: this.props.match.params.storeID,
            storeName: decodeURIComponent(this.props.match.params.StoreName),
            stateList: [],
            state: 0,
            positionId: 0,
            positionList: [],
            phone: '',
            position: '',
            showPosition: false
        };
        this.resetForm = this.resetForm.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }
    handlePhoneChange({ target: { value } }) {
        this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    };

    getLookupValues() {

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'lookup/state')
            .then(res => {
                let stateList = res.data;
                this.setState({ stateList })

            })
            .catch((err) => {
                console.log(err);
            })

        axios.get(CONFIG.API_URL + 'lookup/user/position')
            .then(res => {
                let positionList = res.data;
                this.setState({ positionList })

            })
            .catch((err) => {
                console.log(err);
            })

    }

    handleChange(e) {
        var value = e.target.value;
        if (e.target.name === 'positionId') {
            var position = '';
            var showPosition = e.target.value === "5" ? true : false;
            this.setState({ position, showPosition });
        }
        this.setState({ [e.target.name]: value });
    }


    handleSubmit(event, errors, values) {

        if (errors.length === 0) {
            const { state, positionId, storeID, storeName, stateList, position } = this.state;

            if ((values.position == null || values.position.trim() === '') && (positionId === "5" || positionId === 5)) {
                alert("Title is required");
                errors.push('position');
            }
            if (errors.length === 0) {
                this.setState({ isLoading: true, registerError: '', existError: '', registerSuccess: '' });
                var stateName = "";
                var stateselected = stateList.filter((list => list.ID == state));
                stateName = stateselected[0].Name;
                const data = {
                    User: {
                        'FirstName': values.FirstName,
                        'LastName': values.LastName,
                        'City': values.City,
                        'Username': values.Email,
                        'Email': values.Email,
                        'PhoneNumber': values.Phone,
                        'ZipCode':values.ZipCode,
                        //'CapitalSource': values.CapitalSource,
                        //'NumberOfProperties': values.NumberOfProperties,
                        'CompanyName': values.CompanyName,
                        'StateId': state,
                        'State': stateName,
                        'PositionID': values.positionId,
                        'UserTypeID': values.userType,
                        'IsHavingSelfStorages': values.ownselfstorage !== "true" && values.ownselfstorage !== "false" && values.ownselfstorage !== true && values.ownselfstorage !== false
                            ? null : values.ownselfstorage === "true" ? true : values.ownselfstorage === "false" ? false : values.ownselfstorage,
                        'Question': values.Question,
                        'Position': values.position
                    },
                    BuyerStore: {
                        "StoreID": storeID,
                        "DealName": storeName,
                    },
                    BuyerStoreNDA: {
                        "StoreID": storeID
                    },
                    'Password': values.Password
                }
                axios.post(CONFIG.API_URL + 'account/register/user/', data)
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.Message != "User has been created successfully.") {
                                const registerSuccess = response.data.Message;
                                this.setState({ registerSuccess, isLoading: false });
                            } else {
                                // window.scrollTo(0, 0);
                                // this.setState({ isLoading: false, isRegisterSuccess: true });

                                //Auto Login the registered user
                                const getTokvalues = response.data.Token;
                                let defaultUser = response.data.DefaultRole;

                                localStorage.setItem('accessKey', response.data.Token);
                                localStorage.setItem('id', response.data.Id);
                                localStorage.setItem('name', response.data.FirstName + (response.data.LastName != null ? ' ' + response.data.LastName : ''));
                                localStorage.setItem('email', response.data.Email);

                                axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.Token;
                                localStorage.setItem('sessionId', response.data.SessionId);

                                let buyerDefault = defaultUser[0].IsDefault === true ? true : false;
                                let sellerDefault = defaultUser[1].IsDefault === true ? true : false;

                                localStorage.setItem('buyerDefault', buyerDefault);
                                localStorage.setItem('sellerDefault', sellerDefault);

                                if (sellerDefault === true && buyerDefault === false) {
                                    localStorage.setItem('role', response.data.Role[1]);
                                    const { from } = { from: { pathname: "/seller/dashboard" } };
                                    this.props.history.push(from);
                                }
                                else if (buyerDefault === true && sellerDefault === false) {
                                    localStorage.setItem('role', response.data.Role[0]);
                                    const { from } = { from: { pathname: "/buyer/dashboard" } };
                                    this.props.history.push(from);
                                }
                                else {
                                    localStorage.setItem('role', response.data.Role[0]);
                                    const { from } = { from: { pathname: "/buyer/dashboard" } };
                                    this.props.history.push(from);
                                }
                            }
                        }
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        if (err.response != null && err.response.status === 400) {
                            var registerError = "";
                            var existError = "";
                            if (err.response.data == "Exist") {
                                existError = "exist"
                            }
                            else {
                                registerError = err.response.data;
                            }
                            this.setState({ registerError, existError });

                        }
                        else {
                            const registerError = "Something went wrong.";
                            this.setState({ registerError });
                        }

                    });
            }
        }
    }

    resetForm(event) {
        this.form.reset();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params.StoreName) {
            this.setState({ storeName: decodeURI(this.props.match.params.StoreName) });
        }
        if (this.props.match.params.storeID) {
            this.setState({ storeID: this.props.match.params.storeID });
        }
        this.getLookupValues();
        this.setState({ isRegisterSuccess: false });
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Buyer Registration';
        const { isLoading, registerError, existError, isRegisterSuccess, stateList, position, showPosition
            , state, positionId, positionList, registerSuccess, storeID, storeName } = this.state;
        if (isRegisterSuccess === false) {
            return (
                <div>
                    {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                    <Row>
                        <Col>
                            <div className="buyer-register">
                                <div className="heading">
                                    <h3>Create Account</h3>
                                    {/* <div><span className="heading-broder"></span></div> */}
                                    {/* <p>To access additional property details, please register below.</p> */}

                                    <p>Please register below to access additional property details. If you're a registered user, <Link to={storeID && storeName ? "/signin/" + storeID + "/" + encodeURI(storeName) : '/signin'}>click here</Link> to sign-in.</p>
                                </div>

                                <Row>
                                    <div className="wrapper">
                                        <span className="sign-success">{registerSuccess}</span>
                                        <div className="wrapper-content">
                                            <div>
                                                <h4 className="padding-15">Register</h4>
                                                <span className="left-border"></span>
                                            </div>
                                            <div className="register-create">

                                                <AvForm onSubmit={this.handleSubmit.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.form = c)}>
                                                    <Row>
                                                        <div className="width-33">
                                                            <AvField name="FirstName" label="First Name:*" placeholder="" type="text" maxLength="255" validate={{
                                                                required: { value: true, errorMessage: 'First Name is required' }
                                                            }} />
                                                        </div>
                                                        <div className="width-33">
                                                            <AvField name="LastName" label="Last Name:*" placeholder="" type="text" maxLength="255" validate={{
                                                                required: { value: true, errorMessage: 'Last Name is required' }
                                                            }} />
                                                        </div>
                                                        <div className="width-33">
                                                            <AvField name="Email" label="Email:*" placeholder="" type="email" maxLength="255" validate={{
                                                                required: { value: true, errorMessage: 'Email is required' },
                                                                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                                            }} />
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField name="Password" label="Password:*" placeholder="" type="password" validate={{
                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                            }} />
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField name="ConfirmPassword" label="Retype Password:*" placeholder="" type="password" validate={{
                                                                required: { value: true, errorMessage: 'Retype your password' },
                                                                match: { value: 'Password', errorMessage: 'Password is mismatching' }
                                                            }} />
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField name="City" label="City:*" placeholder="" type="text" maxLength="100" validate={{
                                                                required: { value: true, errorMessage: 'City is required' }
                                                            }} />
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField type="select" name="state" value={state}
                                                                onChange={(e) => this.handleChange(e)}
                                                                label="State:*"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'State is required' },
                                                                }}>
                                                                <option value="0">--Select--</option>
                                                                {stateList ? stateList.map(n => {
                                                                    return (
                                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                            disabled={n.ID === '' ? true : false}
                                                                            value={n.ID}>
                                                                            {n.Name}
                                                                        </option>
                                                                    );
                                                                }) : ''}
                                                            </AvField>
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField name="Phone" label="Phone Number:*" placeholder="xxx-xxx-xxxx" type="tel"
                                                                value={this.state.phone}
                                                                validate={
                                                                    //     {
                                                                    //     required: { value: true, errorMessage: 'Phone Number is required' },
                                                                    //     pattern: { value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone Number is invalid' }
                                                                    // },
                                                                    {
                                                                        required: { value: true, errorMessage: 'Phone Number is required' },
                                                                        tel: { pattern: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone Number is invalid' }
                                                                    }} onChange={(e) => this.handlePhoneChange(e)} />
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField name="ZipCode" label="Zip Code:*" placeholder="" type="text" maxLength="10" validate={{
                                                                required: { value: true, errorMessage: 'Zip code is required' }
                                                            }} />
                                                        </div>
                                                        <div className={showPosition ? "width-33" : "width-half"}>
                                                            <AvField name="CompanyName" label="Company:*" placeholder="" type="text" maxLength="1000" validate={{
                                                                required: { value: true, errorMessage: 'Company is required' }
                                                            }} />
                                                        </div>
                                                        <div className={showPosition ? "width-33" : "width-half"}>
                                                            <AvField type="select" name="positionId" value={positionId}
                                                                onChange={(e) => this.handleChange(e)}
                                                                label="Title:*"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Title is required' },
                                                                }}>
                                                                <option key={0} value="0">--Select--</option>
                                                                {positionList ? positionList.map(n => {
                                                                    return (
                                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                            disabled={n.ID === '' ? true : false}
                                                                            value={n.ID}>
                                                                            {n.Name}
                                                                        </option>
                                                                    );
                                                                }) : ''}
                                                            </AvField>
                                                        </div>
                                                        {
                                                            showPosition ?
                                                                <div className="width-33">
                                                                    <AvField name="position" label="Title:*" placeholder="" type="text" maxLength="100" value={position} />
                                                                </div>
                                                                : ''
                                                        }
                                                        <div className="width-half">
                                                            <AvField type="select" name="ownselfstorage"
                                                                onChange={(e) => this.handleChange(e)}
                                                                label="Do you currently own a self-storage facility?"
                                                            // validate={{
                                                            //     required: { value: true, errorMessage: 'own self-storage facilities is required' },
                                                            // }}
                                                            >
                                                                <option key={2} value={null}>--Select--</option>
                                                                <option key={0}
                                                                    value={true}>
                                                                    {"Yes"}
                                                                </option>
                                                                <option key={1}
                                                                    value={false}>
                                                                    {"No"}
                                                                </option>
                                                            </AvField>
                                                        </div>
                                                        <div className="width-half">
                                                            <AvField type="select" name="userType"
                                                                onChange={(e) => this.handleChange(e)}
                                                                label="I am a:*"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'this field is required' },
                                                                }}>
                                                                <option key={4} value="0">--Select--</option>
                                                                <option key={0}
                                                                    value={USERTYPES.Seller}>
                                                                    {"Seller"}
                                                                </option>
                                                                <option key={1}
                                                                    value={USERTYPES.Buyer}>
                                                                    {"Buyer"}
                                                                </option>
                                                                <option key={3}
                                                                    value={USERTYPES.Both}>
                                                                    {"Buyer and Seller"}
                                                                </option>
                                                            </AvField>
                                                        </div>
                                                        {/* <div className="width-half">
                                                <AvField name="NumberOfProperties" label="No of Properties" placeholder="" type="text" maxLength="255"
                                                    validate={{ myValidation: validateProperties }}
                                                />
                                                </div>
                                                <div className="width-half">
                                                <AvField name="CapitalSource" label="Capital Source" placeholder="" type="text" maxLength="2000" validate={{
                                                    required: { value: true, errorMessage: 'Capital Source is required' }
                                                }} />
                                                </div>*/}
                                                        <div className="width-full">
                                                            <AvField name="Question" label="Additional Comments:" placeholder="" className="txt-area" maxLength="4000" type="textarea" validate={{
                                                                // required: { value: true, errorMessage: 'Additional Comments is required.' }
                                                            }} />
                                                        </div>
                                                    </Row>
                                                    <div className="width-full textright">
                                                        <Button className="btn-reset" type="reset">Clear</Button>
                                                        <Button className="btn-create-submit" type="submit">Submit</Button>
                                                        <span className="sign-error-register">{registerError}</span>
                                                        {existError ? <span className="sign-error-register"> A user already exists with this email. If you have forgotten your password, please <Link to="/forgotpassword">click here </Link>to reset.</span> : ''}
                                                    </div>
                                                </AvForm>

                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </div>
            );
        } else {
            return (
                <div className="reg-acknowledgement page-height-fixed">
                    {/* <h4>Acknowledgement</h4> */}
                    {/* <div><span className="heading-broder"></span></div> */}
                    <p>Thank you for registering with us! <br />
                        We have sent an email confirmation with your temporary password.</p>
                </div>);
        }
    }
}
