import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG, USERTYPES } from '../../Utils/config';
import { getUserType } from '../../Utils/localStorage';
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Label, Input } from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { ReplyMessage } from '../Shared/ReplyMessage';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import c_msg from './../../Assets/Img/mail.png';
import arrow from './../../Assets/Img/arrow-msg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './Notifications.css';
import { Pagination } from '../Shared/Pagination';

const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

const MessageWrapped = withStyles(actionsStyles, { withTheme: true })(
    Pagination,
);

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userId: parseInt(this.props.match.params.id),
            readData: [],
            unreadData: [],
            pageDet: [],
            pageCount: 0,
            cpageCount: 0,
            name: localStorage.getItem('name'),
            mailContent: '',
            replyMes: false,
            messageData: [],
            isRead: false,
            addMessageModal: false,
            composeMessage: '',
            composeError: false,
            userType: '',

        };
        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.getMessages = this.getMessages.bind(this);
    }

    handleChangeContent(e) {
        let mailContent = e.target.value;
        this.setState({
            mailContent
        })
    }
    clearMessage() {
        this.setState({ composeError: false, composeMessage: '' });
    }

    sendMessage() {
        if (this.state.composeMessage == '') {
            this.setState({ composeError: true })
        }
        else {
            this.setState({ isLoading: true })
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            const { userType, composeMessage } = this.state;

            const data = {
                'MailContent': composeMessage,
                'FromUserTypeID': userType
            }
            axios.post(CONFIG.API_URL + 'home/composemail/', data)
                .then(res => {
                    this.setState({ isLoading: false, composeError: false })
                    alert('Mail has been sent.');
                    this.toggleClose();
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }


    handleChangeDescription(e) {
        let propertyDescription = e.target.value;
        this.setState({
            propertyDescription, composeError: false
        })
    }

    readMessage(data) {
        let messageData = data;
        let pageDet = this.state.readData.Pagination;
        this.setState({ isRead: true, replyMes: true, messageData: messageData, pageDet: pageDet });
    }


    handleChangeMessage(e) {
        let composeMessage = e.target.value;
        this.setState({
            composeMessage, composeError: false,
        })
    }

    unreadMessage(data) {
        let pageDet = this.state.unreadData.Pagination;
        this.setState(state => ({ messageData: data, isRead: false, replyMes: true, pageDet: pageDet }))
    }

    toggleClose = () => {
        this.setState(state => ({ mailContent: '', addMessageModal: false, composeError: false }));
        this.getMessages();
    }

    getMessages() {
        this.setState({ replyMes: false })
        var userType = getUserType();
        this.setState({ userType }, () => {
            this.initializeUnreadParams();
            this.initializeReadParams();
        });
    }

    addtoggleCommunicationModal() {
        this.setState(state => ({ addMessageModal: !state.addMessageModal, composeMessage: '', composeError: false }))
    }

    componentDidMount() {
        var userType = getUserType();
        this.setState({ userType }, () => {
            this.initializeUnreadParams();
            this.initializeReadParams();
        });
    }


    getUnReadMessages(params) {

        var userType = getUserType();
        if (params == null) {
            params = {
                'UserType': userType,
                'PageLength': CONFIG.PAGE_LENGTH,
                'Page': this.state.unreadData.Pagination.Page
            }
        }

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        // messages/unread
        axios.post(CONFIG.API_URL + 'home/messages/unread', params)
            .then(res => {
                let unreadData = res.data;
                this.setState({ unreadData });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getReadMessages(params) {
        var userType = getUserType();
        if (params == null) {
            params = {
                'UserType': userType,
                'PageLength': CONFIG.PAGE_LENGTH,
                'Page': this.state.readData.Pagination.Page
            }
        }

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        // messages/unread
        axios.post(CONFIG.API_URL + 'home/messages/read', params)
            .then(res => {
                let readData = res.data;
                this.setState({ readData });
            })
            .catch((err) => {
                console.log(err);
            })
    }


    initializeUnreadParams() {
        var userType = getUserType();
        const params = {
            'UserType': userType,
            'PageLength': CONFIG.PAGE_LENGTH,
            'Page': 1
        }
        this.setState({ pageCount: 0 }, () => {
            this.getUnReadMessages(params);
        });
    }


    initializeReadParams() {
        var userType = getUserType();
        const params = {
            'UserType': userType,
            'PageLength': CONFIG.PAGE_LENGTH,
            'Page': 1
        }
        this.setState({ cpageCount: 0 }, () => {
            this.getReadMessages(params);
        })

    }

    handleReadChangePageTrack = (event, pageCount) => {
        this.setState({ cpageCount: pageCount });
        this.state.readData.Pagination.Page = pageCount + 1;
        this.getReadMessages(null);
    };

    handleUnreadChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount: pageCount });
        this.state.unreadData.Pagination.Page = pageCount + 1;
        this.getUnReadMessages(null);
    };


    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Notifications';
        const { isLoading, readData, unreadData, name, pageCount, pageDet, cpageCount, composeError, messageData, composeMessage, mailContent, replyMes, isRead, userType } = this.state;

        return (
            <main className="dashboard-layout-height">
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="notification-dash">
                    <Row className="notofication-dash-head">
                        <Col md={10}>
                            <div className="heading">
                                <h5>Notifications</h5>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="compose-message-link">
                                {userType != USERTYPES.Admin ?
                                    <Link onClick={this.addtoggleCommunicationModal.bind(this)} ><FontAwesomeIcon icon={faEnvelope} /> Compose Message</Link>
                                    : ''}
                            </div>
                        </Col>
                    </Row>

                    <div className=" leftandright-nomargin">
                        <Row className="document-list-seller margin-top0">

                            <Col md={12} className="paddingremove-colm">

                                <div className="table-seller-property">
                                    <Table className="table custom-table table-bordered store-count-popup">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Unread Notifications</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="unread-notification-box">
                                            {unreadData.Messages ? unreadData.Messages.map((data) =>
                                                <Link to="#" onClick={this.unreadMessage.bind(this, data)}><TableRow key={data.ID}>
                                                    <TableCell>
                                                        <Row>
                                                            <Col md={2}>
                                                                <h6 className="msgheading">{data.FromUserName}</h6>
                                                            </Col>
                                                            <Col md={1}>
                                                                <span className="color-span">{moment(data.CreatedDate).format("L")}</span>
                                                            </Col>
                                                            <Col md={7} >
                                                                <p className="warpmsg">{data.MailSubject} : {data.MailContent}.... </p>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Link to="#" className="readmore-right" onClick={this.unreadMessage.bind(this, data)}>Read more information</Link>
                                                            </Col>
                                                        </Row>
                                                    </TableCell>
                                                </TableRow></Link>
                                            ) :
                                                <div className="notification-no-found">No notification found</div>
                                            }
                                        </TableBody>
                                        {unreadData.Messages != undefined ?
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[1]}
                                                        colSpan={3}
                                                        count={unreadData.Messages ? unreadData.Pagination.TotalRecords : 0}
                                                        rowsPerPage={10}
                                                        page={pageCount}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        onChangePage={this.handleUnreadChangePageTrack}
                                                        onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                                                        ActionsComponent={MessageWrapped}
                                                    />
                                                </TableRow>
                                            </TableFooter> : ''}

                                    </Table>
                                </div>
                            </Col>
                        </Row>

                        <Row className="document-list-seller">
                            <Col md={12} className="paddingremove-colm">

                                <div className="table-seller-property">
                                    <Table className="table custom-table table-bordered store-count-popup">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Read Notifications</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="read-notification-box">
                                            {readData.Messages ? readData.Messages.map((data) =>
                                                <Link to="#" onClick={this.readMessage.bind(this, data)}><TableRow key={data.ID}>
                                                    <TableCell>
                                                        <Row>
                                                            <Col md={2}>
                                                                <h6 className="msgheading">{data.FromUserName}</h6>
                                                            </Col>
                                                            <Col md={1}>
                                                                <span className="color-span">{moment(data.CreatedDate).format("L")}</span>
                                                            </Col>
                                                            <Col md={7}>
                                                                <p className="warpmsg">{data.MailSubject} : {data.MailContent}....</p>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Link to="#" className="readmore-right" onClick={this.readMessage.bind(this, data)}>Read more information</Link>
                                                            </Col>
                                                        </Row>
                                                    </TableCell>
                                                </TableRow></Link>
                                            ) :
                                                <TableRow className="notification-no-found">No notification found</TableRow>
                                            }
                                        </TableBody>
                                        {readData.Messages ?
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[1]}
                                                        colSpan={5}
                                                        count={readData.Messages ? readData.Pagination.TotalRecords : 0}
                                                        rowsPerPage={CONFIG.PAGE_LENGTH}
                                                        page={cpageCount}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        onChangePage={this.handleReadChangePageTrack}
                                                        onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                                                        ActionsComponent={MessageWrapped}
                                                    />
                                                </TableRow>
                                            </TableFooter> : ''
                                        }

                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <div>{replyMes === true ? <ReplyMessage params={messageData} isRead={isRead} parentMethod={this.getMessages} pageparams={pageDet}></ReplyMessage> : ''}</div>

                        <div>
                            <Modal size="md" isOpen={this.state.addMessageModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                                <ModalHeader toggle={this.toggleClose.bind(this)}>Compose Message
                        </ModalHeader>
                                <ModalBody className="overflow-scroll basic-details">
                                    <Row>
                                        <Col md={12}>
                                            <Label>Description</Label>
                                            <Input type="textarea" className="description-box-height" name="Message" value={composeMessage} onChange={this.handleChangeMessage.bind(this)} />
                                            {composeError == true ? <p className="error error-position">Please enter description</p> : ''}
                                        </Col>

                                    </Row>

                                    <Row className="save-right margin-top-buyer">
                                        <Col md={12}>
                                            <Button className="btn-reset padding-rightadd" type="reset" onClick={this.clearMessage.bind(this)}>Clear</Button>
                                            <Button className="" onClick={this.sendMessage.bind(this)}>Send Message</Button>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>

                        </div>

                    </div>
                </div>
            </main>
        )

    }
}
