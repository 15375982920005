import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Form, FormText } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CONFIG } from '../../Utils/config';
import { Link } from "react-router-dom";
import about_icon from './../../Assets/Img/about-us-icon.png';
import user1 from './../../Assets/Img/Seth-CEO.png';
import user2 from './../../Assets/Img/Taylor-Co-Founder.png';
import user3 from './../../Assets/Img/Hunter.png';
import user4 from './../../Assets/Img/Jennifer.png';
import user5 from './../../Assets/Img/Emily.png';
import user6 from './../../Assets/Img/Spencer-Molnar.png';
import user7 from './../../Assets/Img/Dylan.png';
import './FAQ.css';

export default class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Frequently Asked Questions';
        const { isLoading } = this.state;

        return (
            <div>
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="vendor-profilelist addpadding-lr">
                    <Container-fluid>
                        <div className="item">
                            <img src={about_icon} className="" alt="" />
                            <div class="content">  <h2 className="header-space">Frequently Asked Questions<br /> <span><Link to="/">Home</Link> / <Link to=''>Frequently Asked Questions</Link></span></h2></div>
                        </div>
                    </Container-fluid>
                </div>
                <div className="service-feature page-height-fixed">
                    <div className="team-heads">
                        {/* <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <h4>How do I set my own personal password?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>After registration, you will receive a confirmation email from <a href="mailto:info@storageexchange.com">info@storageexchange.com</a> with a temporary password.</li>
                                    <li>Click on the link provided in the email to enter your own preferred password.</li>
                                    <li>You will receive a second email confirming the password has been changed.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion> */}
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <h4>How do I reset my password?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>Visit <a href="https://www.storageexchange.com/forgotpassword" title="www.storageexchange.com/forgotpassword" target="_blank">www.storageexchange.com/forgotpassword</a></li>
                                    <li>Enter the email associated with your account and select “Submit”.</li>
                                    <li>You will then receive an email confirmation.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                <h4>How do I access property documents?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>Login using your username and password.</li>
                                    <li>Select “HOME” to return to the Storage Exchange homepage.</li>
                                    <li>Select the property you are interested in. Under the property description, select “Access Investment Materials”.</li>
                                    <li>You will then be prompted to electronically sign an NDA.</li>
                                    <li>The property will automatically be added to your buyer dashboard under “Interested Properties”.</li>
                                    <li>On your user dashboard, select “View” on the property you wish to access.</li>
                                    <li>Property specific documents are available for download under “Property Documents” as well as interactive overviews under “Interactive Market Analysis”.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                                <h4>How do I submit an offer?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>Access the property on your user dashboard under “Interested Properties”.</li>
                                    <li>Fill in the offer details and select “Submit Offer Terms”.</li>
                                    <li>A Storage Exchange agent will contact you for next steps.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
                                <h4>How do I rescind an offer I have submitted?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Reach out to the agent listed as the property's contact or <Link to="/contactus">click here</Link> to contact us directly.</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
                                <h4>Who do I contact for questions?</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>For property specific questions, please call or email the agent listed under the property's "Contact" box. For website or any other questions, <Link to="/contactus">click here</Link> to contact us directly.</p>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}
