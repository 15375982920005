import React from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Table } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import { getFormattedInt, normalizeInput } from '../../../../../Utils/utils';
import { CONFIG, CONTACT, USERTYPES, PROPERTYSTATUS, DEALSTATUS } from '../../../../../Utils/config';
import { getToken, getUserType, getbuyerStoreNDAIDs } from '../../../../../Utils/localStorage';
import axios from 'axios';
import './LOIViewModal.css';
import moment from 'moment';

export default class LOIViewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            deal: {},
            diligencePeriods: [],
            diligencePeriodID: 0,
            offerPrice: 0,
            stateList: [],
            offlineStateId: 0
        }
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleEarnestMoneyChange = this.handleEarnestMoneyChange.bind(this);
    }

    offlineStateChange(e) {

        var value = e.target.value;
        this.setState({ offlineStateId: value })
    }

    handlePhoneChange({ target: { value } }) {
        this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    };

    handlePriceChange({ target: { value } }) {
        const deal = this.state.deal;
        deal.Price = getFormattedInt(value.replace(/,/g, ''), deal.Price);
        this.setState(prevState => ({ deal }));
    };

    handleEarnestMoneyChange({ target: { value } }) {
        const deal = this.state.deal;
        deal.EarnestMoneyDeposit = getFormattedInt(value.replace(/,/g, ''), deal.EarnestMoneyDeposit);
        this.setState(prevState => ({ deal }));
    };
    componentDidMount() {
        this.getPropertyPriceLookupValues();
        this.getStateLookup();
    }

    getStateLookup() {
        axios.get(CONFIG.API_URL + 'lookup/state')
            .then(res => {
                let stateList = res.data;
                this.setState(state => ({ addModal: !state.addModal, stateList }))

            })
            .catch((err) => {
                console.log(err);
                alert(err.response.data);
            })
    }

    getPropertyPriceLookupValues() {

        axios.get(CONFIG.API_URL + 'lookup/propertypricelookup').then(response => {
            if (response.status === 200) {
                let data = response.data;
                this.setState({ SEPrices: data.SEPrices, diligencePeriods: data.diligencePeriods, isLoading: false });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    submitOffer(event, errors, values) {
        if (errors.length === 0) {
            const { propertyData, offlineStateId } = this.state;

            var earnestMoneyDeposit = values.EarnestMoneyDeposit.split('.');
            var formattedEarnestMoneyDeposit = getFormattedInt(earnestMoneyDeposit[0].replace(/,/g, ''));
            formattedEarnestMoneyDeposit = earnestMoneyDeposit.length > 1 ? formattedEarnestMoneyDeposit + "." + earnestMoneyDeposit[1] : formattedEarnestMoneyDeposit;

            const offerData = {
                Store: {
                    "StoreName": this.state.deal.StoreName,
                    "Price": values.Price.replace(/,/g, ''),
                    "StoreID": this.state.deal.StoreID,
                    "StatusID": this.state.deal.StatusID
                },
                BuyerStore: {
                    "LOIName": values.Name,
                    "LOICompanyName": values.Company,
                    "SourceOfFinancing": values.SourceOfFinancing,
                    "EarnestMoneyDeposit": formattedEarnestMoneyDeposit,
                    "DiligencePeriodID": this.state.diligencePeriodID,
                    "OfflineBuyerFirstName": values.OfflineBuyerFirstName,
                    "OfflineBuyerLastName": values.OfflineBuyerLastName,
                    "OfflineBuyerEmail": values.OfflineBuyerEmail,
                    "OfflineBuyerCity": values.OfflineBuyerCity,
                    "OfflineBuyerStateID": offlineStateId
                },
                "BuyerStoreID": this.state.deal.ID
            }
            offerData.BuyerStore.EarnestMoneyDeposit = offerData.BuyerStore.EarnestMoneyDeposit.replace(/,/g, '');
            this.setState({ isLoading: true, isconfirmOffer: false });
            var token = getToken();
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            axios.post(CONFIG.API_URL + 'buyer/store/deal/', offerData)
                .then(res => {
                    this.setState({ isLoading: false, isOpen: false }, () => {
                        window.location.reload(false);
                        //alert("Offer Terms have been successfully submitted. Offer details are now available under submitted offers on your buyer account. A Storage Exchange Agent will contact you shortly with next steps.");
                    });
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    if (err.response != null && err.response.status === 400) {
                        const error = err.response.data;
                        this.setState({ error });
                    }
                    else {
                        const error = "Something went wrong.";
                        this.setState({ error });
                    }
                })
        }
    }

    onSelectChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    confirmSubmitOffer() {
        this.setState({ isLoading: true, isconfirmOffer: false });
        const { token, offerData, isHomePage } = this.state;
        offerData.BuyerStore.EarnestMoneyDeposit = offerData.BuyerStore.EarnestMoneyDeposit.replace(/,/g, '');

        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        axios.post(CONFIG.API_URL + 'buyer/store/deal/', offerData)
            .then(res => {
                if (!isHomePage) {
                    document.getElementById("r1").click();
                }
                this.setState({ isLoading: false }, () => {
                    alert("Offer Terms have been successfully submitted. Offer details are now available under submitted offers on your buyer account. A Storage Exchange Agent will contact you shortly with next steps.");
                });

                this.props.history.push({
                    pathname: "/buyer/dashboard"
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error });
                }
            })
    }
    resetForm(event) {
        this.offerForm.reset();
    }

    getDeal(deal) {
        if (deal.EarnestMoneyDeposit) {
            var earnestMoneyDeposit = deal.EarnestMoneyDeposit.split('.');
            var formattedEarnestMoneyDeposit = getFormattedInt(earnestMoneyDeposit[0].replace(/,/g, ''));
            formattedEarnestMoneyDeposit = earnestMoneyDeposit.length > 1 ? formattedEarnestMoneyDeposit + "." + earnestMoneyDeposit[1] : formattedEarnestMoneyDeposit;
            //deal.EarnestMoneyDeposit = formattedEarnestMoneyDeposit;
        }
        const offerPrice = deal.Price;
        const diligencePeriodID = deal.DiligencePeriodID;
        const offlineStateId = deal.OfflineBuyerStateID;

        this.setState({ isOpen: true, deal, offerPrice, diligencePeriodID, offlineStateId });
    }

    toggleClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const { isOpen, deal, diligencePeriods, offlineStateId, stateList, diligencePeriodID } = this.state;
        return (
            <div>
                <Modal size="md" isOpen={isOpen} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                    <ModalHeader toggle={this.toggleClose.bind(this)}>{deal.DealName ? deal.DealName : deal.StoreName}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="row-width-model">
                            <Col md={12} className="half-width-model">
                                <div>
                                    <AvForm className="property-form-offer" onSubmit={this.submitOffer.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.offerForm = c)}>
                                        <Row>

                                            <Col md={6}>
                                                <AvField name="Name" label="Name:*" type="text" maxLength="200" validate={{
                                                    required: { value: true, errorMessage: 'Name is required' }
                                                }} value={deal.LOIName} />
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="Company" label="Company:*" maxLength="300"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Company is required' }
                                                    }} value={deal.LOICompanyName} />
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="Price" label="Offer:*" maxLength="13"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Offer is required' },
                                                        pattern: { value: /^[0-9,]*$/, errorMessage: 'Offer is invalid' }
                                                    }}
                                                    value={deal.Price}
                                                    onChange={(e) => this.handlePriceChange(e)}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <AvField type="select" name="diligencePeriodID" value={deal.DiligencePeriodID}
                                                    onChange={(e) => this.onSelectChange(e)}
                                                    label="Diligence Period:*"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Diligence Period is required' }
                                                    }}
                                                >
                                                    <option value="0">--Select--</option>
                                                    {diligencePeriods ? diligencePeriods.map(n => {
                                                        return (
                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                disabled={n.ID === '' ? true : false} selected={n.ID == diligencePeriodID ? 'selected' : ''}
                                                                value={n.ID}>
                                                                {n.Name}
                                                            </option>
                                                        );
                                                    }) : ''}
                                                </AvField>
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="SourceOfFinancing" label=" Source of Financing:*" maxLength="300"
                                                    validate={{
                                                        required: { value: true, errorMessage: ' Source of Financing is required' }
                                                    }} value={deal.SourceOfFinancing} />
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="EarnestMoneyDeposit" label="Earnest Money Deposit(%):*" maxLength="13"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Earnest Money Deposit is required' },
                                                        pattern: { value: /^[0-9,.]*$/, errorMessage: 'Earnest Money Deposit is invalid' }
                                                    }}
                                                    value={deal.EarnestMoneyDeposit}
                                                    onChange={(e) => this.handleEarnestMoneyChange(e)}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <AvField name="OfflineBuyerFirstName" label="Buyer First Name:" type="text" maxLength="100" value={deal.OfflineBuyerFirstName} />
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="OfflineBuyerLastName" label="Buyer Last Name:" maxLength="100" value={deal.OfflineBuyerLastName} />
                                            </Col>

                                            <Col md={6}>
                                                <AvField name="OfflineBuyerEmail" label="Buyer Email:" maxLength="100" value={deal.OfflineBuyerEmail} />
                                            </Col>
                                            <Col md={6}>
                                                <AvField name="OfflineBuyerCity" label="Buyer City:" type="text" maxLength="100" value={deal.OfflineBuyerCity} />
                                            </Col>
                                            <Col md={12}>
                                                <AvField type="select" name="OfflineBuyerState" value={offlineStateId}
                                                    onChange={(e) => this.offlineStateChange(e)}
                                                    label="State:*"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'State is required' },
                                                    }}>
                                                    <option value="0">--Select--</option>
                                                    {stateList ? stateList.map(n => {
                                                        return (
                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                disabled={n.ID === '' ? true : false}
                                                                value={n.ID}>
                                                                {n.Name}
                                                            </option>
                                                        );
                                                    }) : ''}
                                                </AvField>                                            
                                            </Col>
                                            <Col sm={12} className="text-right-side-condition">
                                                <Button className="submt-offer-clear" type="reset">Clear</Button>
                                                <Button className="submt-offer">Submit Offer Terms</Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </div>
                                {/* <p>LOI Name:{deal.LOIName}</p>
                            
                                <p>Company Name:{deal.LOICompanyName}</p>
                           
                                <p>Diligence Period:{deal.DiligencePeriod}</p>
                           
                                <p>Earnest Money Deposit:${getFormattedInt(deal.EarnestMoneyDeposit)}</p> */}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}