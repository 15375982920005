import { USERTYPES } from './config';

export function getToken() {
    return localStorage.getItem('accessKey');
}

export function getUserID() {
    return localStorage.getItem('id');
}

export function getUserType() {

    let role = localStorage.getItem('role');
    var usertype = "";
    switch (role) {
        case 'Buyer':
            usertype = USERTYPES.Buyer;
            break;
        case 'Seller':
            usertype = USERTYPES.Seller;
            break;
        case 'Admin':
            usertype = USERTYPES.Admin
            break;
    }
    return usertype;
}

export function getName() {
    return localStorage.getItem('name');
}

export function getbuyerStoreNDAIDs() {
    return localStorage.getItem('buyerStoreNDAIDs');
}

export function removeLocalStorage() {
    localStorage.removeItem('accessKey');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('buyerStoreNDAIDs');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('sellerDefault');
    localStorage.removeItem('buyerDefault');
    localStorage.removeItem('Count');
    localStorage.removeItem('id');
}