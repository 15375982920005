import React, { Component } from 'react';
import { CONFIG } from '../../../../Utils/config';
import './SellerServices.css';
import { Link } from "react-router-dom";
import logo1 from '../../../../Assets/Img/Transparency.png';
import logo2 from '../../../../Assets/Img/Modeling.png';
import logo3 from '../../../../Assets/Img/Quality-Transaction.png';
import logo4 from '../../../../Assets/Img/Digital-Marketing.png';
import logo5 from '../../../../Assets/Img/Storage-Facility.png';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Form, FormText } from 'reactstrap';

export default class SellerServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }        

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Seller Services';
        const { isLoading } = this.state;

        return (
            <main className="dashboard-layout-height">
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="vendor-profilelist">
                    <Container-fluid>
                        <div className="item">
                           
                            <div class="content">  <h2> Seller Services<br /> <span><Link to="/">Home</Link> / <Link to=''>Seller Services</Link></span></h2></div>
                        </div>
                    </Container-fluid>
                </div>
                <div className="buyerservice-se">
                    <Row>
                        <Col md={12} >
                            <h3 className="buyerheading">Storage Exchange works with self-storage owners and investors to facilitate highly efficient transactions, which deliver maximum value and most favorable terms. By leveraging technology enabled analytics and empirical operator data, Storage Exchange creates unparalleled asset transparency and market visibility.</h3>
                        </Col>
                        {/* <Col md={12}>
                        <h4 className="serviceheading">OUR APPROACH</h4>
                        <p className="buyerpara">At Storage Exchange, we aim to filter out the noise created by subjective sell-side data. Our team’s extensive background in self-storage operations, management, and investing serve as the foundation for our proprietary underwriting and valuation process. Our vast first-hand experience in the marketplace benefits our clients through institutional quality transaction management and comprehensive risk mitigation. </p>
                        </Col> */}
                    </Row>
                    </div>
                    <div className="buyerservicespage">
                    <Container-fluid>
                        <div className="item">
                            <div class="content">  <h2> OUR APPROACH</h2></div>
                        </div>
                        <Col className="buyer-approach">
                        <p>At Storage Exchange, we aim to filter out the noise created by subjective sell-side data. Our team’s extensive background in self-storage operations, management, and investing serve as the foundation for our proprietary underwriting and valuation process. Our vast first-hand experience in the marketplace benefits our clients through institutional quality transaction management and comprehensive risk mitigation. </p>
                        </Col>
                    </Container-fluid>
                </div>
                    <div className="buyerservice-se">
                    <Container-fluid>
                    <Row className="paddingeven">
                    <Col md={3} className="card">
                       <div className="box">
                           <div className="img img-width-change">
                             <img src={logo1} alt="" />
                           </div>
                           <h2>Market Data Aggregation & Analytics</h2>
                           <p>By utilizing cutting edge data aggregation and analytics tools, our team successfully identifies the subtle intricacies of properties and markets, which unlock value for our clients. </p>
                       </div>
                    </Col>
                    <Col md={3} className="card">
                       <div className="box">
                           <div className="img img-width-120change">
                             <img src={logo2} alt="" />
                           </div>
                           <h2>Operator Centric Revenue Modeling</h2>
                           <p>Our industry experience ensures thorough operator centric underwriting and revenue modeling to assess each opportunity.</p>
                       </div>
                    </Col>
                    <Col md={3} className="card">
                       <div className="box">
                           <div className="img">
                             <img src={logo3} alt="" />
                           </div>
                           <h2>Institutional Quality Transaction Management</h2>
                           <p>Through vast firsthand experience, our team is accustomed to providing institutional quality transaction management that ensures the highest level of professionalism and attention to detail. </p>
                       </div>
                    </Col>
                    <Col md={3} className="card">
                       <div className="box">
                           <div className="img">
                             <img src={logo4} alt="" />
                           </div>
                           <h2>Targeted Digital Marketing</h2>
                           <p>We create maximum exposure through highly targeted digital marketing combined with our vast network of industry relationships.</p>
                       </div>
                    </Col>
                    </Row>
                    </Container-fluid>
                    </div>
                    <div className="buyerservicespage">
                    <Container-fluid>
                        <div className="item">
                            <div class="content">  <h2>WORK WITH US</h2></div>
                        </div>
                        <Col className="buyer-approach">
                        {/* <p>Over the last decade, the storage industry has experienced an unprecedented influx of both public and private capital. During this same period, data aggregation has helped bring an end to the era of data asymmetry between buyers and sellers.</p> */}
                        <p>As part of our commitment to create value across the entire self-storage industry, Storage Exchange works with owners ranging from small sole proprietors to large institutions, regardless of geography. To learn more about how Storage Exchange can support your self-storage investment needs, <Link to="/contactus" className="color-link">please get in touch.</Link></p>
                        </Col>
                    </Container-fluid>
                </div>
                    <div className="buyerservice-se">
                    {/* <Row>
                        <Col md={12} className="">
                        <h4 className="serviceheading">WORK WITH US</h4>
                        <p className="buyerpara">As part of our commitment to create value across the entire self-storage industry, Storage Exchange works with owners ranging from small sole proprietors to large institutions, regardless of geography. To learn more about how Storage Exchange can support your self-storage investment needs, <Link to="/contactus" className="color-link">please get in touch.</Link></p>
                        </Col>
                    </Row> */}
                    <Row>
                    <div className="card width-40">
                       <div className="box">
                           <div className="img img-height-change">
                             <img src={logo5} alt="" />
                           </div>
                           <h2>Storage Facility Owners & Operators</h2>
                           <p>Existing Owners & Operators in the self storage industry who are looking to expand or sell their portfolio through an off-market acquisitions strategy.</p>
                       </div>
                       <div class="row margin-center">
	                      <Link className="btn btn-primary slide_right_alt" to="/contactus" role="button">Expand Your Portfolio</Link>
                        </div>
                    </div>
                    </Row>
                </div>
                
               
            </main>
        );
    }
}