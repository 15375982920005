import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../../Utils/config';
import { normalizeInput } from '../../../../Utils/utils';
import axios from 'axios';
import './MyAccount.css';

export default class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            passworderror: '',
            user: undefined,
            isChangePassword: false,
            stateList: [],
            positionList: [],
            positionId: 0,
            StateId: 0,
            position: '',
            showPosition: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.stateChange = this.stateChange.bind(this);
        this.positionChange = this.positionChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    handleSubmit(event, errors, values) {
        if (errors.length === 0) {
            if ((values.position == null || values.position.trim() === '') && (this.state.positionId === "5" || this.state.positionId === 5)) {
                alert("Title is required");
                errors.push('position');
            }
            if (errors.length === 0) {
                this.setState({ isLoading: true });
                const { phone } = this.state;
                const data = {
                    'FirstName': values.FirstName,
                    'LastName': values.LastName,
                    'Email': values.Email,
                    'PhoneNumber': phone,
                    'CompanyName': values.Company,
                    'StateId': this.state.StateId,
                    'State': values.State,
                    'PositionID': this.state.positionId,
                    'City': values.City,
                    'Position': values.position
                }

                let token = localStorage.getItem('accessKey');
                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }
                axios.post(CONFIG.API_URL + 'account/update/profile/', data)
                    .then(response => {
                        if (response.status === 200) {

                            //document.getElementById("r1").click();            //when email changed need to update token
                            if (response.data != "success") {
                                const getTokvalues = response.data.Token;
                                localStorage.setItem('accessKey', response.data.Token);
                                localStorage.setItem('name', response.data.FirstName + (response.data.LastName != null ? ' ' + response.data.LastName : ''));
                                localStorage.setItem('email', response.data.Email);
                                localStorage.setItem('role', localStorage.getItem('role'));
                                axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.Token;

                            }
                            this.setState({ isLoading: false, success: "Account updated successfully", error: '' });
                        }
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        if (err.response != null && err.response.status === 400) {
                            const error = err.response.data;
                            this.setState({ error, success: '' });
                        }
                        else {
                            const error = "Something went wrong.";
                            this.setState({ error });
                        }

                    });
            }
        }
    }

    stateChange(e) {
        var value = e.target.value;
        this.setState({ StateId: value })
    }
    positionChange(e) {
        var value = e.target.value;
        var position = '';
        var showPosition = e.target.value === "5" ? true : false;
        this.setState({ positionId: value, position: position, showPosition });
    }

    resetForm(event) {
        this.form.reset();
    }

    getProfile() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'lookup/state')
            .then(res => {
                let stateList = res.data;


                this.setState(state => ({ stateList: stateList }))

            })
            .catch((err) => {
                console.log(err);
                alert(err.response.data);
            })
        axios.get(CONFIG.API_URL + 'lookup/user/position')
            .then(res => {
                let positionList = res.data;
                this.setState({ positionList })

            })
            .catch((err) => {
                console.log(err);
            })

        axios.get(CONFIG.API_URL + 'account/profile/')
            .then(response => {
                if (response.status === 200) {
                    var user = response.data;
                    var phone = user.PhoneNumber;
                    var showPosition = user.PositionID === "5" || user.PositionID === 5 ? true : false;
                    this.setState({ isLoading: false, StateId: response.data.StateId, positionId: response.data.PositionID, showPosition, user, phone });
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, success: '' });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error });
                }

            });
    }

    componentDidMount() {
        this.getProfile();
    }

    changePasswordSubmit(event, errors, values) {
        if (errors.length === 0) {
            this.setState({ isLoading: true });
            const data = {
                'Password': values.Password,
                'ConfirmPassword': values.ConfirmPassword
            }

            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.post(CONFIG.API_URL + 'Account/changepassword/', data)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ isLoading: false, success: "Password changed successfully", isChangePassword: false, passworderror: '' });
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response != null && err.response.status === 400) {
                        const passworderror = err.response.data;
                        this.setState({ passworderror, success: '' });
                    }
                    else {
                        const passworderror = "Something went wrong.";
                        this.setState({ passworderror });
                    }

                });
        }
    }

    changePasswordOnclick() {
        this.setState({ isChangePassword: true });
    }

    resetChangePassword() {
        this.setState({ isChangePassword: false, passworderror: '' });
    }

    handlePhoneChange({ target: { value } }) {
        this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    };

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Profile';
        const { isLoading, error, passworderror, user, isChangePassword, success, stateList, StateId, positionList, positionId, phone, position, showPosition } = this.state;
        return (
            <main className="dashboard-layout-height background-clr-admin">

                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <Row>
                    <Col>
                        <div className="user-myaccount ">
                            <Row>
                                <div className="wrapper ">
                                    <div className={localStorage.getItem("role") === "Admin" ? "wrapper-content form-back-shadow" : "wrapper-content-condition form-back-shadow"}>
                                        <div>
                                            <div className=" text-center-account"><span className="success text-center-account">{success}</span></div>
                                            <h3 className="text-center-account">Edit Profile</h3>
                                            <span className="left-border"></span>
                                        </div>
                                        {user ?
                                            <React.Fragment>

                                                <AvForm onSubmit={this.handleSubmit.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.form = c)}>
                                                    <Row>
                                                        <div className={localStorage.getItem("role") === "Admin" ? "col-md-12" : "col-md-6"} >
                                                            <AvField name="FirstName" label="First Name:*" placeholder="FirstName" type="text" maxLength="255"
                                                                value={user.FirstName} validate={{
                                                                    required: { value: true, errorMessage: 'First Name is required' }
                                                                }} />
                                                            <AvField name="LastName" label="Last Name:*" placeholder="LastName" type="text" maxLength="255"
                                                                value={user.LastName} validate={{
                                                                    required: { value: true, errorMessage: 'Last Name is required' }
                                                                }} />
                                                            <AvField name="Phone" label="Phone Number:*" placeholder="xxx-xxx-xxxx" type="tel"
                                                                value={phone}
                                                                validate={
                                                                    {
                                                                        required: { value: true, errorMessage: 'Phone Number is required' },
                                                                        tel: { pattern: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone Number is invalid' }
                                                                    }} onChange={(e) => this.handlePhoneChange(e)} />
                                                            <AvField name="Email" label="Email" placeholder="Email" type="email" maxLength="256"
                                                                value={user.Email.toLowerCase()} validate={{
                                                                    required: { value: true, errorMessage: 'Email is required' },
                                                                    pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                                                }} />
                                                        </div>

                                                        {localStorage.getItem("role") === "Admin" ? "" :
                                                            <Col md={6}>
                                                                <AvField name="Company" label="Company:*" placeholder="Company" type="text" maxLength="1000"
                                                                    value={user.CompanyName} validate={{
                                                                        required: { value: true, errorMessage: 'Company is required' }
                                                                    }} />
                                                                <AvField type="select" name="Title" label="Title:" helpMessage="" value={positionId}
                                                                    onChange={(e) => this.positionChange(e)}>
                                                                    <option value=''>--Select--</option>
                                                                    {positionList ? positionList.map((c) =>
                                                                        <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                                {
                                                                    showPosition ?
                                                                        <AvField name="position" label="Title:*" placeholder="Title" type="text" maxLength="100"
                                                                            value={user.Position} />
                                                                        : ''
                                                                }
                                                                <AvField name="City" label="City:*" placeholder="City" type="text" maxLength="100"
                                                                    value={user.City} validate={{
                                                                        required: { value: true, errorMessage: 'City is required' }
                                                                    }} />

                                                                <AvField type="select" name="State" label="State:" helpMessage="" value={StateId}
                                                                    onChange={(e) => this.stateChange(e)} validate={{
                                                                        required: { value: true, errorMessage: 'State is required' },
                                                                    }}>
                                                                    <option value=''>--Select State--</option>
                                                                    {stateList ? stateList.map((c) =>
                                                                        <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                            </Col>
                                                        }
                                                        <Col md={12}>
                                                            <Button color="primary" type="submit">Submit</Button>
                                                            <br />
                                                            <span className="sign-error">{error}</span>
                                                        </Col>
                                                    </Row>
                                                </AvForm>

                                                <Link to="#" className="change-password-account text-center-account" onClick={this.changePasswordOnclick.bind(this)}>Change Password</Link>

                                                <Modal size="md" id="tst2" name="tst2" isOpen={isChangePassword} toggle={this.resetChangePassword.bind(this)} className="model-popup edit-market-dashboard">
                                                    <ModalHeader toggle={this.resetChangePassword.bind(this)}>Change Password</ModalHeader>
                                                    <ModalBody className="overflow-scroll basic-details">
                                                        <AvForm onSubmit={this.changePasswordSubmit.bind(this)} onReset={this.resetChangePassword.bind(this)} ref={c => (this.form = c)}>
                                                            <AvField name="Password" label="" placeholder="Password" type="password" validate={{
                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                            }} />
                                                            <AvField name="ConfirmPassword" label="" placeholder="Confirm Password" type="password" validate={{
                                                                required: { value: true, errorMessage: 'Confirm Password is required' },
                                                                match: { value: 'Password', errorMessage: 'Mismatch Confirm Password' }
                                                            }} />
                                                            <Row className="right-side-button">
                                                                <div>
                                                                    <Button color="primary" type="submit" className="btn-changepass">Change Password</Button>
                                                                    <Button color="primary" type="reset" className="cancel-btn-pass">Cancel</Button>
                                                                    <span className="sign-error">{passworderror}</span>
                                                                </div>
                                                            </Row>
                                                        </AvForm>
                                                    </ModalBody>
                                                </Modal>
                                            </React.Fragment>
                                            : ''}
                                    </div>

                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </main>);
    }
}
