import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './AddressNotFound.css';

export default class AddressNotFound extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen
        }
    }


    toggleClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const { isOpen } = this.state;
        return (
            <div>
                <Modal size="md" isOpen={isOpen} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                    <ModalHeader toggle={this.toggleClose.bind(this)}>
                        Address Not Found!
                    </ModalHeader>
                    <ModalBody>
                        <p>Please make sure you have entered a valid address.</p>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
