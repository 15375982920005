import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody, Container } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG, DEALSTATUS } from '../../../../Utils/config';
import axios from 'axios';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CurrencyFormat from 'react-currency-format';
import './DealTracker.css';
import editicon from '../../../../Assets/Img/edit.png';
import { DealViewModal } from '../Shared/DealView';
import { DealList } from '../Shared/DealList';
import view_icon from '../../../../Assets/Img/view.png';
import disable from '../../../../Assets/Img/disabel.png';

import { getFormattedInt } from '../../../../Utils/utils';
import { Pagination } from '../../../Shared/Pagination';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});


const DealTrackerWrapped = withStyles(actionsStyles, { withTheme: true })(
  Pagination,
);

export default class DealTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEdit: false,
      dealId: 0,
      storeId: 0,
      buyerId: 0,
      price: 0,
      searchError: '',
      dealList: [],
      pageCount: 0,
      statusId: 0,
      searchStatusId: 0,
      PropertyName: '',
      dealStatusList: [],
      propertyData: [],
      deal: {}, isOpen: false,
      excelParams: {},
    };
    this.dealView = React.createRef();
  }

  searchStatusChange(e) {
    var value = e.target.value;
    this.setState({ searchStatusId: value })
  }

  searchPropertyNameChange(e) {
    var value = e.target.value;
    this.setState({ PropertyName: value })
  }

  getAllStoreNames() {
    axios.get(CONFIG.API_URL + 'lookup/stores/1')
      .then(res => {
        this.setState({ propertyData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  resetSearch() {
    this.myFormSearch && this.myFormSearch.reset();
    this.setState({ pageCount: 0, searchStatusId: 0 });
    this.searchDeals(this.initialParam());
    this.handleExcelParam("", [], "");
  }

  searchSubmit(event, errors, values) {
    if (errors.length === 0) {
      const params = {
        'DealName': values.DealName,
        'PropertyName': this.state.PropertyName,
        // 'PropertyName': values.PropertyName,
        'StatusID': this.state.searchStatusId,
        'BuyerName': values.BuyerName,
        'BuyerEmail': values.BuyerEmail,
        'PageLength': CONFIG.PAGE_LENGTH,
        'Page': 1
      }
      this.setState({ pageCount: 0 });
      this.searchDeals(params);
      this.handleExcelParam("haveValues", errors, values);
    }
  }

  searchDeals(params) {
    this.setState({ isLoading: true });
    if (params == null) {
      params = this.state.dealList.Criteria;
      this.handleExcelParam("nullValue", [], params);
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.post(CONFIG.API_URL + 'admin/deals', params)
      .then(response => {
        if (response.status === 200) {
          this.setState({ dealList: [] });
          this.setState({ isLoading: false, dealList: response.data });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  }

  componentDidMount() {
    if ((localStorage.getItem('accessKey') == null || localStorage.getItem('accessKey') == '' || localStorage.getItem('role') != 'Admin')) {
      this.props.history.push({
        pathname: "/admin"
      });
    }
    this.getLookupDealStatus();
    this.getAllStoreNames();
    window.scrollTo(0, 0);
    this.searchDeals(this.initialParam());
    this.handleExcelParam("", [], "");
  }

  initialParam() {
    const params = {
      'DealName': '',
      'PropertyName': '',
      'StatusID': 0,
      'BuyerName': '',
      'BuyerEmail': '',
      'PageLength': CONFIG.PAGE_LENGTH,
      'Page': 1
    }
    return params;
  }

  getLookupDealStatus() {

    this.setState({ isLoading: true });
    axios.get(CONFIG.API_URL + 'lookup/deal/status').then(response => {
      if (response.status === 200) {
        this.setState({ dealStatusList: response.data, isLoading: false });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });

  }

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
    this.state.dealList.Criteria.Page = pageCount + 1;
    this.searchDeals(null);
  };

  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  viewDeal(deal) {
    this.dealView.current.getDeal(deal);
  }

  reFreshGridFromChild = () => {
    this.setState({ pageCount: 0 });
    this.searchDeals(this.initialParam());
    this.handleExcelParam("", [], "");
  }

  handleExcelParam(haveValuesOrNot, errors, values) {
    let params = {};
    if (haveValuesOrNot === "haveValues" && errors.length === 0) {
      params = {
        'DealName': values.DealName,
        'PropertyName': this.state.PropertyName,
        // 'PropertyName': values.PropertyName,
        'StatusID': this.state.searchStatusId,
        'BuyerName': values.BuyerName,
        'BuyerEmail': values.BuyerEmail,
        'PageLength': CONFIG.PAGE_LENGTH,
        'Page': 1
      }
    } else if (haveValuesOrNot === "nullValue") {
      params = values;
    } else {
      params = {
        'DealName': '',
        'PropertyName': '',
        'StatusID': 0,
        'BuyerName': '',
        'BuyerEmail': '',
        'PageLength': CONFIG.PAGE_LENGTH,
        'Page': 1
      }
    }
    this.setState({ excelParams: params });
  }

  excelReportExport() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let params = this.state.excelParams;
    axios({
      url: CONFIG.API_URL + "admin/deals/download",
      method: "POST",
      responseType: "blob",
      data: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "deal_tracker_report.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          const error = "Invalid request";
        } else {
          const error = err.response.statusText;
        }
      });
  }

  render() {
    document.title = CONFIG.PAGE_TITLE + 'Deal Tracker';
    const { isLoading, isEdit, dealId, searchError, dealList, status, dealStatusList, pageCount, propertyData } = this.state;

    return (
      <main className="dashboard-layout-height ">
        <DealViewModal ref={this.dealView} />
        {isLoading ? <div className="loader-wrap">< div className="page-loading"></div></div> : ''
        }
        <div className="dealtracker-search-list background-clr-admin">
          <div className="dealtracker-listing-dashboard leftandright-nomargin">
            <div className="heading">
              <h5>Deal Tracker</h5>
              {/* <div><span className="heading-broder"></span></div> */}
            </div>
            <div className="dealtracker-form form-back-shadow">
              <AvForm onSubmit={this.searchSubmit.bind(this)} ref={c => (this.myFormSearch = c)}>
                <Row>
                  {/* <Col md={3}>
                    <AvField name="DealName" label="Deal Name:" type="text" />
                  </Col> */}
                  <Col md={3}>
                    {/* <AvField name="PropertyName" label="Property Name:" type="text" /> */}
                    <AvField type="select" name="PropertyName" value={this.state.PropertyName}
                      onChange={(e) => this.searchPropertyNameChange(e)}
                      label="Property Name:">
                      <option value="">--Select--</option>
                      {propertyData ? propertyData.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.Name}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  <Col md={3}>
                    <AvField type="select" name="Status" value={this.state.searchStatusId}
                      onChange={(e) => this.searchStatusChange(e)}
                      label="Status:">
                      <option value="0">--Select--</option>
                      {dealStatusList ? dealStatusList.map(n => {
                        if (n.ID != DEALSTATUS.Closed && n.ID != DEALSTATUS.OnHold) {
                          return (
                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                              disabled={n.ID === '' ? true : false}
                              value={n.ID}>
                              {n.Name}
                            </option>
                          );
                        } else {

                        }
                      }) : ''}
                    </AvField>
                  </Col>
                  <Col md={3}>
                    <AvField name="BuyerName" label="Buyer Name:" type="text" />
                  </Col>
                  <Col md={3}>
                    <AvField name="BuyerEmail" label="Buyer Email:" type="text" />
                  </Col>
                  <Col md={12} className="btn-search btn-margin-top-remove">
                    {dealList.Deals && dealList.Deals.length > 0 &&
                      <a onClick={() => this.excelReportExport()} href="javascript:void(0)" className="excel-pop-download" title="Click here to download report">
                        <FontAwesomeIcon icon={faFileExcel} />
                        Download
                      </a>
                    }
                    <Button color="primary" className="search-butn" >Search</Button>
                    <Button onClick={this.resetSearch.bind(this)} className="btn-reset">Reset</Button>
                    <span className="sign-error">{searchError}</span>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </div>
          <Row>
            <Col md={6} className="total-numb">
              {dealList.Deals && dealList.Deals.length > 0 ? <p>Total Deals: {dealList.Pagination.TotalRecords}</p> : ''}
            </Col>
          </Row>
          <Row >
            <Col>
              <div className="overflow-scroll-table br-0 table-dealtracker-search table-deal table-model publish-property-table background-white">
                {dealList.Deals && dealList.Deals.length > 0 ?
                  <Table>
                    <DealList deal={dealList} triggerFromChild={this.reFreshGridFromChild} isDealTracker={true} />
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[1]}
                          colSpan={9}
                          count={dealList.Deals ? dealList.Pagination.TotalRecords : 0}
                          rowsPerPage={CONFIG.PAGE_LENGTH}
                          page={pageCount}
                          SelectProps={{
                            native: true,
                          }}
                          onChangePage={this.handleChangePageTrack}
                          onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                          ActionsComponent={DealTrackerWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  : <h6 className="no-records-found">No records found</h6>}
              </div>
            </Col>
          </Row>
        </div>
      </main >
    );
  }
}
