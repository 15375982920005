import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, NavbarText } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from '../../../Utils/config';
import { getUserType } from '../../../Utils/localStorage';
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Label, Input } from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';
//import msg from './../../Assets/Img/msg-icon.png';
import msg from '../../../Assets/Img/msg-icon.png'
import arrow from '../../../Assets/Img/arrow-msg.png';

import './ReplyMessage.css';

export default class ReplyMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      readData: [],
      unreadData: [],
      pageCount: 0,
      name: localStorage.getItem('name'),
      addModal: false,
      message: [],
      mailDes: '',
      desAlert: false,
      isRead: false,
      allMes: [],
      isShow: true,
      userType: '',
      readData: [],
      unreadMessages: [],
      isAllMessageLoaded: false,

    };
    this.handleChangeContent = this.handleChangeContent.bind(this);
    this.unreadMessage = this.unreadMessage.bind(this);
  }

  handleChangeContent(e) {
    let mailDes = e.target.value;
    this.setState({
      mailDes, desAlert: false
    })
  }

  async nextMessage() {
    let readData = this.state.readData;
    let unreadMessages = this.state.unreadMessages;
    const { message, isAllMessageLoaded, userType } = this.state;
    var rmessage = message;
    if (isAllMessageLoaded == false) {
      let token = localStorage.getItem('accessKey');

      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      await axios.get(CONFIG.API_URL + 'home/messages/' + userType)
        .then(res => {
          if (this.state.isRead === true) {
            readData = res.data.readMessage;
          }
          else {
            unreadMessages = res.data.unreadMessages
          }
          this.setState({ readData, unreadMessages, isAllMessageLoaded: true });
        })
        .catch((err) => {
          console.log(err);
        })
    }

    if (unreadMessages.length > 0) {
      var count = 0;
      var i = unreadMessages.forEach(function (x, index) {
        if (x.ID === rmessage.ID) {
          count = index;
        }
      })

      if ((count + 1) >= unreadMessages.length) {
        count = -1;
      }

      let message = unreadMessages[count + 1];

      this.setState({ message: message }, () => {
        this.unreadMessage(message);
      });
    }

    if (readData.length > 0) {
      var count = 0;
      var i = readData.forEach(function (x, index) {
        if (x.ID === message.ID) {
          count = index;
        }
      })

      if ((count + 1) >= readData.length) {
        alert('All notification has been read.');
      } else {
        let message = readData[count + 1];
        this.setState({ message: message });
        console.log("setState Mess");
      }
    }

  }

  replyMessage(message) {
    if (this.state.mailDes === '') {
      this.setState({ desAlert: true })
    }
    else {
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

      }
      const data = {
        'ID': message.ID,
        'MailSubject': message.MailSubject,
        'MailContent': this.state.mailDes,
        'FromUserID': message.FromUserID,
        'ToUserID': message.ToUserID,
        'ParentMessageID': message.ID,
        'StoreID': message.StoreID,
        'FromUserTypeID': message.FromUserTypeID,
        'ToUserTypeID': message.ToUserTypeID
      }
      axios.post(CONFIG.API_URL + 'home/replymail/', data)
        .then(res => {
          this.toggleClose();
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  toggleClose() {
    this.setState({ addModal: false, mailDes: '', desAlert: false });
    this.props.parentMethod();

  }

  componentDidMount() {
    this.commonComponentAction();
  }

  commonComponentAction() {
    this.state.message = [];
    let message = this.props.params;
    let isRead = this.props.isRead;
    let allMes = this.props.allmessage;
    let isShow = allMes === undefined ? true : false;
    var userType = getUserType();
    this.setState({ userType });

    if (isRead == false) {
      this.unreadMessage(message);
    } else {
      this.setState({ addModal: true, message: message, isRead: isRead, allMes: allMes, isShow })
    }
  }

  unreadMessage(message) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'home/readmessage/' + message.ID)
      .then(res => {
        var unReadcount = res.data.unreadCount;
        localStorage.setItem("Count", unReadcount);
        //have to update header read count.
        document.getElementById("divunreadmessage").click();
        var isShow = false;
        if (unReadcount > 0) {
          isShow = true
        } else {
          isShow = false
        };
        this.setState({ isShow, addModal: true, message, isRead: this.props.isRead, allMes: this.props.allmessage });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    const { isLoading, signinError, name, message, mailDes, desAlert, isShow } = this.state;

    return (
      <div >
        <div>
          <Modal size="md" isOpen={this.state.addModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
            <ModalHeader className="notification-popup" toggle={this.toggleClose.bind(this)}><img src={msg} alt="" /> Read more information
            </ModalHeader>
            <ModalBody className="overflow-scroll basic-details notification-body">
              <Row>
                <Col sm={8} className="table-no-border padding-remove-left">
                  <table>
                    <tr>
                      <td><b>From:</b></td>
                      <td><span className="name-user-msg">{message.FromUserName}</span>
                        <br /><p><span className="date-formate">{moment(message.CreatedDate).format("L")}</span> - {moment(message.CreatedDate).format("h:mm a")}</p>
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col sm={4}>
                  {isShow === true ? <Link onClick={this.nextMessage.bind(this)}>Read Next Message {/*<img src={arrow} className="img-arrow-width" alt="next" title="next Message" />*/}<FontAwesomeIcon icon={faLongArrowAltRight} alt="next" title="next Message" /></Link> : ''}
                </Col>
                <br />
                <Col md={12}>
                  <p className="msg-head">Subject:</p>
                  <p >{message.MailSubject}</p>
                  <p className="msg-head">Message:</p>
                  <p className="notification-content"><pre>{message.MailContent}</pre></p>
                </Col>
                <Col md={12}>
                  <Input type="textarea" className="description-box-height" placeholder="Reply:" name="Reply" value={mailDes ? mailDes : ''} onChange={this.handleChangeContent.bind(this)} />
                  {desAlert === true ? <p className="error displaymessage">Please enter message</p> : ''}

                </Col>
              </Row>

              <Row >
                <Col md={12} className="notification-top save-right">
                  <Button className="submit-msg-btn" onClick={this.replyMessage.bind(this, message)}>Submit</Button>
                  <Button className="cancle-btn-msg" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                </Col>
              </Row>

            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
