import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { PropertyUpload } from '../PropertyUpload';
import { CONFIG, PROPERTYSTATUS } from '../../../../../Utils/config';
import axios from 'axios';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { Editor } from "@tinymce/tinymce-react";
import './PropertySearch.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getFormattedInt } from '../../../../../Utils/utils';

import Geocode from "react-geocode";

import { AddressNotFound } from '../../Shared/AddressNotFound';
import { AssignSeller } from '../../Shared/AssignSeller';

import { Pagination } from '../../../../Shared/Pagination';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PRICEOPTION } from "../../../../../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

const StoreWrapped = withStyles(actionsStyles, { withTheme: true })(
  Pagination,
);

const validateRentableSQFT = (value, ctx) => {
  // if (isNaN(ctx.RentableSQFT)) {
  //   return "Rentable Square Feet should be a number";
  // } else 
  if (ctx.RentableSQFT <= 0 && ctx.RentableSQFT != "") {
    return "Rentable Square Feet should be greater than zero";
  }
  return true;
}
const propertyValidation = (value, ctx) => {
  if (ctx.PropertyTypeId == "0" || ctx.PropertyTypeId == "") {
    return "Property Type is required";
  }
  return true;
}

const validateAcreage = (value, ctx) => {
  // if (isNaN(ctx.Acreage)) {
  //   return "Acreage should be a number";
  // } else 
  if (ctx.Acreage <= 0 && ctx.Acreage != "") {
    return "Acreage should be greater than zero";
  }
  return true;
}
const validatePrice = (value, ctx) => {
  if (ctx.Price.toString().trim() == "" && (ctx.AlternatePriceOptionID == "" || ctx.AlternatePriceOptionID == "0")) {

    return "Price or Price Alternative is required";
  }
  return ctx.Price.toString().trim() == "" ? true : true;
}

export default class PropertySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchError: '',
      searchSellerError: '',
      ndaError: '',
      properties: [],
      pageCount: 0,
      addModal: false,
      propertyTypes: [],
      states: [],
      documentTypes: [],
      stateId: 0,
      storeTypeId: 0,
      documentModal: false,
      uploadModal: false,
      mvpRadius: 0,
      storeId: 0,
      file: '',
      documentTypeId: 0,
      documents: [],
      description: '',
      sellers: [],
      sellerPageCount: 0,
      sellerCriteria: '',
      sellerPagination: '',
      sellerModal: false,
      sellerSelectionInProgress: false,
      sellerSelected: false,
      propertyCriteria: {
        StoreName: '',
        City: '',
        StatusID: 0,
        StateID: 0,
        ShowUnPublished: false,
      },
      propertyPagination: {
        Page: 1,
        PageLength: CONFIG.PAGE_LENGTH,
        TotalRecords: 0,
        TotalPages: 0
      },
      storeStatusList: [],
      isAddressNotFound: false,
      storePrice: '',
      storeRentableSF: '',
      storeAcreage: '',
      alternatePriceList: [],
      AlternatePriceOptionID: null,
      AlternatePriceOptionDate: null,
      AlternatePriceOptionDateStr: null,
      errorsMsg: '',
      OffersDueDate: null,
      excelParams: {},
      propertyData: [],
    };
    this.toggleDocumentModal = this.toggleDocumentModal.bind(this);
    this.toggleUploadModal = this.toggleUploadModal.bind(this);
    this.toggleSellerModalClose = this.toggleSellerModalClose.bind(this);
    this.valuetext = this.valuetext.bind(this);
    this.getStoreDocuments = this.getStoreDocuments.bind(this);
    this.handler1 = this.handler1.bind(this);
    this.setSeller = this.setSeller.bind(this);
    this.restoreStore = this.restoreStore.bind(this);
    this.saveProperty = this.saveProperty.bind(this);
    this.viewProperty = this.viewProperty.bind(this);
    this.getAllStores = this.getAllStores.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleRentableSQFTChange = this.handleRentableSQFTChange.bind(this);
    this.handleAcreageChange = this.handleAcreageChange.bind(this);
    this.descOnChange = this.descOnChange.bind(this);
    this.onAuctionDateChange = this.onAuctionDateChange.bind(this);
    this.onOfferdueDateChange = this.onOfferdueDateChange.bind(this);
  }

  handlePriceChange({ target: { value } }) {
    this.setState(prevState => ({ storePrice: getFormattedInt(value.replace(/,/g, ''), prevState.storePrice) }));
  };

  handleRentableSQFTChange({ target: { value } }) {
    this.setState(prevState => ({ storeRentableSQFT: getFormattedInt(value.replace(/,/g, ''), prevState.storeRentableSQFT) }));
  };

  handleAcreageChange({ target: { value } }) {
    var acreage = value.split('.');
    var formattedacreage = getFormattedInt(acreage[0].replace(/,/g, ''));
    formattedacreage = acreage.length > 1 ? formattedacreage + "." + acreage[1] : formattedacreage;

    this.setState(prevState => ({ storeAcreage: formattedacreage }));
  };

  searchStoreStatusChange(e) {
    const { propertyCriteria } = this.state;
    propertyCriteria.StatusID = e.target.value;
    this.setState({ propertyCriteria })
  }

  showOnlyUnPublished(e) {
    const { propertyCriteria } = this.state;
    propertyCriteria.ShowUnPublished = !propertyCriteria.ShowUnPublished;
    this.setState({ propertyCriteria })
  }

  stateChange(e) {
    var value = e.target.value;
    this.setState({ stateId: value })
  }

  searchStateChange(e) {

    const { propertyCriteria } = this.state;
    propertyCriteria.StateID = e.target.value;
    this.setState({ propertyCriteria })
  }

  searchStateNameChange(e) {
    const { propertyCriteria } = this.state;
    propertyCriteria.StoreName = e.target.value;
    this.setState({ propertyCriteria });
  }

  storeTypeChange(e) {
    var value = e.target.value;
    this.setState({ storeTypeId: value })
  }

  descOnChange(articleDescription, editor) {
    this.setState({ description: articleDescription });
  }

  handler1() {
    this.getStoreDocuments();
  }

  searchSubmit(event, errors, values) {
    if (errors.length === 0) {
      const { propertyCriteria, propertyPagination } = this.state;
      // propertyCriteria.StoreName = values.StoreName;
      propertyCriteria.City = values.City;

      propertyPagination.Page = 1;
      propertyPagination.PageLength = CONFIG.PAGE_LENGTH;

      this.setState({
        propertyCriteria,
        propertyPagination,
        isLoading: true,
        pageCount: 0
      }, () => {
        this.searchStores();
      });
    }
  }

  searchStores() {
    const { propertyCriteria, propertyPagination, states } = this.state;

    let data = {
      StoreName: propertyCriteria.StoreName,
      City: propertyCriteria.City,
      StatusID: propertyCriteria.StatusID,
      StateID: propertyCriteria.StateID,
      Page: propertyPagination.page,
      PageLength: propertyPagination.PageLength,
      ShowUnPublished: propertyCriteria.ShowUnPublished,
    }
    let searchStateName = states.filter(n => n.ID === Number(propertyCriteria.StateID))[0]?.Name
    let excelStateFullName = { StateName: searchStateName !== undefined ? searchStateName : '' };
    this.setState({ excelParams: { ...data, ...excelStateFullName } });

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'admin/stores', data)
      .then(response => {
        if (response.status === 200) {

          let result = response.data;
          this.setState({ isLoading: false, properties: result.Stores, propertyPagination: result.Pagination });

        }
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const sighinerror = err.response.data;
          this.setState({ sighinerror });
        }
        else {
          const sighinerror = "Something went wrong.";
          this.setState({ sighinerror });
        }

      });
  }

  initialLookup() {
    axios.get(CONFIG.API_URL + 'lookup/propertylookup')
      .then(response => {
        if (response.status === 200) {
          let lookups = response.data;
          this.setState({ propertyTypes: lookups.storeTypes, states: lookups.states, documentTypes: lookups.documentTypes });

        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const sighinerror = err.response.data;
          this.setState({ sighinerror });
        }
        else {
          const sighinerror = "Something went wrong.";
          this.setState({ sighinerror });
        }

      });
  }
  alternatePriceOption(e) {
    var value = e.target.value;
    this.setState({ AlternatePriceOptionID: value })
  }
  addtoggleModal() {
    this.setState(state => ({ addModal: !state.addModal, AlternatePriceOptionID: '', AlternatePriceOptionDate: null, OffersDueDate: null }))
  }

  toggleClose = () => {
    this.myFormRef && this.myFormRef.reset();
    this.setState(state => ({ addModal: false, storeAcreage: '', storePrice: '' }))
  };

  toggleDocumentModal() {
    this.getStoreDocuments();
    this.setState(state => ({ documentModal: !state.documentModal, isLoading: false }));
  }

  documentModalClose() {
    const isNDADocUploaded = this.state.documents.indexOf(8) == -1 ? false : true;

    if ((this.state.documents == null || this.state.documents.length == 0
      || !this.state.documents.some(doc => (doc.DocumentTypeID == 8))) && this.state.saveDraft == false) {
      this.setState(state => ({ ndaError: 'Please upload NDA Document' }));
    }
    else {
      this.setState(state => ({ documentModal: false, isLoading: false, ndaError: '', sellerModal: true }));
      //this.getAllSellers();
    }
  };

  toggleUploadModal(documentTypeId) {
    this.setState(state => ({ uploadModal: !state.uploadModal, isLoading: false, documentTypeId: documentTypeId }));
  }

  toggleUploadClose = () => this.setState(state => ({ uploadModal: false, isLoading: false }));

  toggleSellerModal() {
    this.setState(state => ({ sellerModal: !state.sellerModal }));
  }

  toggleSellerModalClose = () => this.setState(state => ({ sellerModal: false, isLoading: false }), () => {
    this.getAllStores();
  });

  componentDidMount() {
    Geocode.setApiKey(CONFIG.GeolocationApiKey);
    Geocode.setLanguage("en");
    Geocode.enableDebug();

    if ((localStorage.getItem('accessKey') == null || localStorage.getItem('accessKey') == '' || localStorage.getItem('role') != 'Admin')) {
      this.props.history.push({
        pathname: "/admin"
      });
    }
    window.scrollTo(0, 0);
    this.getLookupStoreStatus();
    this.getAllStoreNames();
    this.initialLookup();
    this.getAlternatePriceOption();
    this.getAllStores();
  }

  resetSellerSearch() {
    this.formSeller && this.formSeller.reset();
    this.getAllSellers();
  }

  getAllStoreNames() {
    axios.get(CONFIG.API_URL + 'lookup/stores/1')
      .then(res => {
        this.setState({ propertyData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLookupStoreStatus() {
    axios.get(CONFIG.API_URL + 'lookup/store/status').then(response => {
      if (response.status === 200) {
        this.setState({ storeStatusList: response.data, isLoading: false });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  getAllStores() {
    this.setState({
      propertyCriteria: {
        StoreName: '',
        City: '',
        StatusID: 0,
        StateID: 0,
        ShowUnPublished: false,
      },
      propertyPagination: {
        Page: 1,
        PageLength: CONFIG.PAGE_LENGTH,
        TotalRecords: 0,
        TotalPages: 0
      },
      pageCount: 0
    }, () => {
      this.searchStores();
    }
    );
  }

  handleInit() {
  }

  saveDraftClick() {
    this.setState({ saveDraft: true }, () => {
      document.getElementById('btn').click();
    });
  }

  saveClick() {
    this.setState({ saveDraft: false }, () => {
      document.getElementById('btn').click();
    });
  }

  saveProperty(event, errors, values, lat, lng) {
    const { saveDraft } = this.state;
    if (values.AlternatePriceOptionDate) {
      var today = new Date(values.AlternatePriceOptionDate);
      values.AlternatePriceOptionDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    if (errors.length === 0) {
      const data = {
        'StoreID': 0,
        'StoreName': values.StoreName ? values.StoreName.trim() : '',
        'Address1': values.Address1 ? values.Address1.trim() : '',
        'Address2': values.Address2 ? values.Address2.trim() : '',
        'City': values.City ? values.City.trim() : '',
        'StateID': this.state.stateId,
        'ZipCode': values.ZipCode ? values.ZipCode.trim() : '',
        'Country': 'USA',//values.Country,
        'Description': this.state.description,
        'StoreTypeID': this.state.storeTypeId,
        'RentableSQFT': values.RentableSQFT ? values.RentableSQFT.trim().replace(/,/g, '') : '',
        'Acerage': values.Acreage ? values.Acreage.trim().replace(/,/g, '') : '',
        'Price': values.Price ? values.Price.trim().replace(/,/g, '') : null,
        'IsRoomForExpansion': values.IsRoomForExpansion == '1' ? true : values.IsRoomForExpansion == '0' ? false : null,
        'IsFeatureListing': values.IsFeatureListing,
        "Latitude": lat,
        "Longitude": lng,
        'ISActive': saveDraft ? false : true,
        'StatusID': saveDraft ? PROPERTYSTATUS.Draft : PROPERTYSTATUS.Available,
        'AlternatePriceOptionID': this.state.AlternatePriceOptionID === "0" || this.state.AlternatePriceOptionID === " " ? null : this.state.AlternatePriceOptionID,
        'AlternatePriceOptionDate': this.state.AlternatePriceOptionDate ? this.state.AlternatePriceOptionDate : null,
        'AlternatePriceOptionDateStr': this.state.AlternatePriceOptionDate ? this.state.AlternatePriceOptionDate.toLocaleDateString() : null,
        'OffersDueDate': this.state.OffersDueDate ? this.state.OffersDueDate : null,
        'OffersDueDateStr': this.state.OffersDueDate ? this.state.OffersDueDate.toLocaleDateString() : null,
      }
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.post(CONFIG.API_URL + 'admin/store', data)
        .then(response => {
          let storeId = response.data.StoreID;
          this.setState({
            storeId: storeId, addModal: false, description: '', storeAcreage: '', storePrice: '', stateId: 0, storeTypeId: 0
          }, () => {
            this.toggleDocumentModal();
            this.getAllStoreNames();
          });
        })
        .catch(err => {

          this.setState({ isLoading: false });
          if (err.response != null && err.response.status === 400) {
            const sighinerror = err.response.data;
            this.setState({ sighinerror });
          }
          else {
            const sighinerror = "Something went wrong.";
            this.setState({ sighinerror });
          }

        });
    }
  }

  addProperty(event, errors, values) {
    var errorsMsg = "";
    if (values.AlternatePriceOptionID === "6" && this.state.AlternatePriceOptionDate === null) {
      errorsMsg = 'Date is required';
    }
    this.setState({ errorsMsg });
    if (errors.length === 0 && errorsMsg == "") {
      var acreageError = "";
      if (values.Acreage.trim() == "" && values.RentableSQFT.trim() == "") {
        acreageError = 'Acreage or Rentable Square Feet is required';
      }
      // else {
      //   if (values.Acreage.trim() != "" && isNaN(values.Acreage)) {
      //     acreageError = "Acreage should be a number";
      //   } 
      //   else if (values.RentableSQFT.trim() != "" && isNaN(values.RentableSQFT)) {
      //     acreageError = "Rentable Square Feet should be a number";
      //   }
      // }

      this.setState({ acreageError });
      if (acreageError == "") {
        const { states, stateId } = this.state;
        var state = states.filter(s => s.ID == stateId);

        var address = values.Address1 + "," + values.City + "," + state[0].Name + ",USA";// + values.Country;
        Geocode.fromAddress(address).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            this.saveProperty(event, errors, values, lat, lng);
          },
          error => {
            console.error(error);
            //this.saveProperty(event, errors, values, "", "");
            this.setState({ isAddressNotFound: true });
          }
        );
      }
    }
  }
  getAlternatePriceOption() {
    axios.get(CONFIG.API_URL + 'lookup/store/alternateprice').then(response => {
      if (response.status === 200) {
        this.setState({ alternatePriceList: response.data });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }
  getStoreDocuments() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let storeId = this.storeId != undefined ? this.storeId : this.state.storeId;
    axios.get(CONFIG.API_URL + 'admin/store/documents/' + storeId)
      .then(response => {

        let documents = response.data;
        this.setState({ documents: documents, uploadModal: false, ndaError: '' }, () => {
          //this.toggleDocumentModal();
        });
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const sighinerror = err.response.data;
          this.setState({ sighinerror });
        }
        else {
          const sighinerror = "Something went wrong.";
          this.setState({ sighinerror });
        }

      });
  }

  setSeller(sellerId) {

    this.setState({ sellerSelectionInProgress: true });
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let data = {
      SellerId: sellerId,
      StoreIds: [this.state.storeId],
      "IsAssignSeller": true
    }

    axios.post(CONFIG.API_URL + 'admin/seller/store', data)
      .then(response => {

        this.setState({
          sellerSelected: true
        }, () => {
          this.toggleSellerModalClose();
        });
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const searchSellerError = err.response.data;
          this.setState({ searchSellerError, sellerSelectionInProgress: false });
        }
        else {
          const searchSellerError = "Something went wrong.";
          this.setState({ searchSellerError, sellerSelectionInProgress: false });
        }

      });
  }

  valuetext(value) {
    if (value != this.state.mvpRadius) {
      // 
      const data = {
        'StoreID': this.state.storeId,
        'MVPRadius': value
      };
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.post(CONFIG.API_URL + 'admin/store/radius', data)
        .then(response => {
          if (response.status === 200) {
            let mvpRadius = value;
            this.setState({ mvpRadius: mvpRadius }, () => {
              setTimeout(mvpRadius = value, 500);
            });
          }
        })
        .catch(err => {

          if (err.response != null && err.response.status === 400) {
            const sighinerror = err.response.data;
            this.setState({ sighinerror });
          }
          else {
            const sighinerror = "Something went wrong.";
            this.setState({ sighinerror });
          }
        });
    }

    return `$${value}`;
  }

  resetSearch() {
    this.myFormSearch && this.myFormSearch.reset();
    this.getAllStores();
  }


  viewProperty(storeId) {
    const { from } = { from: { pathname: '/admin/property/' + storeId } };
    this.props.history.push(from);
  }
  onAuctionDateChange(date) {
    var today = date !== undefined && date !== null ? new Date(date) : new Date();
    var dates = date !== undefined && date !== null ? new Date(today.getFullYear(), today.getMonth(), today.getDate()) : null;
    this.state.AlternatePriceOptionDate = dates;
    this.setState({ AlternatePriceOptionDate: dates });
  }
  onOfferdueDateChange(offerdate) {
    var todays = offerdate !== undefined && offerdate !== null ? new Date(offerdate) : new Date();
    var offerdue = offerdate !== undefined && offerdate !== null ? new Date(todays.getFullYear(), todays.getMonth(), todays.getDate()) : null;
    this.state.OffersDueDate = offerdue;
    this.setState({ OffersDueDate: offerdue });
  }
  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
    this.state.propertyPagination.page = pageCount + 1;
    this.searchStores();
  };

  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  deleteStore = (storeId) => {
    confirmAlert({
      title: 'Delete Store',
      message: 'Are you sure want to unpublish this store?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.request({
              url: CONFIG.API_URL + 'admin/store/' + storeId,
              method: 'delete',
            })
              .then(res => {
                this.searchStores();
                this.getAllStoreNames();
              })
              .catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                  const searchError = err.response.data;
                  this.setState({ searchError });
                }
                else {
                  const searchError = "Something went wrong.";
                  this.setState({ searchError });
                }
              });
          }
        },
        {
          label: 'No'
          //onClick: () => alert('Click No')
        }
      ]
    });
  };

  deleteStorePermanant = (storeId) => {
    confirmAlert({
      title: 'Delete Store',
      message: 'Are you sure want to delete this store?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.request({
              url: CONFIG.API_URL + 'admin/store/' + storeId + '/true',
              method: 'put',
            })
              .then(res => {
                this.searchStores();
                this.getAllStoreNames();
              })
              .catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                  const searchError = err.response.data;
                  this.setState({ searchError });
                }
                else {
                  const searchError = "Something went wrong.";
                  this.setState({ searchError });
                }
              });
          }
        },
        {
          label: 'No'
          //onClick: () => alert('Click No')
        }
      ]
    });
  };

  restoreStore(storeId) {
    let token = localStorage.getItem('accessKey');
    this.setState({ isLoading: true });
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.request({
      url: CONFIG.API_URL + 'admin/store/' + storeId + '/false',
      method: 'put',
    })
      .then(res => {
        this.searchStores();
        this.getAllStoreNames();
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const searchError = err.response.data;
          this.setState({ searchError });
        }
        else {
          const searchError = "Something went wrong.";
          this.setState({ searchError });
        }
      });
  }

  excelReportExport() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let data = this.state.excelParams;
    axios({
      url: CONFIG.API_URL + "admin/stores/download",
      method: "POST",
      responseType: "blob",
      data: data,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "property_search_report.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          const error = "Invalid request";
        } else {
          const error = err.response.statusText;
        }
      });
  }

  render() {
    document.title = CONFIG.PAGE_TITLE + 'Property Listings';
    const { isLoading, description, searchError, properties, pageCount, states, propertyTypes, documentTypes, documents, propertyData,
      sellers, sellerPageCount, sellerCurrentPage, searchSellerError, sellerSelectionInProgress, sellerSelected, propertyPagination,
      propertyCriteria, storeStatusList, acreageError, ndaError, isAddressNotFound, storeId, sellerModal, alternatePriceList, AlternatePriceOptionID, errorsMsg } = this.state;

    return (
      <main className="dashboard-layout-height background-clr-admin">
        {isAddressNotFound ? <AddressNotFound isOpen={isAddressNotFound} /> : ''}
        {sellerModal ? <AssignSeller isOpen={sellerModal} parentMethod={this.getAllStores} storeID={storeId} /> : ''}
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <div className="property-search-list">
          {/* <PropertyAdd
          isOpen={this.state.isModalOpen}
          toggle={this.state.isModalOpen}
        /> */}
          <div className="property-listing-dashboard leftandright-nomargin">
            <div className="heading">
              <h5>Property Listing</h5>
              {/* <div><span className="heading-broder"></span></div> */}
            </div>
            <div className="property-search-form form-back-shadow ">
              <AvForm onSubmit={this.searchSubmit.bind(this)} ref={c => (this.myFormSearch = c)}>
                <Row>
                  <Col md={3}>
                    {/* <AvField name="StoreName" label="Store Name:" type="text" /> */}
                    <AvField type="select" name="StoreName" value={propertyCriteria.StoreName}
                      onChange={(e) => this.searchStateNameChange(e)}
                      label="Store Name:">
                      <option value="">--Select--</option>
                      {propertyData ? propertyData.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.Name}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  <Col md={3}>
                    <AvField name="City" label="City:" type="text" />
                  </Col>
                  <Col md={3}>
                    <AvField type="select" name="State" value={propertyCriteria.StateID}
                      onChange={(e) => this.searchStateChange(e)}
                      label="State:">
                      <option value="0">--Select--</option>
                      {states ? states.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  <Col md={3}>
                    <AvField type="select" name="State" value={propertyCriteria.StatusID}
                      onChange={(e) => this.searchStoreStatusChange(e)}
                      label="Status:"
                    >
                      <option value="0">--Select--</option>
                      {storeStatusList ? storeStatusList.map(n => {
                        return (
                          <option key={n.ID}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>

                  <Col md={6}>
                    <AvField type="checkbox" className="checkbox-align" value={propertyCriteria.ShowUnPublished} name="NDA Agreement" label="Show only unpublished properties"
                      onChange={(e) => this.showOnlyUnPublished(e)} />
                  </Col>
                  <Col md={6} className="text-align-right">
                    {properties.length > 0 &&
                      <a onClick={() => this.excelReportExport()} href="javascript:void(0)" className="excel-pop-download" title="Click here to download report">
                        <FontAwesomeIcon icon={faFileExcel} />
                        Download
                      </a>
                    }
                    <Button className="search-butn" color="primary">Search</Button>
                    <Button onClick={this.resetSearch.bind(this)} className="btn-reset">Reset</Button>
                  </Col>
                  <span className="sign-error">{searchError}</span>

                </Row>
              </AvForm>
            </div>
          </div>

          <Row>
            <Col md={6} className="total-numb">
              {properties.length > 0 ? <p>Total Property Listings: {propertyPagination.TotalRecords}</p> : ''}
            </Col>
            <Col md={6} className="add-new-btn">
              <Link onClick={this.addtoggleModal.bind(this)} >[+] Add New Property</Link>
              {/* <Button onClick={this.toggleDocumentModal} >Add nested</Button> */}
            </Col>
          </Row>
          <Row className="margin-bottom-25">
            <Col>
              <div className="overflow-scroll-table br-0 table-model background-white publish-property-table">
                {properties.length > 0 ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell className="property-name-width">Property Name</TableCell>
                        <TableCell className="property-seller">City</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Zip</TableCell>
                        <TableCell className="property-seller">Seller</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {properties ? properties.map((record) =>
                        <TableRow key={record.StoreID} className={record.ISActive ? "publish-row" : "unpublish-row"}>
                          <TableCell>{moment(record.CreatedDate).format("L")}</TableCell>
                          <TableCell>{record.StoreName}</TableCell>
                          <TableCell>{record.City}</TableCell>
                          <TableCell>{record.StateCode}</TableCell>
                          <TableCell>{record.ZipCode}</TableCell>
                          <TableCell>{record.SellerName}</TableCell>
                          <TableCell>{record.Status}</TableCell>
                          {/* <TableCell>{record.StatusID === 1 ? 'For Sale' : record.StatusID === 2 ? 'Closed' : 'Under Contract'}</TableCell> */}
                          <TableCell>
                            <Button type="view" className="button-common background-view" onClick={() => this.viewProperty(record.StoreID)}>View</Button>
                            {
                              record.ISActive ?
                                <Button type="delete" className="button-common background-delete" onClick={() => this.deleteStore(record.StoreID)}>UnPublish</Button> :
                                <Button type="restore" className="button-common background-publish" onClick={() => this.restoreStore(record.StoreID)}>Publish</Button>
                            }
                            <Button type="delete" className="button-common" onClick={() => this.deleteStorePermanant(record.StoreID)}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      ) :
                        <TableRow></TableRow>
                      }
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={1}
                          colSpan={8}
                          count={properties ? this.state.propertyPagination.TotalRecords : 0}
                          rowsPerPage={CONFIG.PAGE_LENGTH}
                          page={pageCount}
                          SelectProps={{
                            native: true,
                          }}
                          onChangePage={this.handleChangePageTrack}
                          onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                          ActionsComponent={StoreWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  : <h6 className="no-records-found">No records found</h6>}
              </div>
            </Col>
          </Row>
          <Modal size="md" id="tst1" name="tst1" isOpen={this.state.addModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard price-alternative-added">
            <ModalHeader toggle={this.toggleClose.bind(this)}>Add New Property</ModalHeader>
            <ModalBody className="overflow-scroll basic-details">
              <AvForm onSubmit={this.addProperty.bind(this)} ref={c => (this.myFormRef = c)}>
                <Row>
                  <Col md={6}>
                    <Col md={12} className="padding-left-0">
                      <AvField name="StoreName" label="Property Name:*" type="text" maxLength="200" validate={{
                        required: { value: true, errorMessage: 'Property Name is required' }
                      }} />
                    </Col>
                    <Col md={12} className="padding-left-0">
                      <AvField name="Address1" label="Address Line 1:*" maxLength="300"
                        validate={{
                          required: { value: true, errorMessage: 'Address Line 1 is required' }
                        }} />
                    </Col>
                    <Col md={12} className="padding-left-0">
                      <AvField name="City" label="City:*" maxLength="100" validate={{
                        required: { value: true, errorMessage: 'City is required' }
                      }} />
                    </Col>
                    <Col md={12} className="padding-left-0">
                      <AvField name="ZipCode" label="Zip Code:*" maxLength="20" validate={{
                        required: { value: true, errorMessage: 'Zip Code is required' }
                      }} />
                    </Col>
                    <Col md={12} className="padding-left-0">
                      <AvField name="RentableSQFT" label="Rentable Sqare Feet:" maxLength="9"
                        validate={{
                          pattern: { value: /^[0-9,]*$/, errorMessage: 'Rentable Sqare Feet is invalid' }
                        }}
                        value={this.state.storeRentableSQFT}
                        onChange={(e) => this.handleRentableSQFTChange(e)}
                      />
                    </Col>
                    <Col md={12} className="padding-left-0">
                      <AvRadioGroup inline name="IsRoomForExpansion" className="room-for-expansion-font margin-bottom-remove" label="Room For Expansion:">
                        <AvRadio label="Yes" value="1" />
                        <AvRadio label="No" value="0" />
                      </AvRadioGroup>
                    </Col>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col xs={6} >
                        <AvField name="Price" label="Property Value:*" type="text" maxLength="13"
                          validate={{
                            pattern: { value: /^[0-9,]*$/, errorMessage: 'Property Value is invalid' },
                            validatePrice: validatePrice
                          }}
                          value={this.state.storePrice}
                          onChange={(e) => this.handlePriceChange(e)}
                        />
                      </Col>
                      <Col xs={6} className="padding-right-0">
                        <AvField type="select" name="AlternatePriceOptionID" label="Price Alternative:"
                          value={this.state.AlternatePriceOptionID}
                          onChange={(e) => this.alternatePriceOption(e)}
                        >
                          <option value="0">--Select--</option>
                          {alternatePriceList ? alternatePriceList.map(n => {
                            return (
                              <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                disabled={n.ID === '' ? true : false}
                                value={n.ID}>
                                {n.Name}
                              </option>
                            );
                          }) : ''}
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>

                      </Col>
                      {this.state.AlternatePriceOptionID === "6" ?
                        <Col md={6} className="padding-right-0">
                          <div className="auction-date">
                            <DatePicker className="is-touched is-pristine av-valid form-control" placeholderText="Date" selected={this.state.AlternatePriceOptionDate} dateFormat={"MM-dd-yyyy"} onChange={this.onAuctionDateChange.bind(this)} minDate={new Date()} />
                          </div>
                          {errorsMsg ? <p className="error error-position">{errorsMsg}</p> : ''}
                        </Col>
                        : ''}
                    </Row>
                    <Col md={12} className="padding-right-0">
                      <AvField name="Address2" label="Address Line 2:" maxLength="100" />
                    </Col>
                    <Col md={12} className="padding-right-0">
                      <AvField type="select" name="State" value={this.state.stateId}
                        onChange={(e) => this.stateChange(e)}
                        label="State:*"
                        validate={{
                          required: { value: true, errorMessage: 'State is required' },
                        }}>
                        <option value="0">--Select--</option>
                        {states ? states.map(n => {
                          return (
                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                              disabled={n.ID === '' ? true : false}
                              value={n.ID}>
                              {n.Name}
                            </option>
                          );
                        }) : ''}
                      </AvField>
                    </Col>
                    {/* <Col md={6}>
                    <AvField name="Country" label="Country:" maxLength="20" validate={{
                      required: { value: true, errorMessage: 'Country is required' }
                    }} />
                  </Col> */}
                    <Col md={12} className="padding-right-0">
                      <AvField type="select" name="PropertyTypeId" value={this.state.storeTypeId}
                        onChange={(e) => this.storeTypeChange(e)}
                        label="Property Type:*"
                        validate={{
                          propertyValidation: propertyValidation
                        }} >
                        <option value="0">--Select--</option>
                        {propertyTypes ? propertyTypes.map(n => {
                          return (
                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                              disabled={n.ID === '' ? true : false}
                              value={n.ID}>
                              {n.Name}
                            </option>
                          );
                        }) : ''}
                      </AvField>
                    </Col>
                    <Col md={12} className="padding-right-0">
                      <AvField name="Acreage" label="Acreage:" maxLength="9"
                        validate={{
                          pattern: { value: /^[0-9,.]*$/, errorMessage: 'Acreage is invalid' }
                        }}
                        value={this.state.storeAcreage}
                        onChange={(e) => this.handleAcreageChange(e)}
                      />
                    </Col>
                    <Col md={12} className="padding-right-0">
                      <div className="auction-date">
                        <Label>Offers Due:</Label>
                        <DatePicker className="is-touched is-pristine av-valid form-control" placeholderText="Date" selected={this.state.OffersDueDate}
                          dateFormat={"MM-dd-yyyy"} onChange={this.onOfferdueDateChange.bind(this)} minDate={new Date()} />
                      </div>
                    </Col>
                    <Col md={12} className="property-box">
                      <AvField type="checkbox" name="IsFeatureListing" label="Featured Listing:" />
                    </Col>
                  </Col>


                  <Col xs={12} className="property-description">
                    <Label>Description:</Label>
                    {/* <textarea onChange={(e) => this.descOnChange(e)} value={description} label="Description:" className="property-description" /> */}
                    <Editor
                      value={description}
                      apiKey={CONFIG.TINY_MCE_KEY}
                      init={{
                        height: 270,
                        menubar: true,
                        plugins: "link",
                      }}
                      onEditorChange={this.descOnChange}
                    />
                  </Col>
                </Row>
                {acreageError ? <p className="error error-position">{acreageError}</p> : ''}
                <Row className="save-right">
                  <Col md={12}>
                    <Button id="btn" className="displaynone">Save</Button>
                    <Button id="btn1" className="next-btn submit-btn btn-design add-padding-left-right" onClick={this.saveClick.bind(this)}>Save </Button>
                    <Button id="btn2" className="next-btn submit-btn btn-design add-padding-left-right add-colorbtn" onClick={this.saveDraftClick.bind(this)}>Save As Draft</Button>
                    <Button className="btn-reset" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal size="md" id="tst2" name="tst2" isOpen={this.state.documentModal} toggle={this.documentModalClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
            <ModalHeader toggle={this.documentModalClose.bind(this)}>Add New Property
            </ModalHeader>
            <ModalBody className="overflow-scroll basic-details">
              <div>
                <Typography id="discrete-slider" gutterBottom>
                  Explorer Radius
                </Typography>
                <Slider className="radius-scroll"
                  defaultValue={this.state.mvpRadius}
                  getAriaValueText={this.valuetext}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="on"
                  step={1}
                  marks
                  min={1}
                  max={10}
                />
              </div>
              <div>
                <h2 className="documents-font-size">Documents</h2>
                <div className="table-scroll table-padding table-property-croll">
                  {documentTypes && documentTypes.length > 0 ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Document Name</TableCell>
                          <TableCell>Action</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          documentTypes.map((docType) =>
                            <React.Fragment>
                              <TableRow>
                                <TableCell>{docType.Name}</TableCell>
                                <TableCell>
                                  {docType.ID == 8 ?
                                    documents.some(doc => (doc.DocumentTypeID == 8)) ? '' : <PropertyUpload parentMethod={this.handler1} storeId={this.state.storeId} documentId={docType.ID} />
                                    : <PropertyUpload parentMethod={this.handler1} storeId={this.state.storeId} documentId={docType.ID} />
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow key={docType.ID}>
                                <TableCell colspan="2" className="padding-add-b10">
                                  {documents.some(doc =>
                                    (doc.DocumentTypeID == docType.ID)) ?
                                    <Table className="inside-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Uploaded Date</TableCell>
                                          <TableCell>View</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {documents.map((doc) =>
                                          doc.DocumentTypeID == docType.ID ?
                                            <TableRow>
                                              <TableCell>
                                                {
                                                  docType.ID == 8 && doc.ModifiedDate !== null ?
                                                    moment(doc.ModifiedDate).format("L")
                                                    :
                                                    moment(doc.CreatedDate).format("L")
                                                }
                                              </TableCell>
                                              <TableCell>
                                                <a target="_blank" rel="noopener noreferrer" href={'/StoreDocuments/' + doc.FileName} download={doc.ActualFileName != null ? doc.ActualFileName : doc.FileName} className="link-view">View</a>
                                              </TableCell>

                                            </TableRow>
                                            : '')}
                                      </TableBody>
                                    </Table>
                                    : ''}
                                </TableCell>
                              </TableRow>

                            </React.Fragment>
                          )
                        }
                      </TableBody>
                    </Table>
                    : ''
                  }
                </div>
                <Modal size="md" id="tst3" name="tst3" isOpen={this.state.uploadModal} toggle={this.toggleUploadClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                  <ModalHeader toggle={this.toggleUploadClose.bind(this)}>Add New Property
                  </ModalHeader>
                  <ModalBody className="overflow-scroll basic-details">
                    <FilePond ref={ref => this.pond = ref}
                      files={this.state.files}
                      allowMultiple={false}
                      allowFileTypeValidation={true}
                      allowImagePreview={false}

                      //data-max-file-size="1MB"
                      allowFileSizeValidation={true}
                      maxFileSize='25MB'
                      maxTotalFileSize='25MB'
                      labelMaxFileSizeExceeded='File is too large'
                      labelMaxFileSize='Maximum file size is 25MB'
                      //acceptedFileTypes={['file/xls', 'file/xl', 'file/xlsl']}
                      labelIdle="Drag & Drop your Document OR Browse"
                      //accept="*"
                      // maxFiles={3}
                      server={{
                        url: CONFIG.API_URL + 'admin/upload',
                        timeout: 7000,
                        process: (fieldName, file, metadata, load, error, progress, abort, template) => {

                          // fieldName is the name of the input field
                          // file is the actual file object to send
                          const formData = new FormData();
                          formData.append('file', file, file.name);
                          formData.append('StoreID', this.state.storeId);
                          formData.append('DocumentTypeID', this.state.documentTypeId);

                          const request = new XMLHttpRequest();
                          let token = localStorage.getItem('accessKey');
                          request.open('POST', CONFIG.API_URL + 'admin/upload/document');

                          // Should call the progress method to update the progress to 100% before calling load
                          // Setting computable to false switches the loading indicator to infinite mode
                          request.upload.onprogress = (e) => {
                            progress(e.lengthComputable, e.loaded, e.total);
                          };

                          // Should call the load method when done and pass the returned server file id
                          // this server file id is then used later on when reverting or restoring a file
                          // so your server knows which file to return without exposing that info to the client
                          request.onload = function (e) {
                            if (request.status >= 200 && request.status < 300) {

                              //e.pond.removeFiles();
                              const template = request.responseText;
                              // var logoJson = JSON.parse(request.responseText);


                              localStorage.setItem('template', template);
                              //console.log(template);
                              // the load method accepts either a string (id) or an object
                              load(request.responseText);
                            }
                            else {
                              // Can call the error method if something is wrong, should exit after
                              error('oh no');
                            }
                          };
                          request.setRequestHeader('Authorization', 'Bearer ' + token);
                          request.send(formData);
                          // Should expose an abort method so the request can be cancelled
                          return {
                            abort: () => {
                              // This function is entered if the user has tapped the cancel button
                              request.abort();

                              // Let FilePond know the request has been cancelled
                              abort();
                            }
                          };
                        }
                      }
                      }
                      oninit={() => this.handleInit.bind(this)}
                      onupdatefiles={(fileItems) => {
                        // Set current file objects to this.state
                        this.setState({
                          files: fileItems.map(fileItem => fileItem.file)
                        });

                      }}
                      onprocessfiles={() => {

                        if (this.pond.getFiles().filter(x => x.status !== 5).length === 0) {
                          this.getStoreDocuments();
                        }
                        // setTimeout(() => {
                        //   
                        //   this.pond.element = null;
                        //   this.getStoreDocuments();
                        // }, 5000);
                      }}
                    >
                    </FilePond>
                  </ModalBody>

                </Modal>
              </div>
            </ModalBody>
            <ModalFooter>
              {ndaError ? <p className="error nda-error-position">{ndaError}</p> : ''}
              <Button color="primary" onClick={this.documentModalClose.bind(this)}>Next</Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
      </main >
    );
  }
}
