import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import blue_pin from '../../Assets/Img/blue-pin.png';
import './MapView.css'
import { getFormattedInt, PRICEOPTION } from '../../Utils/utils';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps";
import { CONFIG , PROPERTYSTATUS } from '../../Utils/config';
import feature_home from './../../Assets/Img/ventor-list.png';
import moment from 'moment';

// const bounds = new google.maps.LatLngBounds();
// const coordinates = this.props.StoreDetails.map(model => {
//     const latLng = new google.maps.LatLng(model.Latitude, model.Longitude);
//     bounds.extend(latLng);
//     return latLng;
// });

export default class MapView extends Component {

    static defaultProps = {
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.GoogleMapsApiKey + '',
        StoreDetails: [],
        center: {
            lat: 39.8283,
            lng: -98.5795
        },
        zoom: 4
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedMarker: {
                StoreID: '',
                StoreName: '',
                StoreImage: '',
                StorePrice: '',
                AlternatePriceOptionDate:null,
                AlternatePriceOptionName:'',
                AlternatePriceOptionID:null,
                StatusID:0,
            },
            showInfoWindow: false

        }
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMarkerClose = this.onMarkerClose.bind(this);
        console.log("cons",props);
    }


    componentDidMount() {

        this.delayedShowMarker()
    }


    CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            // ref={map => {
            //     const bounds = new window.google.maps.LatLngBounds();
            //     this.props.StoreDetails.map(x => {
            //         bounds.extend(new window.google.maps.LatLng(x.Latitude, x.Longitude));
            //     });
            //     map && map.fitBounds(bounds);
            // }}
            defaultZoom={this.props.zoom}
            defaultCenter={{ lat: parseFloat(this.props.center.lat), lng: parseFloat(this.props.center.lng) }}
            FdefaultOptions={{ zoomControl: true, fullscreenControl: true, streetViewControl: true, disableDefaultUI: true, mapTypeControl: false }}
        >
            {props.children}
        </GoogleMap>
    ));

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 3000)
    }

    handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    onMarkerClick = (store) => {
        var selectedMarker = this.state.selectedMarker;
        selectedMarker.StoreID = store.StoreID;
        selectedMarker.StoreName = store.StoreName;
        selectedMarker.StoreImage = store.StoreImage;
        selectedMarker.StorePrice = store.Price;
        selectedMarker.AlternatePriceOptionDate = store.AlternatePriceOptionDate;
        selectedMarker.AlternatePriceOptionName = store.AlternatePriceOptionName;
        selectedMarker.AlternatePriceOptionID = store.AlternatePriceOptionID;
        selectedMarker.StatusID = store.StatusID;
        this.setState({ showInfoWindow: true, selectedMarker: selectedMarker });
    }

    onMarkerClose() {
        this.setState({ showInfoWindow: false });
    }

    render() {
        const { StoreDetails, googleMapURL } = this.props;
        const { selectedMarker, showInfoWindow } = this.state;
        return (
            <Fragment>
                <this.CMap

                    //onBoundsChanged={props.onBoundsChanged}
                    googleMapURL={googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div className="mobile-mapview" style={{ height: `92.7%`, marginTop: '-24px' }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                >
                    {StoreDetails.map((store, index) => {
                        return (
                            <Marker
                                position={{ lat: parseFloat(store.Latitude), lng: parseFloat(store.Longitude) }}
                                onMouseOver={() => this.onMarkerClick(store)}
                                icon={blue_pin}
                            >
                                {showInfoWindow && selectedMarker.StoreID === store.StoreID ?
                                    <InfoWindow
                                        onCloseClick={() => this.onMarkerClose()}
                                        visible={showInfoWindow}>
                                        <div className="gmap-tooltip map-view">
                                            {selectedMarker.StoreImage != null ?
                                                <img src={'/StoreImages/' + selectedMarker.StoreImage} onError={(e) => { e.target.onerror = null; e.target.src = feature_home }} alt="img" /> :
                                                <img src={feature_home} alt="" />}
                                            <h5><Link to={"/property/" + selectedMarker.StoreID + "/" + encodeURI(selectedMarker.StoreName)} >{selectedMarker.StoreName}</Link></h5>
                                            {selectedMarker.StatusID != PROPERTYSTATUS.Sold ?
                                                                        <>
                                                                        {selectedMarker.AlternatePriceOptionID !== null ? <h6>{selectedMarker.AlternatePriceOptionName} {selectedMarker.AlternatePriceOptionID === PRICEOPTION.Auction && selectedMarker.AlternatePriceOptionDate !== null ? ' : ' + moment(selectedMarker.AlternatePriceOptionDate).format("L") :''}</h6>: <h6>
                                                                            ${getFormattedInt(selectedMarker.StorePrice)}
                                                                               </h6>}</> : null}
                                        </div>
                                    </InfoWindow>
                                    : ''}
                            </Marker>
                        )
                    })}
                </this.CMap>
            </Fragment>
        )
    }
}
