import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from '../../../../../Utils/config';
import { normalizeInput } from '../../../../../Utils/utils';
import './CreateUser.css';

export default class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addModal: false,
            userDetail: [],
            pageCount: 0,
            userList: [],
            userType: 3,
            positionList: [],
            positionId: 0,
            stateList: [],
            stateId: 0,
            position: '',
            showPosition: false,
            statusList: [
                {
                    "id": -1,
                    "name": "All"
                },
                {
                    "id": 2,
                    "name": "Approved"
                },
                {
                    "id": 3,
                    "name": "Rejected"
                }
            ],
            phone: ''
        }
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    handlePhoneChange({ target: { value } }) {
        this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    };

    getLookupValues() {

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'lookup/state')
            .then(res => {
                let stateList = res.data;
                this.setState(state => ({ stateList, stateId: 0 }))

            })
            .catch((err) => {
                console.log(err);
                alert(err.response.data);
            })
        axios.get(CONFIG.API_URL + 'lookup/user/position')
            .then(res => {
                let positionList = res.data;
                this.setState({ positionList })

            })
            .catch((err) => {
                console.log(err);
            })
    }

    toggleClose() {
        this.setState(state => ({ addModal: false }));
        this.props.parentMethod();
    }

    handleChange(e) {

        if (e.target.name === 'positionId') {
            var position = '';
            var showPosition = e.target.value === "5" ? true : false;
            this.setState({ [e.target.name]: e.target.value, position, showPosition });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    stateChange(e) {

        var value = e.target.value;
        this.setState({ stateId: value })
    }

    createUser(event, errors, values) {

        if (errors.length > 0) {
            console.log(errors);
        }
        else {
            const { positionId, stateId, userType } = this.state;

            if ((values.position == null || values.position.trim() === '') && (positionId === "5" || positionId === 5)) {
                alert("Title is required");
                errors.push('position');
            }
            if (errors.length > 0) {
                console.log(errors);
            }
            else {
                this.setState({ isLoading: true });

                const data = {
                    "FirstName": values.FirstName,
                    "LastName": values.LastName,
                    "Email": values.Email,
                    "UserTypeID": this.state.userType,
                    "Address1": values.AddressLine1,
                    "Address2": values.AddressLine2,
                    "City": values.City,
                    "PhoneNumber": values.Phone,
                    "State": values.State,
                    "Zipcode": values.ZipCode,
                    "StateId": parseInt(this.state.stateId),
                    'PositionID': values.positionId,
                    'IsHavingSelfStorages': values.ownselfstorage !== true && values.ownselfstorage !== false ? null : values.ownselfstorage,
                    "IsConfirmed": 1,
                    "IsActive": 1,
                    "StatusID": 2,
                    'CapitalSource': values.CapitalSource,
                    'NumberOfProperties': values.NumberOfProperties,
                    'CompanyName': values.CompanyName,
                    'Position': values.position
                    // 'PositionID': positionId,
                }
                let token = localStorage.getItem('accessKey');
                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }
                axios.post(CONFIG.API_URL + 'account/register/', data)
                    .then(res => {

                        this.myFormRef && this.myFormRef.reset();
                        alert('Seller has been created and notification has been sent.')
                        this.setState({ isLoading: false, addModal: false }, () => {
                            this.props.parentMethod();

                        });
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        console.log(err);
                        alert(err.response.data);

                    })
            }
        }
    }

    componentDidMount() {
        this.setState({ addModal: true });
        this.getLookupValues();
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'User Management - Seller Search';
        const { isLoading, stateList, positionList, positionId, position, showPosition } = this.state;
        return (
            <div>
                {isLoading ? <div className="loader-wrap">< div className="page-loading" ></div ></div > : ''}

                <Modal size="md" isOpen={this.state.addModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                    <ModalHeader toggle={this.toggleClose.bind(this)}>Create Seller
                    </ModalHeader>
                    <ModalBody className="overflow-scroll basic-details">
                        <AvForm onSubmit={this.createUser.bind(this)} ref={c => (this.myFormRef = c)}>
                            <Row>
                                <Col md={6}>

                                    <AvField name="FirstName" label="First Name:*" maxLength="255" type="text" validate={{
                                        required: { value: true, errorMessage: 'First Name is required' }
                                    }} />

                                </Col>
                                <Col md={6}>

                                    <AvField name="LastName" label="Last Name:*" maxLength="255" type="text" validate={{
                                        required: { value: true, errorMessage: 'Last Name is required' }
                                    }} />

                                </Col>
                                <Col md={6}>

                                    <AvField name="Email" label="User Email:*" type="email" maxLength="256" validate={{
                                        required: { value: true, errorMessage: 'Email is required' }
                                    }} />


                                </Col>
                                <Col md={6}>
                                    <AvField name="Phone" label="Phone Number:*" placeholder="xxx-xxx-xxxx" type="text"
                                        validate={{
                                            required: { value: true, errorMessage: 'Phone Number is required' },
                                            pattern: { value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone Number is invalid' }
                                        }}
                                        value={this.state.phone}
                                        onChange={(e) => this.handlePhoneChange(e)}
                                    />
                                </Col>

                                <Col md={6}>

                                    <AvField name="AddressLine1" label="Address Line 1:" maxLength="500" />

                                </Col>
                                <Col md={6}>

                                    <AvField name="AddressLine2" label="Address Line 2:" maxLength="500" />

                                </Col>
                                <Col md={6}>
                                    <AvField name="City" label="City:" maxLength="100" />
                                </Col>
                                <Col md={6}>
                                    <AvField type="select" name="State" value={this.state.stateId}
                                        onChange={(e) => this.stateChange(e)}
                                        label="State:*"
                                        validate={{
                                            required: { value: true, errorMessage: 'State is required' },
                                        }}>
                                        <option value="0">--Select--</option>
                                        {stateList ? stateList.map(n => {
                                            return (
                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                    disabled={n.ID === '' ? true : false}
                                                    value={n.ID}>
                                                    {n.Name}
                                                </option>
                                            );
                                        }) : ''}
                                    </AvField>
                                </Col>
                                <Col md={6}>

                                    <AvField name="ZipCode" label="Zip Code:" maxLength="30" />

                                </Col>
                                <Col md={6}>
                                    <AvField name="CompanyName" label="Company Name:*" placeholder="" type="text" maxLength="1000" validate={{
                                        required: { value: true, errorMessage: 'Company Name is required' }
                                    }} />
                                </Col><Col md={6}>
                                    <AvField type="select" name="positionId" value={positionId}
                                        onChange={(e) => this.handleChange(e)}
                                        label="Title:*"
                                        validate={{
                                            required: { value: true, errorMessage: 'Position is required' },
                                        }}>
                                        <option value="0">--Select--</option>
                                        {positionList ? positionList.map(n => {
                                            return (
                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                    disabled={n.ID === '' ? true : false}
                                                    value={n.ID}>
                                                    {n.Name}
                                                </option>
                                            );
                                        }) : ''}
                                    </AvField>
                                    {
                                        showPosition ?
                                            <AvField name="position" label="Title:*" placeholder="" type="text" maxLength="100" value={position} />
                                            : ''
                                    }
                                </Col>
                                <Col md={6}>
                                    {/* <AvField name="NumberOfProperties" label="No Of Properties:" placeholder="" type="text" maxLength="255"
                                                validate={{ myValidation: validateProperties }}
                                            /> */}

                                    <AvField type="select" name="ownselfstorage"
                                        onChange={(e) => this.handleChange(e)}
                                        label="Do you currently own a self-storage facility?"
                                    // validate={{
                                    //     required: { value: true, errorMessage: 'own self-storage facilities is required' },
                                    // }}
                                    >
                                        <option key={2} value={null}>--Select--</option>
                                        <option key={0}
                                            value={true}>
                                            {"Yes"}
                                        </option>
                                        <option key={1}
                                            value={false}>
                                            {"No"}
                                        </option>
                                    </AvField>
                                </Col>





                                {/* <Col md={6}>
                                            <AvField name="CapitalSource" label="Capital Source:*" placeholder="" type="text" maxLength="2000" validate={{
                                                required: { value: true, errorMessage: 'Capital Source is required' }
                                            }} />
                                        </Col> */}
                            </Row>

                            <Row className="save-right">
                                <Col md={12}>
                                    <Button id="btn" className="next-btn submit-btn btn-design">Save</Button>
                                    <Button className="btn-reset" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
