import React from 'react';
import { Button, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import { CONFIG } from '../../../../../Utils/config';
import CurrencyFormat from 'react-currency-format';
import logo from './../../../../../Assets/Img/SE-LOGO-FINAL.png';
import axios from 'axios';
import './OfferPDF.css';
import moment from 'moment';

export default class OfferPDF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            reportData: [],
            agents: []
        }
    }

    generateReport(buyerStoreId) {
        this.setState({ isOpen: true });
        this.getReportData(buyerStoreId);
    }

    getReportData(buyerStoreId) {
        axios.get(CONFIG.API_URL + 'admin/deal/report/' + buyerStoreId)
            .then(res => {
                this.setState({ reportData: res.data });
            })
            .catch((err) => {
                alert(err.response.data);
            });
    }

    toggleClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const { isOpen, reportData } = this.state;
        return (
            <div>
                <PrintProvider>
                    <NoPrint>
                        <Modal isOpen={isOpen} toggle={this.toggleClose.bind(this)} size="lg" className="print-invoice-popup">
                            <Print single name="foo">
                                <ModalHeader className="bill-invoice-head" toggle={this.toggleClose.bind(this)}>
                                    <img src={logo} alt="Storage Exchange"/>
                                    <p>4770 BASELINE ROAD, SUITE 200, BOULDER, CO 80303</p>
                                </ModalHeader>
                                <ModalBody>
                                    {reportData.Property ?
                                        <Row>
                                            <Col md={4}>Property:</Col> <Col md={8}>{reportData.Property}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.PropertyAddress ?
                                        <Row>
                                            <Col md={4}>Address:</Col> <Col md={8}>{reportData.PropertyAddress}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.OfferPrice ?
                                        <Row>
                                            <Col md={4}>Offer Price: </Col>
                                            <Col md={8}><CurrencyFormat value={reportData.OfferPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                        </Row>
                                        : ''}
                                    {reportData.LOIName ?
                                        <Row>
                                            <Col md={4}>Name: </Col> <Col md={8}>{reportData.LOIName}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.LOICompanyName ?
                                        <Row>
                                            <Col md={4}>Company: </Col> <Col md={8}>{reportData.LOICompanyName}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.DiligencePeriod ?
                                        <Row>
                                            <Col md={4}>Diligence Period: </Col> <Col md={8}>{reportData.DiligencePeriod}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.EarnestMoneyDeposit ?
                                        <Row>
                                            <Col md={4}>Earnest Money Deposit: </Col> <Col md={8}>{reportData.EarnestMoneyDeposit}%</Col>
                                        </Row>
                                        : ''}
                                    {reportData.SourceOfFinancing ?
                                        <Row>
                                            <Col md={4}>Source of Financing: </Col> <Col md={8}>{reportData.SourceOfFinancing}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.DateSubmitted ?
                                        <Row>
                                            <Col md={4}>Date Submitted: </Col> <Col md={8}>{moment(reportData.DateSubmitted).format("L")}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.SellerName ?
                                        <Row>
                                            <Col md={4}>Seller Name: </Col> <Col md={8}>{reportData.SellerName}</Col>
                                        </Row>
                                        : ''}
                                    {reportData.Sellercontact ?
                                        <Row>
                                            <Col md={4}>Seller Contact: </Col> <Col md={8}>{reportData.Sellercontact}<br /><a href={"mailto:" + reportData.SellerContactEmail}>{reportData.SellerContactEmail}</a></Col>
                                        </Row>
                                        : ''}

                                    <h4>Contact Storage Exchange:</h4>
                                    {reportData.ListingAgents ? reportData.ListingAgents.map((agent) =>
                                        <React.Fragment>
                                            <Row className="contact-agent-bottom">
                                                <Col md={4}>{agent.AgentName}, Agent</Col>
                                                <Col md={8}>{agent.AgentLocation}<br />{agent.AgentPhoneNumber}<br />
                                                    <a href={"mailto:" + agent.AgentEmail}>{agent.AgentEmail}</a></Col>
                                            </Row>
                                        </React.Fragment>
                                    ) : ''}
                                </ModalBody>
                            </Print>
                            <ModalFooter>
                                <Button color="primary" className="mb-0 mt-0" onClick={() => window.print()}>Save Report</Button>
                            </ModalFooter>
                        </Modal>
                    </NoPrint>
                </PrintProvider>
            </div>
        );
    }
}