import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG, CONTACT, USERTYPES, PROPERTYSTATUS, DEALSTATUS } from '../../Utils/config';
import { normalizeInput, PRICEOPTION } from '../../Utils/utils';
import axios from 'axios';
import './PropertyView.css';
import { Input } from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CurrencyFormat from 'react-currency-format';
import storage_src from './../../Assets/Img/storage-icon.png';
import address_icon from './../../Assets/Img/address.png';
import logo_name from './../../Assets/Img/logo-se1.png';
import phone_icon from './../../Assets/Img/phone.png';
import pdf from './../../Assets/Img/pdf-downlaod.png';
import mail_add from './../../Assets/Img/mail-add.png';
import noimg from './../../Assets/Img/noimg.png';
import feature_home from './../../Assets/Img/noimglist.png';
import market from './../../Assets/Img/market.png';
import { Carousel } from 'react-responsive-carousel';
import { getToken, getUserType, getbuyerStoreNDAIDs } from '../../Utils/localStorage';
import { getFormattedInt } from '../../Utils/utils';

//function Contact() {
const Contact = (props) => {
  const { data, list } = props;

  const Storelist = () => {
    var newArr = [];
    for (let i = 0; i < list.length; i++) {
      let DataList = data && data.filter(x => x.AgentLocationId === list[i].Id);
      if (DataList && DataList.length > 0) {
        const ListOne = list.filter(x => x.Id === list[i].Id)[0];
        newArr.push(<>
          {ListOne ? <>
            {/* <h3>{ListOne && ListOne.Name}</h3> */}
            <div className="item">
              <img src={address_icon} className="" alt="" />
              <div class="content"> {ListOne && ListOne.Address}<br />{ListOne && ListOne.City}, {ListOne && ListOne.StateName} {ListOne && ListOne.Zipcode}</div>
            </div>
          </>
            : ''}
          {data && data.filter(x => x.AgentLocationId === list[i].Id).map((task) =>
            <span className="address-line" key={task.Id}>
              <p>
                <span className="companyname-heading">
                  <b>{task.BrokerFirstName + ' ' + task.BrokerLastName}</b>
                  {task.BrokerPosition && task.BrokerPosition != '' ? ', ' + task.BrokerPosition : ''}
                </span>
              </p>
              <p><img src={phone_icon} className="icon-size" alt="" /> : {task.BrokerPhoneNumber}</p>
              <p><img src={mail_add} className="icon-size" alt="" /> : {task.BrokerEmail}</p>
            </span>)}
          <br />
        </>)
      }
    }
    return newArr
  }
  return (
    <React.Fragment>
      <Row>
        <div className="width-70">
          <Storelist />
        </div>
        <div className="width-property30">
          <img src={logo_name} className="" alt="" />
        </div>
      </Row>
    </React.Fragment >
  );
}

const LOITerms = () => {
  return (
    <i className="submit-offer-terms">This offer and any acceptance by Seller will not be binding and will not constitute a contract, nor does it create any legal or other obligation, including duty to bargain in good faith.  It is presented merely as an outline of possible purchase terms for discussion purposes only. No party will have any liability until such time as the Purchase Agreement has been fully executed and delivered to both parties.</i>
  );
}

const validatePrice = (value, ctx) => {
  if (ctx.Price.toString().trim() == "") {
    return "Price is required";
  }
  // else if (isNaN(ctx.Price)) {
  //   return "Price should be a number";
  // } 
  else if (ctx.Price <= 0) {
    return "Price should be greater than zero";
  }
  return true;
}

const validateEarnestMoneyDeposit = (value, ctx) => {
  if (ctx.EarnestMoneyDeposit.toString().trim() == "") {
    return "Earnest Money Deposit is required";
  }
  // else if (isNaN(ctx.EarnestMoneyDeposit)) {
  //   return "Earnest Money Deposit should be a number";
  // } 
  else if (ctx.EarnestMoneyDeposit <= 0) {
    return "Earnest Money Deposit should be greater than zero";
  }
  return true;
}

export default class PropertyView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      NDAModal: false,
      propertyId: parseInt(this.props.match.params.propertyId),
      propertyData: [],
      deals: [],
      listingAgents: [],
      imageNames: [],
      documentData: [],
      pageCount: 0,
      isHomePage: true,
      diligencePeriods: [],
      SEPrices: [],
      diligencePeriodsID: 0,
      SEPriceID: '0',
      isNeedNDASign: false,
      phone: '',
      offerPrice: '',
      earnestMoney: '',
      isconfirmOffer: false,
      offerData: {},
      isNDAForRejected: false,
      stateList: [],
      stateId: 0,
      offlineStateId: 0,
      offlineState: '',
      isOffline: false,
      locationlist: []
    };

    this.toggleNDAModal = this.toggleNDAModal.bind(this);
    this.addDashBoard = this.addDashBoard.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleEarnestMoneyChange = this.handleEarnestMoneyChange.bind(this);
  }


  stateChange(e) {

    var value = e.target.value;
    this.setState({ stateId: value })
  }

  offlineStateChange(e) {
    var value = e.target.value;
    var offlineState = '';
    if (e.target.selectedOptions.length > 0) {
      offlineState = e.target.selectedOptions[0].label;
    }
    this.setState({ offlineStateId: value, offlineState });
  }

  handlePhoneChange({ target: { value } }) {
    this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
  };

  handlePriceChange({ target: { value } }) {
    this.setState(prevState => ({ offerPrice: getFormattedInt(value.replace(/,/g, ''), prevState.offerPrice) }));
  };

  handleEarnestMoneyChange({ target: { value } }) {
    this.setState(prevState => ({ earnestMoney: getFormattedInt(value.replace(/,/g, ''), prevState.earnestMoney) }));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    var propertyId = parseInt(this.props.match.params.propertyId);

    var token = getToken();
    var userType = getUserType();

    var isHomePage = true;

    if (this.props.location.pathname.indexOf("buyer/") > 0) {
      isHomePage = false;
    }

    this.setState({ token, userType, propertyId, isHomePage }, () => {
      this.getProperty();
    });

  }

  componentWillReceiveProps(nextprops) {

    let propertyId = parseInt(nextprops.match.params.propertyId)

    var token = getToken();
    var userType = getUserType();
    var isHomePage = true;

    if (this.props.location.pathname.indexOf("buyer/") > 0) {
      isHomePage = false;
    }
    this.setState({ token, userType, propertyId, isHomePage }, () => {
      this.getProperty();
    });
  }

  getPropertyPriceLookupValues() {

    axios.get(CONFIG.API_URL + 'lookup/propertypricelookup').then(response => {
      if (response.status === 200) {
        let data = response.data;
        this.setState({ SEPrices: data.SEPrices, diligencePeriods: data.diligencePeriods, isLoading: false });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  getStateLookup() {
    axios.get(CONFIG.API_URL + 'lookup/state')
      .then(res => {
        let stateList = res.data;
        this.setState(state => ({ addModal: !state.addModal, stateList }))

      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
  }
  getLocationLookup() {
    axios.get(CONFIG.API_URL + 'home/agent/locations')
      .then(res => {
        let locationlist = res.data;
        this.setState({ locationlist })

      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
  }
  getProperty() {
    this.getPropertyPriceLookupValues();
    this.getLocationLookup();
    const { token, propertyId, userType } = this.state;

    this.setState({ isLoading: true });
    var url = CONFIG.API_URL + 'home/store/profile/' + propertyId;

    if (token) {//UnAuthenticated Property view

      var buyerStoreNDAIds = getbuyerStoreNDAIDs();//get Buyer storeIds from localStorage.
      var buyerStoreNDAId = 0;

      if (buyerStoreNDAIds) {
        buyerStoreNDAIds.split(",").map(element => {
          let elementSplit = element.split("##");
          if (elementSplit[0] == propertyId) {
            buyerStoreNDAId = elementSplit[1];
          }
        });

      }
      if (buyerStoreNDAId != 0) {
        url = CONFIG.API_URL + 'buyer/store/profile/' + propertyId + '/' + buyerStoreNDAId
      }
    }

    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

    axios.get(url)
      .then(res => {
        if (res.status === 200) {

          let propertyData = res.data.Store;

          if (propertyData.EarnestMoneyDeposit) {
            var earnestMoneyDeposit = propertyData.EarnestMoneyDeposit.split('.');
            var formattedEarnestMoneyDeposit = getFormattedInt(earnestMoneyDeposit[0].replace(/,/g, ''));
            formattedEarnestMoneyDeposit = earnestMoneyDeposit.length > 1 ? formattedEarnestMoneyDeposit + "." + earnestMoneyDeposit[1] : formattedEarnestMoneyDeposit;
            propertyData.EarnestMoneyDeposit = formattedEarnestMoneyDeposit;
          }

          if (propertyData.Acerage) {
            var acreage = propertyData.Acerage.toString();
            acreage = acreage.split('.');
            var formattedacreage = getFormattedInt(acreage[0].replace(/,/g, ''));
            formattedacreage = acreage.length > 1 ? formattedacreage + "." + acreage[1] : formattedacreage;
            propertyData.Acerage = formattedacreage;
          }

          if (propertyData !== null && (propertyData.BuyerStoreNDAID !== 0 || localStorage.getItem('role') === 'Admin') && propertyData.NDASignedDate === null) {
            this.getStateLookup();
            if (localStorage.getItem('role') !== 'Admin') {
              this.setState({ isNeedNDASign: true, NDAModal: true });
            }
          }

          this.setState({
            propertyData: propertyData, documentData: res.data.Documents, isLoading: false
            , imageNames: res.data.ListedImages, listingAgents: res.data.ListingAgents, deals: res.data.Deals
          });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  submitOffer(event, errors, values) {
    if (errors.length === 0) {
      const { propertyData, offlineStateId, offlineState } = this.state;

      var earnestMoneyDeposit = values.EarnestMoneyDeposit.split('.');
      var formattedEarnestMoneyDeposit = getFormattedInt(earnestMoneyDeposit[0].replace(/,/g, ''));
      formattedEarnestMoneyDeposit = earnestMoneyDeposit.length > 1 ? formattedEarnestMoneyDeposit + "." + earnestMoneyDeposit[1] : formattedEarnestMoneyDeposit;

      const offerData = {
        Store: {
          "StoreName": propertyData.StoreName,
          "Price": values.Price.replace(/,/g, ''),
          "StoreID": propertyData.StoreID,
          "StatusID": propertyData.StatusID
        },
        BuyerStore: {
          "LOIName": values.Name,
          "LOICompanyName": values.Company,
          "SourceOfFinancing": values.SourceOfFinancing,
          "EarnestMoneyDeposit": formattedEarnestMoneyDeposit,
          "DiligencePeriodID": values.diligencePeriodsID,
          "OfflineBuyerFirstName": this.state.userType == USERTYPES.Admin ? values.OfflineBuyerFirstName : null,
          "OfflineBuyerLastName": this.state.userType == USERTYPES.Admin ? values.OfflineBuyerLastName : null,
          "OfflineBuyerEmail": this.state.userType == USERTYPES.Admin ? values.OfflineBuyerEmail : null,
          "OfflineBuyerCity": this.state.userType == USERTYPES.Admin ? values.OfflineBuyerCity : null,
          "OfflineBuyerStateID": this.state.userType == USERTYPES.Admin ? offlineStateId : null,
          "OfflineBuyerState": this.state.userType == USERTYPES.Admin ? offlineState : ''
        },
        // "BuyerStoreID": propertyData.BuyerStoreID,
        "IsOffline": this.state.userType == USERTYPES.Admin ? true : false
      }
      this.setState({ offerData, isconfirmOffer: true });
    }
  }

  confirmSubmitOffer() {
    this.setState({ isLoading: true, isconfirmOffer: false }, () => {
      const { token, offerData, isHomePage } = this.state;
      offerData.BuyerStore.EarnestMoneyDeposit = offerData.BuyerStore.EarnestMoneyDeposit.replace(/,/g, '');

      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      axios.post(CONFIG.API_URL + 'buyer/store/deal/', offerData)
        .then(res => {
          if (!isHomePage) {
            document.getElementById("r1").click();
          }
          this.setState({ isLoading: false }, () => {
            alert("Offer Terms have been successfully submitted. Offer details are now available under submitted offers on your buyer account. A Storage Exchange Agent will contact you shortly with next steps.");
          });
          if (this.state.userType == USERTYPES.Admin) {
            this.props.history.push({
              pathname: "/admin/dealtracker"
            });
          }
          else {
            this.props.history.push({
              pathname: "/buyer/dashboard"
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.response != null && err.response.status === 400) {
            const error = err.response.data;
            this.setState({ error });
          }
          else {
            const error = "Something went wrong.";
            this.setState({ error });
          }
        })
    });
  }

  toggleNDAModal() {
    const { propertyData } = this.state;
    // if (propertyData.DealStatusID == DEALSTATUS.Rejected) {
    //   this.setState({ isNDAForRejected: true }, () => { this.addDashBoard() });
    // } else {
    this.getStateLookup();
    this.setState(state => ({ NDAModal: !state.NDAModal }));
    // }
  }

  toggleClose() {
    // if (this.state.isNeedNDASign) {
    //   this.setState({ NDAModal: true });
    // } else {
    this.setState({ NDAModal: false });
    //}
  }

  toggleCloseConfirmOffer = () => this.setState(state => ({ isconfirmOffer: false }));

  addDashBoard(event, errors, values) {
    const { isNDAForRejected, token, propertyData, isNeedNDASign, stateId } = this.state;
    var isvalid = false;

    if (isNDAForRejected) {
      isvalid = true;
    } else {
      if (errors.length === 0) {
        isvalid = true;
      }
    }

    if (isvalid) {
      const data = {
        'ID': propertyData.BuyerStoreNDAID,
        // 'DealStatusID': propertyData.DealStatusID,
        'StoreID': propertyData.StoreID,
        'DealName': propertyData.StoreName,
        'NDAName': values ? values.Name : propertyData.NDAName,
        'NDAAddress': values ? values.Address : propertyData.NDAAddress,
        'NDACity': values ? values.NDACity : propertyData.NDACity,
        'NDAStateID': values ? stateId : propertyData.NDAStateID,
        'NDAZipCode': values ? values.NDAZipCode : propertyData.NDAZipCode,
        'NDAEntity': values ? values.Entity : propertyData.NDAEntity,
        'NDALastName': values ? values.LastName : propertyData.NDALastName,
        'NDAPhone': values ? values.Phone : propertyData.NDAPhone,
        'NDAEmail': values ? values.Email : propertyData.NDAEmail,
        'NDAAddress2': values ? values.Address2 : propertyData.NDAAddress2
      }

      this.setState({ isLoading: true });
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

      axios.post(CONFIG.API_URL + 'buyer/property/add/dashboard', data)
        .then(res => {
          // if (!isHomePage) {
          //   document.getElementById("r1").click();
          // }

          let role = res.data.defaultRole;
          let buyerDefault = role[0].IsDefault === true ? true : false;
          let sellerDefault = role[1].IsDefault === true ? true : false;
          localStorage.removeItem('buyerDefault');
          localStorage.removeItem('sellerDefault');
          localStorage.removeItem('role');
          localStorage.setItem('role', 'Buyer');
          localStorage.setItem('buyerDefault', buyerDefault);
          localStorage.setItem('sellerDefault', sellerDefault);
          if (isNeedNDASign === true) {
            this.setState({ isLoading: false, isNeedNDASign: false, NDAModal: false });
            this.getProperty();
          }
          else {
            // localStorage.removeItem(sellerDefault)
            this.props.history.push({
              pathname: "/buyer/dashboard/"
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.response != null && err.response.status === 400) {
            const error = err.response.data;
            this.setState({ error });
          }
          else {
            const error = "Something went wrong.";
            this.setState({ error });
          }
        });
    }
  }

  resetForm(event) {
    this.offerForm.reset();
  }

  signUp() {
    let propertyId = this.state.propertyId;
    let storeName = this.state.propertyData.StoreName;

    this.props.history.push({
      pathname: "/buyer-registration/" + propertyId + "/" + encodeURI(storeName)
    });
  }

  onSelectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onMarketReportClick(accessKey, storeID) {
    const { token } = this.state;
    let data = {
      'StoreID': storeID,
      'AccessKey': accessKey
    }

    this.setState({ isLoading: true });
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

    axios.post(CONFIG.API_URL + 'buyer/store/accesskey', data)
      .then(res => {
        this.setState({ isLoading: false });
        var url = CONFIG.StorTrackMVPURL + "access/" + res.data;
        window.open(url, '_blank');
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const error = err.response.data;
          this.setState({ error });
        }
        else {
          const error = "Something went wrong.";
          this.setState({ error });
        }
      });
  }

  onAdminMarketReportClick(storeID) {
    const { token } = this.state;

    const data = {
      'StoreID': storeID
    }

    this.setState({ isLoading: true });
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

    axios.post(CONFIG.API_URL + 'admin/property/add/dashboard', data)
      .then(res => {
        this.setState({ isLoading: false });
        var accessKey = res.data.token;
        this.onMarketReportClick(accessKey, storeID);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const error = err.response.data;
          this.setState({ error });
        }
        else {
          const error = "Something went wrong.";
          this.setState({ error });
        }
      });
  }

  render() {
    document.title = CONFIG.PAGE_TITLE + 'Property Profile View';
    const { isLoading, propertyData, listingAgents, imageNames, documentData, deals, pageCount, isNeedNDASign, isOffline, offlineStateId,
      token, isHomePage, userType, diligencePeriods, diligencePeriodsID, isconfirmOffer, offerData, stateId, stateList, locationlist } = this.state;
    return (
      <div >
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <div class={!isHomePage ? "property-banner-condition" : "property-banner"} >
          <Container-fluid>
            <div className="item">
              {/* <img src={storage_src} className="" alt="" /> */}
              <div class="content">  <h2>PROPERTY DETAILS<br /> <span> <Link to="/">Home </Link>/ Property Details</span></h2></div>
            </div>
          </Container-fluid>
        </div>
        <div class={!isHomePage ? "property-detl-condition dashboard-layout-height" : "property-detl"} >
          <Row>
            <Col>
              {/* Property Address and Image start */}
              <div className="heading">
                <Row>
                  <div className="width-90-property">
                    <h3 className="blue-with-text">{propertyData.StoreName}</h3>
                    <h5>{propertyData.Address1 + ((propertyData.Address2 != null && propertyData.Address2 != '') ? (', ' + propertyData.Address2) : '')}, {propertyData.City}, {propertyData.StateName} {propertyData.ZipCode}</h5>
                    {/* {(localStorage.getItem('role') == 'Admin' || (propertyData.DealStatusID != DEALSTATUS.Rejected && propertyData.NDASignedDate)) && propertyData.AccessKey ? <Link className="marketreport-link" onClick={this.onMarketReportClick.bind(this, propertyData.AccessKey, propertyData.StoreID)}><img src={market} alt="marketreport" Title="Interactive Market Analysis" className="marketicon" /> Interactive Market Analysis</Link> : ''} */}
                  </div>
                </Row>
              </div>
              <Row>
                <Col md={6} className="property_img">
                  <div className="size">
                    {imageNames.length === 0 && !propertyData.VideoCode ? <img src={noimg} class={!isHomePage ? "noimg-condition" : "noimg-cls"} alt="" />
                      : imageNames.length === 0 ?
                        propertyData.VideoCode ?
                          <Carousel showThumbs={false} autoPlay={true} interval="3000" className="image-carousel-size" >
                            <div class="listing-img-resize" dangerouslySetInnerHTML={{ __html: propertyData.VideoCode }}>
                            </div>
                          </Carousel>
                          : ''
                        :
                        propertyData.VideoCode ?
                          <Carousel showThumbs={false} autoPlay={true} interval="3000" className="image-carousel-size">
                            {imageNames.length > 0 ? imageNames.map((image) =>
                              <div className="listing-img-resize">
                                <img src={'/StoreImages/' + image.FileName} onError={(e) => { e.target.onerror = null; e.target.src = feature_home }} />
                              </div>
                            ) : ''
                            }
                            {propertyData.VideoCode ?
                              <div class="listing-img-resize" dangerouslySetInnerHTML={{ __html: propertyData.VideoCode }}>
                              </div>
                              : ''}
                          </Carousel> :
                          <Carousel>
                            {imageNames.length > 0 ? imageNames.map((image) =>
                              <div className="listing-img-resize">
                                <img src={'/StoreImages/' + image.FileName} onError={(e) => { e.target.onerror = null; e.target.src = feature_home }} />
                              </div>
                            ) : ''
                            }
                          </Carousel>
                    }
                  </div>
                </Col>
                {/* Property Address and Image end */}

                {/* Property Description start */}
                <Col md={6} className="property_des" >
                  <h3 className="blue-with-text">Property <span>Description</span></h3>
                  <div className="property-text" dangerouslySetInnerHTML={{ __html: propertyData.Description ? propertyData.Description : 'N/A' }}></div>
                  {/* <pre>{propertyData.Description ? propertyData.Description : 'N/A'}</pre> */}

                  {
                    ((propertyData.NDASignedDate || localStorage.getItem('role') === 'Admin') && propertyData.AccessKey && propertyData.AccessKey.length !== 0) ?
                      <React.Fragment>
                        <div className="text-right">
                          <Link className="marketreport-link" onClick={this.onMarketReportClick.bind(this, propertyData.AccessKey, propertyData.StoreID)}><img src={market} alt="marketreport" Title="Interactive Market Analysis" className="marketicon" /> Interactive Market Analysis</Link>
                        </div>
                        <div class="but-clear"></div>
                      </React.Fragment>
                      : (localStorage.getItem('role') === 'Admin') ?
                        <React.Fragment>
                          <div className="text-right">
                            <Link className="marketreport-link" onClick={this.onAdminMarketReportClick.bind(this, propertyData.StoreID)}><img src={market} alt="marketreport" Title="Interactive Market Analysis" className="marketicon" /> Interactive Market Analysis</Link>
                          </div>
                          <div class="but-clear"></div>
                        </React.Fragment>
                        : ''
                  }

                  {((propertyData.StatusID == PROPERTYSTATUS.Available || propertyData.StatusID == PROPERTYSTATUS.DueDiligence) &&
                    !propertyData.NDASignedDate) ? //Property Available so allow user to (add to dashboard) or register.

                    <div className="text-right">
                      {(!token) ? //not authenticated 
                        <React.Fragment>
                          {/* <p className="para-msg-property">If you are interested in the property or willing to know more please click the button below and register</p> */}
                          <p className="information-text"><Link to="/buyer-registration">Create an account</Link> to access financial reports, interactive market analysis, and additional investment details.</p>
                          <Button className="request-information-btn margin-0" onClick={this.signUp.bind(this)} >Request Information</Button>
                        </React.Fragment>
                        : //authenticated
                        <React.Fragment>
                          {propertyData.SellerID == parseInt(localStorage.getItem('id')) ? '' :

                            // userType == USERTYPES.Buyer ?
                            //   <React.Fragment>
                            //     <p className="para-msg-property">If you are interested in the property or willing to know more please click the button below to add to your dashboard </p>
                            //     <Button onClick={this.toggleNDAModal} >Access Investment Materials</Button>
                            //   </React.Fragment> : ''
                            localStorage.getItem('role') == 'Admin' ?
                              <React.Fragment>
                                {/* <p className="para-msg-property">If you are interested in the property or willing to know more please click the button below to add to your dashboard </p> */}
                                <div class="info-btn-cont">
                                  <p className="information-text">Click here to add an offer for Offline Buyer </p>
                                  <Button className="request-information-btn margin-0" onClick={() => { this.setState({ isOffline: true }) }} >Add Offline Offer</Button>
                                </div>
                              </React.Fragment>
                              :
                              <React.Fragment>
                                {/* <p className="para-msg-property">If you are interested in the property or willing to know more please click the button below to add to your dashboard </p> */}
                                <p className="information-text">Click below to access financial reports, interactive market analysis, and additional investment details.</p>
                                <Button className="request-information-btn margin-0" onClick={this.toggleNDAModal}>Access Investment Materials</Button>
                              </React.Fragment>
                          }
                        </React.Fragment>
                      }
                    </div>
                    : ''}
                  {propertyData.StatusID == PROPERTYSTATUS.Sold ?
                    <div className="text-right">
                      <div class="sold-type large-size-tags closed-btn">Closed</div>
                    </div>
                    : ''}
                  {(token && userType == USERTYPES.Buyer && propertyData.StatusID == PROPERTYSTATUS.DueDiligence && propertyData.IsInterested && (propertyData.DealStatusID == DEALSTATUS.OnHold)) ?//Property under contract Deal submitted but on hold.
                    <div className="text-right">
                      <div class="estate-type large-size-tags">Under Contract</div>
                    </div>
                    : ''}
                </Col>
                {/* Property Description end */}
              </Row>
              <Row>
                {/* Property Detail start */}
                <Col md={6} className={!isHomePage ? "property-dl-condition" : "property-dl padding-left-remove"}>
                  <h3 className="border-details blue-with-text">Details</h3>
                  <Table bordered>
                    <tbody>
                      {propertyData.StatusID !== PROPERTYSTATUS.Sold ?
                        <tr>
                          <td><b>Ask Price</b></td>
                          {propertyData.AlternatePriceOptionID !== null ? <td className="featured-price">{propertyData.AlternatePriceOptionName} {propertyData.AlternatePriceOptionID === PRICEOPTION.Auction && propertyData.AlternatePriceOptionDate !== null ? ' : ' + moment(propertyData.AlternatePriceOptionDate).format("L") : ''}</td> : <td className="featured-price">
                            ${getFormattedInt(propertyData.Price)}
                          </td>}
                        </tr>
                        : ''}
                      {/* <tr>
                        <td><b>Property Type</b></td>
                        <td>{propertyData.StoreType ? propertyData.StoreType : 'N/A'}</td>
                        </tr> */}
                      {propertyData.OffersDueDate !== null ?
                        <tr>
                          <td><b>Offers Due</b></td>
                          <td>{moment(propertyData.OffersDueDate).format("L")}</td>
                        </tr> : ''}
                      <tr>
                        <td><b>Status</b></td>
                        <td>{propertyData.StoreStatus ? propertyData.StoreStatus : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td><b>Rentable Square Feet</b></td>
                        <td>{propertyData.RentableSQFT ? getFormattedInt(propertyData.RentableSQFT) + ' sq. ft' : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td><b>Parcel Size</b></td>
                        <td>{propertyData.Acerage ? propertyData.Acerage + ' Acre(s)' : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td><b>Room for Expansion</b></td>
                        <td>{propertyData.IsRoomForExpansion == true ? 'Yes' : 'No'}</td>
                      </tr>

                      {propertyData.StatusID !== PROPERTYSTATUS.Sold ?
                        <React.Fragment>
                          <tr>
                            <td><b>Days Listed</b></td>
                            <td>{propertyData.DaysListed ? propertyData.DaysListed <= 0 ? 0 : propertyData.DaysListed : 'N/A'} {propertyData.DaysListedType ? propertyData.DaysListedType : ''}</td>
                          </tr>

                          <tr>
                            <td><b>Updated</b></td>
                            <td>{propertyData.ModifiedDate ? moment(propertyData.ModifiedDate).format("L") : moment(propertyData.CreatedDate).format("L")}</td>
                          </tr>
                        </React.Fragment>
                        : ''}
                    </tbody>
                  </Table>
                  {(token && (propertyData.NDASignedDate || localStorage.getItem('role') === 'Admin')) ?//Property under contract Deal submitted.
                    <Col md={12} className={!isHomePage ? "property-dl-condition remove-padding-document" : "property-dl remove-padding-document"} >
                      <h3 className="left-bor blue-with-text">Property Documents</h3>
                      <Table className="table custom-table table-bordered store-count-popup ">
                        <TableHead>
                          <TableRow>
                            <TableCell>Document Name</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(documentData.length > 0 && isNeedNDASign == false) ? documentData.slice(pageCount * 10, pageCount * 10 + 10).map((document) =>
                            <TableRow key={document.DocumentTypeID}>
                              <TableCell>{document.DocumentType}</TableCell>
                              <TableCell>
                                <a target="_blank" rel="noopener noreferrer" href={'/StoreDocuments/' + document.FileName} download={document.ActualFileName != null ? document.ActualFileName : document.FileName} className="download-link">Download</a>
                              </TableCell>
                            </TableRow>
                          ) :
                            isNeedNDASign ?
                              <TableRow>
                                <td colspan="2" className="no-records-found">
                                  You need to sign the NDA in order to view the documents.
                                  <Button className="click-here-link" onClick={this.toggleNDAModal} >Click here</Button> to sign the NDA.
                                </td>
                              </TableRow>
                              :
                              <TableRow>
                                <td colspan="2" className="no-records-found">No documents available.</td>
                              </TableRow>
                          }
                        </TableBody>
                      </Table>
                      {/* <div className={!isHomePage ? "property-contact-condition" : "property-contact"}>
                            <div className={!isHomePage ? "contact-details-condition" : "contact-details"}>
                              <h3>Contact:</h3>
                              <div className={!isHomePage ? "borker-contact-condition" : "borker-contact"}>
                                <Contact data={propertyData} />
                              </div>
                            </div>
                          </div> */}
                    </Col>

                    : ''}
                </Col>
                {/* Property Detail end */}

                {/* Property Process start */}
                {propertyData.StatusID == PROPERTYSTATUS.Sold ?
                  <div className="col-md-6 add-margin">

                    {/* <div className="cls-background-porperty">
                      <div class="sold-type large-size-tags">Closed</div>
                    </div> */}
                    <h3 className="font-size20">Contact</h3>
                    <div className=" property-contact no-margin">
                      <div className="contact-details ">
                        <div className="borker-contact">
                          <Contact data={listingAgents} list={locationlist} />
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''
                }
                {(!token || (token && userType != USERTYPES.Admin && !isOffline)) && ((propertyData.StatusID == PROPERTYSTATUS.Available || propertyData.StatusID == PROPERTYSTATUS.DueDiligence) &&
                  !propertyData.NDASignedDate) ? //Property Available so allow user to (add to dashboard) or register.
                  <div className="col-md-6 property-contact" >
                    <h3 className="font-size20-c blue-with-text">Contact</h3>
                    <div className="contact-details contact-details-height">
                      <div className="borker-contact contact-seller">
                        <Contact data={listingAgents} list={locationlist} />
                      </div>
                    </div>
                  </div>
                  ://property available user interested
                  <React.Fragment>
                    {/* //property interested check deal added if yes show deal price else get deal price. */}
                    {((token && userType == USERTYPES.Admin && isOffline) || (token && userType == USERTYPES.Buyer && (propertyData.StatusID == PROPERTYSTATUS.Available || propertyData.StatusID == PROPERTYSTATUS.DueDiligence) && propertyData.NDASignedDate)) ?
                      <React.Fragment>
                        <Col md={6} className={!isHomePage ? "property-contact-condition page-top-margin-condition" : "property-contact page-top-margin"} >

                          <React.Fragment>
                            <h3 className="blue-with-text">Submit Offer Terms:</h3>
                            <LOITerms />
                            <div className={!isHomePage ? "contact-details-condition" : "contact-details property-offer-form"}>
                              <AvForm className="property-form-offer valid-fields-form" onSubmit={this.submitOffer.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.offerForm = c)}>
                                <Row>
                                  <Col md={6}>
                                    <AvField name="Name" label="Name:" type="text" maxLength="200" validate={{
                                      required: { value: true, errorMessage: 'Name is required' }
                                    }} />
                                  </Col>

                                  <Col md={6}>
                                    <AvField name="Company" label={isOffline ? "Buyer Company:" : "Company:"} maxLength="300"
                                      validate={{
                                        required: { value: true, errorMessage: 'Company is required' }
                                      }} />
                                  </Col>

                                  <Col md={6}>
                                    <AvField name="Price" label="Offer:" maxLength="13"
                                      validate={{
                                        required: { value: true, errorMessage: 'Offer is required' },
                                        pattern: { value: /^[0-9,]*$/, errorMessage: 'Offer is invalid' }
                                      }}
                                      value={this.state.offerPrice}
                                      onChange={(e) => this.handlePriceChange(e)}
                                    />
                                  </Col>

                                  <Col md={6}>
                                    <AvField type="select" name="diligencePeriodsID" value={diligencePeriodsID}
                                      onChange={(e) => this.onSelectChange(e)}
                                      label="Diligence Period:"
                                      validate={{
                                        required: { value: true, errorMessage: 'Diligence Period is required' }
                                      }}
                                    >
                                      <option value="0">--Select--</option>
                                      {diligencePeriods ? diligencePeriods.map(n => {
                                        return (
                                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                            disabled={n.ID === '' ? true : false}
                                            value={n.ID}>
                                            {n.Name}
                                          </option>
                                        );
                                      }) : ''}
                                    </AvField>
                                  </Col>

                                  <Col md={6}>
                                    <AvField name="SourceOfFinancing" label=" Source of Financing:" maxLength="300"
                                      validate={{
                                        required: { value: true, errorMessage: ' Source of Financing is required' }
                                      }} />
                                  </Col>

                                  <Col md={6}>
                                    <AvField name="EarnestMoneyDeposit" label="Earnest Money Deposit(%):" maxLength="13"
                                      validate={{
                                        required: { value: true, errorMessage: 'Earnest Money Deposit is required' },
                                        pattern: { value: /^[0-9,.]*$/, errorMessage: 'Earnest Money Deposit is invalid' }
                                      }}
                                      value={this.state.earnestMoney}
                                      onChange={(e) => this.handleEarnestMoneyChange(e)}
                                    />
                                  </Col>
                                  {isOffline ?
                                    <>
                                      <Col md={6}>
                                        <AvField name="OfflineBuyerFirstName" label=" Buyer First Name:" maxLength="100"
                                          validate={{
                                            required: { value: true, errorMessage: ' Buyer First name is required' }
                                          }} />
                                      </Col>

                                      <Col md={6}>
                                        <AvField name="OfflineBuyerLastName" label=" Buyer Last Name:" maxLength="100" />
                                      </Col>

                                      <Col md={6}>
                                        <AvField name="OfflineBuyerEmail" label=" Buyer Email:" maxLength="100"
                                          validate={{
                                            required: { value: true, errorMessage: ' Buyer Email is required' }
                                          }} />
                                      </Col>
                                      <Col md={6}>
                                        <AvField name="OfflineBuyerCity" label=" Buyer City:" maxLength="100"
                                          validate={{
                                            required: { value: true, errorMessage: ' Buyer City is required' }
                                          }} />
                                      </Col>
                                      <Col md={6}>
                                        <AvField type="select" name="OfflineBuyerState" value={offlineStateId}
                                          onChange={(e) => this.offlineStateChange(e)}
                                          label="State:*"
                                          validate={{
                                            required: { value: true, errorMessage: 'State is required' },
                                          }}>
                                          <option value="0">--Select--</option>
                                          {stateList ? stateList.map(n => {
                                            return (
                                              <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                disabled={n.ID === '' ? true : false}
                                                value={n.ID}>
                                                {n.Name}
                                              </option>
                                            );
                                          }) : ''}
                                        </AvField>
                                      </Col>
                                    </>
                                    : ''
                                  }
                                  <Col sm={12} className={!isHomePage ? "text-right-side-condition" : "text-right-side"}>
                                    <Button className="submt-offer-clear" type="reset">Clear</Button>
                                    <Button className="submt-offer">Submit Offer Terms</Button>
                                  </Col>
                                </Row>
                              </AvForm>
                              {/* <Container-fluid><Row className="text-center-or"><Col>OR</Col></Row></Container-fluid> */}
                            </div>
                          </React.Fragment>
                          {deals && deals.length > 0 ?// submitted offer(s)
                            <React.Fragment>
                              <h3 className="blue-with-text mt-3">Submitted Offers:</h3>
                              {/* <LOITerms /> */}
                              <div className={!isHomePage ? "contact-details-condition margin-bottom-property deal-price-max-height" : "contact-details margin-bottom-property dealprice-height"}>
                                {deals.map((deal) =>
                                  <React.Fragment>
                                    <h3 className={!isHomePage ? "dealprice-size-condition" : "dealprice-size"}> Deal Price : ${getFormattedInt(deal.Price)}</h3>
                                    <div className="deal-price-details">
                                      <h3>Details</h3>
                                      <Table>
                                        <tr>
                                          <td>Name</td>
                                          <td>:</td>
                                          <td><span>{deal.LOIName}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Company Name</td>
                                          <td>:</td>
                                          <td><span>{deal.LOICompanyName}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Source of Financing</td>
                                          <td>:</td>
                                          <td><span>{deal.SourceOfFinancing}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Diligence Period</td>
                                          <td>:</td>
                                          <td><span>{deal.DiligencePeriod}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Earnest Money Deposit</td>
                                          <td>:</td>
                                          <td><span>{deal.EarnestMoneyDeposit}%</span></td>
                                        </tr>
                                        <tr>
                                          <td>Status</td>
                                          <td>:</td>
                                          <td><span>{deal.DealStatus}</span></td>
                                        </tr>
                                      </Table>
                                      <hr />
                                    </div>

                                  </React.Fragment>
                                )}
                              </div>
                            </React.Fragment>
                            : ''}
                          <h3 className={!isHomePage ? "margintop25-condition blue-with-text" : "font-size20 margintop25 blue-with-text"} >Contact</h3>
                          <div className={!isHomePage ? "contact-details-condition" : "contact-details"}>

                            <div className={!isHomePage ? "borker-contact-condition" : "borker-contact"}>
                              <Contact data={listingAgents} list={locationlist} />
                            </div>
                          </div>
                        </Col>
                      </React.Fragment>
                      : ''}
                    {/* {(token && userType == USERTYPES.Buyer && propertyData.StatusID == PROPERTYSTATUS.DueDiligence && propertyData.IsInterested && (propertyData.DealStatusID == DEALSTATUS.Accepted)) ?//Property under contract Deal submitted. */}


                    {/* {(token && userType == USERTYPES.Buyer && propertyData.StatusID == PROPERTYSTATUS.DueDiligence && propertyData.IsInterested && (propertyData.DealStatusID == DEALSTATUS.OnHold)) ?//Property under contract Deal submitted but on hold.
                      <div className="col-md-6 add-margin">
                        <div className="property-contact property-contact-condition no-margin">
                          <h3 className="font-size20">Contact</h3>
                          <div className={!isHomePage ? "contact-details-condition" : "contact-details"}>
                            <div className={!isHomePage ? "borker-contact-condition" : "borker-contact"}>

                              <Contact data={listingAgents} />
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''} */}
                  </React.Fragment>
                }
                {/* Property Process end */}
              </Row>
            </Col>
          </Row>
        </div >

        <Modal size="md" id="tst1" name="tst1" isOpen={this.state.NDAModal} className=" modal-sell-my-property edit-market-dashboard">
          {/* {
            isNeedNDASign ?
              <ModalHeader>NDA</ModalHeader>
              : */}
          <ModalHeader toggle={this.toggleClose.bind(this)} >NDA</ModalHeader>
          {/* } */}
          <ModalBody className="overflow-scroll basic-details">
            <AvForm onSubmit={this.addDashBoard.bind(this)} ref={c => (this.myFormRef = c)}>
              <Row>
                <Col md={12}>
                  <p>Please click the link below to download NDA and complete the form to agree to the terms.</p>
                  {documentData != null && documentData.length != 0 && documentData.some(doc => (doc.DocumentTypeID == 8)) ?
                    documentData.map((doc) =>
                      doc.DocumentTypeID == 8 ?
                        <div className="margin-bottom-2nda"><a target="_blank" rel="noopener noreferrer" href={'/StoreDocuments/' + doc.FileName} download={doc.ActualFileName != null ? doc.ActualFileName : doc.FileName} className="download-nda-document"><img src={pdf} alt="" title="PDF Download" /> Download NDA Document</a></div>
                        : '')
                    : ''}
                </Col>
              </Row>
              <Row>
                <Col md={12} className="form-fill-check">
                  <AvField type="checkbox" name="NDA Agreement" label="I agree to the terms in this document:"
                    validate={{
                      required: { value: true, errorMessage: 'NDA is required' }
                    }} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AvField name="Name" label="First Name:*" type="text" maxLength="200" validate={{
                    required: { value: true, errorMessage: 'First Name is required' }
                  }} />
                </Col>
                <Col md={6}>
                  <AvField name="LastName" label="Last Name:*" type="text" maxLength="50" validate={{
                    required: { value: true, errorMessage: 'Last Name is required' }
                  }} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AvField name="Email" label="Email:*" maxLength="100"
                    validate={{
                      required: { value: true, errorMessage: 'Email is required' }
                    }} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AvField name="Address" label="Address:*" maxLength="300"
                    validate={{
                      required: { value: true, errorMessage: 'Address is required' }
                    }} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AvField name="Address2" label="Address2:" maxLength="100" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AvField name="NDACity" label="City:*" maxLength="100"
                    validate={{
                      required: { value: true, errorMessage: 'City is required' }
                    }} />
                </Col>
                <Col md={6}>
                  <AvField type="select" name="NDAState" value={stateId}
                    onChange={(e) => this.stateChange(e)}
                    label="State:*"
                    validate={{
                      required: { value: true, errorMessage: 'State is required' },
                    }}>
                    <option value="0">--Select--</option>
                    {stateList ? stateList.map(n => {
                      return (
                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                          disabled={n.ID === '' ? true : false}
                          value={n.ID}>
                          {n.Name}
                        </option>
                      );
                    }) : ''}
                  </AvField>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AvField name="NDAZipCode" label="Zip Code:*" maxLength="50"
                    validate={{
                      required: { value: true, errorMessage: 'Zip Code is required' }
                    }} />
                </Col>
                <Col md={6}>
                  <AvField name="Phone" label="Phone:*" placeholder="xxx-xxx-xxxx" validate={{
                    required: { value: true, errorMessage: 'Phone is required' },
                    pattern: { value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone is invalid' }
                  }}
                    value={this.state.phone}
                    onChange={(e) => this.handlePhoneChange(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AvField name="Entity" label="Entity:" type="text" maxLength="1000" />
                </Col>
                <Col md={6} className="save-right">
                  <div className="margin-top-17">
                    {/* {
                      !isNeedNDASign ? */}
                    <Button className="cancle-btn-msg " onClick={this.toggleClose.bind(this)}>Cancel</Button>
                    {/* : ''} */}
                    <Button id="btn" className="btn btn-primary sec-btn-clr">Submit</Button>
                  </div>
                </Col>
              </Row>

            </AvForm>
          </ModalBody>
        </Modal>
        <Modal size="md" id="confirmOffersubmit" name="confirmOffersubmit" isOpen={isconfirmOffer} className=" modal-sell-my-property  popup-submit-offer edit-market-dashboard">
          <ModalHeader className="model-head">Please confirm your offer terms are correct.</ModalHeader>
          <ModalBody className="overflow-scroll basic-details popup-model-box">
            {offerData.Store && offerData.BuyerStore ?
              <React.Fragment>
                <Row>
                  <Col md={12} className={!isHomePage ? "" : "table-padding-add"}>
                    <Table className={!isHomePage ? "table-dealviewmodal table-margin-add" : "table-dealviewmodal-condition table-margin-add"} >
                      <tr>
                        <td>Offer Price</td>
                        <td>:</td>
                        <td><span>${getFormattedInt(offerData.Store.Price)}</span></td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td><span>{offerData.BuyerStore.LOIName}</span></td>
                      </tr>
                      <tr>
                        <td>Company Name</td>
                        <td>:</td>
                        <td><span>{offerData.BuyerStore.LOICompanyName}</span></td>
                      </tr>
                      <tr>
                        <td>Diligence Period</td>
                        <td>:</td>
                        <td><span>
                          {diligencePeriods.map(d =>
                            d.ID == offerData.BuyerStore.DiligencePeriodID ?
                              d.Name : ''
                          )}</span></td>
                      </tr>
                      <tr>
                        <td>Earnest Money Deposit</td>
                        <td>:</td>
                        <td> <span>{offerData.BuyerStore.EarnestMoneyDeposit}%</span></td>
                      </tr>
                      <tr>
                        <td>Source of Financing</td>
                        <td>:</td>
                        <td><span>{offerData.BuyerStore.SourceOfFinancing}</span></td>
                      </tr>
                      {isOffline ?
                        <>
                          <tr>
                            <td>Buyer First Name</td>
                            <td>:</td>
                            <td><span>{offerData.BuyerStore.OfflineBuyerFirstName}</span></td>
                          </tr>

                          <tr>
                            <td>Buyer Last Name</td>
                            <td>:</td>
                            <td><span>{offerData.BuyerStore.OfflineBuyerLastName}</span></td>
                          </tr>
                          <tr>
                            <td>Buyer Email</td>
                            <td>:</td>
                            <td><span>{offerData.BuyerStore.OfflineBuyerEmail}</span></td>
                          </tr>
                          <tr>
                            <td>Buyer City</td>
                            <td>:</td>
                            <td><span>{offerData.BuyerStore.OfflineBuyerCity}</span></td>
                          </tr>
                          <tr>
                            <td>Buyer State</td>
                            <td>:</td>
                            <td><span>{offerData.BuyerStore.OfflineBuyerState}</span></td>
                          </tr>
                        </>
                        : ''
                      }
                    </Table>
                  </Col>
                </Row>
                <Row className="save-right">
                  <Col md={6}>
                    <Button id="btn" className="btn yes-confirm btn-primary" onClick={this.confirmSubmitOffer.bind(this)}>Yes, Confirm & Submit</Button>
                  </Col>
                  <Col md={4}>
                    <Button id="btn" className="btn no-revise btn-primary" onClick={this.toggleCloseConfirmOffer.bind(this)}>No, Revise</Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </React.Fragment>
              : ''}
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
