import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from '../../Utils/config';
import { Link } from "react-router-dom";
import about_icon from './../../Assets/Img/about-us-icon.png';
import teamDefaultImg from './../../Assets/Img/ventor-list.png';
import './AboutUs.css';

const AboutUs = () => {
    const [isLoading, setisLoading] = useState(false);
    const [apiData, setApiData] = useState([]);

    const aboutUsApi = async () => {
        setisLoading(true);
        await axios.get(`${CONFIG.API_URL}team/list`)
            .then((res) => {
                if (res.status === 200) {
                    setisLoading(false);
                    setApiData(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        aboutUsApi();
    }, []);

    const errorImg = (e) => {
        e.target.onerror = null;
        e.target.src = teamDefaultImg;
    };

    document.title = CONFIG.PAGE_TITLE + 'About Us';
    return (
        <Fragment>
            {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> :
                <div>
                    <div className="vendor-profilelist addpadding-lr">
                        <Container-fluid>
                            <div className="item">
                                <img src={about_icon} className="" alt="" />
                                <div className="content">  <h2 className="header-space">Meet the Team<br /> <span><Link to="/">Home</Link> / <Link to='/aboutus'>Team</Link></span></h2></div>
                            </div>
                        </Container-fluid>
                    </div>

                    <div className="team-cont">
                        <div className="row">
                            {apiData && apiData.length > 0 ? apiData
                                .map((val) =>
                                    <div className="width-25" key={val.Id}>
                                        <Link to={`/teamdetails/${val.Id}`}>
                                            <img src={`/TeamImages/${val.ImageName}`} alt={val.FirstName + ' ' + val.LastName} onError={(e) => { errorImg(e) }} />
                                            <div className="team-meb-name">
                                                {val.FirstName + ' ' + val.LastName}
                                                <p>{val.Title}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                                : null}
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default AboutUs