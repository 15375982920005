import React, { Component } from 'react';
import { CONFIG } from '../../../../../Utils/config';
import { DATERANGE } from "../../../../../Utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import './PropertyReport.css';
import { Button, Label, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { Bar, BarChart, Legend, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export default class PropertyReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      rangeID: "1",
      trackId: 0,
      propertyId: 0,
      dateRangeData: [],
      proeprtyData: [],
      propertyName: '',
      fromDate: '',
      minDate: '',
      toDate: '',
      toDateError: '',
      recordData: [],
      trackingData: [],
      propertyData: [],
      toDisable: true,
      result: DATERANGE[1],
      reportReqData: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
    this.handleRange = this.handleRange.bind(this);
  }

  handleFromDate(date) {
    let to = this.state.toDate;
    if (to === '') {
      let fromDate = moment(date).format('L');
      if (fromDate.length < 11) {
        this.setState({
          fromDate: date, toDisable: false
        });
      }
    }
    else {
      if (date < this.state.toDate) {
        let toDate = moment(date).format('L');
        if (toDate.length < 11) {
          this.setState({
            fromDate: date, toDisable: false, toDateError: ''
          });
        }
      }
      else {
        this.setState({ toDateError: 'Please select valid To Date', toDate: '' })
      }
    }
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    var text = value === 'PropertyAdded' ? 'Property Added' : value === 'PropertyViewed' ? 'Property Viewed' : value === 'OfferReceived' ? 'Offer Received' : value === 'NDASigned' ? 'NDA Signed' : value === 'DealAccepted' ? 'Deal Accepted' : value === 'DealRejected' ? 'Deal Rejected' : value === 'PropertySold' ? 'Property Sold' : '';
    return <span style={{ color }}>{text}</span>;
  }

  handleRange() {
    this.setState({ fromDate: '', toDate: '' });
  }

  handleToDate(date) {
    if (date >= this.state.fromDate) {
      let toDate = moment(date).format('L');
      if (toDate.length < 11) {
        this.setState({
          toDate: date, toDateError: ''
        });
      }
    }
    else {
      this.setState({ toDateError: 'Please select valid To Date' })
    }
  }

  resetForm(event) {
    this.form.reset();
    this.setState({ fromDate: '', toDate: '', toDateError: '', result: DATERANGE[1] })
    this.componentDidMount();
  }

  handleSubmit(event, errors, values) {
    if ((this.state.fromDate !== "" && this.state.toDate === "")) {
      this.setState({ toDateError: 'Please select To Date' })
    }
    else {
      if (errors.length === 0) {
        this.setState({ isLoading: true });
        const { propertyData } = this.state;
        let result = '';

        if ((this.state.fromDate !== "" && this.state.toDate !== "")) {
          result = moment(this.state.fromDate).format("L") + ' to ' + moment(this.state.toDate).format("L");
        }
        else {
          result = values.range == '' ? '' : DATERANGE[parseInt(values.range)];
        }

        if (values.propertyId > 0) {
          var selectedProperty = propertyData.filter(p => p.ID == values.propertyId);
          result = result + " with Property Name \"" + selectedProperty[0].Name + "\"";
        }

        const data = {
          'trackId': parseInt(values.tracking),
          'propertyId': parseInt(values.propertyId),
          'propertyName': values.propertyName,
          'rangeId': values.range == '' ? this.state.rangeId : parseInt(values.range),
          'startDate': this.state.fromDate,
          'endDate': this.state.toDate
        }
        this.getReport(data);
        this.setState({ result: result })
      }
    }
  }

  getReport(param) {
    if (param.startDate != "") {
      param.rangeId = '';
    }

    const data = {
      'DateRangeID': param.rangeId,
      'TrackingTypeID': param.trackId,
      'PropertyID': param.propertyId,
      'PropertyName': param.propertyName,
      'StartDate': param.startDate !== '' ? moment(param.startDate).format('YYYY-MM-DD') : '',
      'EndDate': param.endDate !== '' ? moment(param.endDate).format('YYYY-MM-DD') : ''
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.post(CONFIG.API_URL + 'analysis/report/dealreport/', data)
      .then(response => {
        if (response.status === 200) {
          this.setState({ isLoading: false, recordData: response.data.report, reportReqData: data });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const error = err.response.data;
        }
        else {
          const error = "Something went wrong.";
        }

      });
  }

  reportExport() {
    let { reportReqData, result } = this.state;
    let token = localStorage.getItem('accessKey');

    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    reportReqData.Criteria = result;

    axios({
      url: CONFIG.API_URL + "analysis/report/dealreport/download",
      method: "POST",
      responseType: "blob", // important
      data: reportReqData,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "property_report.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          const error = "Invalid request";
        } else {
          const error = err.response.statusText;
        }
      });
  }

  getLookupValues() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'lookup/daterange')
      .then(res => {
        this.setState({ dateRangeData: res.data.daterange });
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(CONFIG.API_URL + 'lookup/tracking')
      .then(res => {
        this.setState({ trackingData: res.data.daterange });
      })
      .catch((err) => {
        console.log(err);
      });

    // stores
    axios.get(CONFIG.API_URL + 'lookup/stores/0')
      .then(res => {
        this.setState({ propertyData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    var today = new Date();
    let minDate = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate());

    this.setState({ minDate: minDate })
    this.getLookupValues();
    const data = {
      'trackId': '',
      'propertyId': '',
      'rangeId': 1,
      'startDate': '',
      'endDate': ''
    }
    this.getReport(data);
  }

  render() {
    document.title = CONFIG.PAGE_TITLE + 'Analytics - Searches';
    const { isLoading, dateRangeData, rangeID, fromDate, result, minDate, toDisable, toDateError, trackId, propertyId, propertyName, trackingData, propertyData, toDate, recordData } = this.state;

    return (
      <main className="">
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <div className="admin-dash-details">

          <AvForm onSubmit={this.handleSubmit.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.form = c)}>
            <Row className="form-analytics-fields form-back-shadow">
              <Col md={3}>
                <AvField type="select" name="range" value={rangeID} onChange={this.handleRange.bind(this)}
                  label="Date Range:">
                  {dateRangeData ? dateRangeData.map(n => {
                    return (
                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                        disabled={n.ID === '' ? true : false}
                        value={n.ID}>
                        {n.Name}
                      </option>
                    );
                  }) : ''}
                </AvField>
              </Col>

              <Col md={3}>
                <AvField type="select" name="tracking" value={trackId} label="Tracking Type:">
                  <option value={trackId}>--Select--</option>
                  {trackingData ? trackingData.map(n => {
                    return (
                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                        disabled={n.ID === '' ? true : false}
                        value={n.ID}>
                        {n.Name}
                      </option>
                    );
                  }) : ''}
                </AvField>
              </Col>

              <Col md={3}>
                <Label>From Date:</Label>
                <DatePicker className="is-touched is-pristine av-valid form-control" selected={fromDate} onChange={this.handleFromDate} maxDate={new Date()} minDate={minDate} />
              </Col>

              <Col md={3}>
                <Label>To Date:</Label>
                <DatePicker className="is-touched is-pristine av-valid form-control" selected={toDate} onChange={this.handleToDate} maxDate={new Date()} minDate={minDate} disabled={toDisable} />
                <p className="analtics-error">{toDateError}</p>
              </Col>

              {/* <Col md={3}>
                <AvField name="propertyName" label="Property Name:" type="text" value={propertyName} />
              </Col> */}

              <Col md={3}>
                <AvField type="select" name="propertyId" value={propertyId} label="Property Name:">
                  <option value={propertyId}>--Select--</option>
                  {propertyData ? propertyData.map(n => {
                    return (
                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                        disabled={n.ID === '' ? true : false}
                        value={n.ID}>
                        {n.Name}
                      </option>
                    );
                  }) : ''}
                </AvField>
              </Col>

              <Col md={12} className="text-align-right">
                <Button className="button-12s" type="submit">Submit</Button>
                <Button className="btn-reset" type="reset">Clear</Button>
              </Col>
            </Row>
          </AvForm>
          <Row className="showing-row">
            <Col md={10}><p><span style={{ color: "#4c4c4c" }}>Showing result: </span>{result}</p></Col>
            {recordData.length === 0 ? '' :
              <Col md={2}>
                <a onClick={() => this.reportExport()} href="javascript:void(0)" className="excel-pop-download" title="Click here to download report">
                  <FontAwesomeIcon icon={faFileExcel} />
                  Download
                </a>
                <div className="clear"></div>
              </Col>
            }
          </Row>
          {recordData.length === 0 ? <h6 className="no-records-found-in-filter">No data found</h6> :
            <Col md={12} className="text-align-left remove-padd-search search-graph">
              <BarChart
                width={1020}
                height={300}
                barSize={40}
                data={recordData}
                margin={{
                  top: 5, right: 0, left: -30, bottom: 0,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="TimePeriod" />
                <YAxis />
                <Tooltip />
                <Legend formatter={this.renderColorfulLegendText} />
                <Bar dataKey="PropertyAdded" fill="#8884d8" />
                <Bar dataKey="PropertyViewed" fill="#82ca9d" />
                <Bar dataKey="OfferReceived" fill="#f69e4f" />
                <Bar dataKey="NDASigned" fill="#086A87" />
                <Bar dataKey="PropertySold" fill="#1b2c42" />
                <Bar dataKey="DealAccepted" fill="#4caf50" />
                <Bar dataKey="DealRejected" fill="#f96a5d" />
              </BarChart>
            </Col>
          }
        </div>
      </main>
    );
  }
}
