import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, NavbarText } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from './../../Utils/config';
import { normalizeInput } from './../../Utils/utils';
import { removeLocalStorage } from './../../Utils/localStorage';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import signin from './../../Assets/Img/signin-icon.png';
import mobile_icon from './../../Assets/Img/miobile-icon.png';
import social_icon from './../../Assets/Img/linkedin-social.png';
import social_icon_insta from './../../Assets/Img/instagram.png';
import dropdown_icon from './../../Assets/Img/dropdown.png';
import createres from './../../Assets/Img/create-res.png';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
} from 'reactstrap';

import { getToken, getUserType, getName } from '../../Utils/localStorage';
import { USERTYPES } from '../../Utils/config';

var images = require.context('./../../Assets/Img', true);

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            activeMenu: 'home',
            toggleLogout: false,
            sellMyPropertyModal: parseInt(this.props.parm.children.props.match.params.flag) === 1 ? true : false,
            error: '',
            phone: ''
        };
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    handlePhoneChange({ target: { value } }) {
        this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    signOut() {

        let sessionId = localStorage.getItem('sessionId');
        if (sessionId) {
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.post(CONFIG.API_URL + 'home/logout/' + sessionId)
                .then(res => {
                    this.signOutAndRedirect();
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            this.signOutAndRedirect();
        }
    }

    signOutAndRedirect() {
        removeLocalStorage();
        this.commonAction();
        const { from } = { from: { pathname: "/" } };
        this.props.parm.children.props.history.push(from)
    }


    toggleClose() {
        this.myFormRef && this.myFormRef.reset();
        this.setState({ sellMyPropertyModal: false, phone: '', error: '' });
    }

    sellMyProperty() {
        this.setState({ sellMyPropertyModal: true });
    }

    sellMyPropertyRequest(event, errors, values) {
        this.setState({ error: '' });
        if (errors.length === 0) {
            const data = {
                'Name': values.Name,
                'Phone': values.Phone,
                'Email': values.Email,
                'Message': values.Message
            }

            axios.post(CONFIG.API_URL + 'home/sell/myproperty', data)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data != "success") {
                            const error = response.data;
                            this.setState({ error });
                        } else {
                            alert("Thank you for reaching out. One of our agents will be in touch shortly.");
                            this.setState({ sellMyPropertyModal: false });
                        }
                    }
                })
                .catch(err => {
                    if (err.response != null && err.response.status === 400) {
                        const error = err.response.data;
                        this.setState({ error });
                    }
                    else {
                        const error = "Something went wrong.";
                        this.setState({ error });
                    }
                });
        }
    }

    commonAction() {
        var token = getToken();
        var userType = getUserType();
        var name = getName();
        var activeMenu = "home";

        if (this.props.parm.children.props.match.path == "/buyerservices" || this.props.parm.children.props.match.path == "/sellerservices") {
            activeMenu = 'service';
        } else if (this.props.parm.children.props.match.path == "/contactus") {
            activeMenu = 'contact';
        }
        else if (this.props.parm.children.props.match.path == "/aboutus") {
            activeMenu = 'about';
        }
        else if (this.props.parm.children.props.match.path == "/faq") {
            activeMenu = 'faq';
        }
        else if (this.props.parm.children.props.match.path == "/press/") {
            activeMenu = 'press';
        }
        else if (this.props.parm.children.props.match.path == "/property/:propertyId/:name/" || this.props.parm.children.props.match.path == "/property/search/" || this.props.parm.children.props.match.path == "/property/search/:keyword") {
            activeMenu = "listings";
        }
        this.setState({ token, name, userType, activeMenu });

    }

    componentDidMount() {
        this.commonAction();
        window.scrollTo(0, 0);
    }

    activeClass = (menu) => {
        this.setState({
            activeMenu: menu,
        });
    }

    toggleLogout = (toggleLogout) => {
        this.setState({ toggleLogout });
    }

    render() {
        let img_src = images(`./SE-LOGO-FINAL.png`);
        const { token, name, userType, activeMenu, toggleLogout, sellMyPropertyModal, error } = this.state;
        return (

            <div className="header page-sticky">
                <Container-fluid>
                    <div className="row top-nav">
                        <div className="col-md-6 marginauto">
                            <div>
                                <Link to="/" >
                                    <img src={img_src} className="logo" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <ul className="top-num">

                                <li><a href="https://www.linkedin.com/company/storage-exchange" target="blank"><img src={social_icon} className="social" alt="LinkedIn" title="LinkedIn" /></a></li>
                                <li><a href="https://www.instagram.com/storageexchange/" target="blank"><img src={social_icon_insta} className="social" alt="instagram" title="instagram" /></a></li>
                                {/* <li><b>Contact: 720-310-0113</b></li> */}
                                <li>
                                    {!token ?
                                        <div>
                                            <Link to="/buyer-registration/" className="Sign-in-btn padd-15p">{/*<img src={createres} className="sign_icon" alt="sign in" />*/}<FontAwesomeIcon icon={faUser} /> <b>REGISTER </b></Link>
                                            <Link to="/signin" className="Sign-in-btn">{/*<img src={signin} className="sign_icon" alt="sign in" />*/} <FontAwesomeIcon icon={faSignInAlt} /> <b>SIGN IN</b></Link>
                                        </div>
                                        :
                                        <React.Fragment>
                                            {'Welcome ' + name}
                                            <div className="dropdown btn-dropdown-header">
                                                <button onClick={() => this.toggleLogout(!toggleLogout)} className="dropbtn"><img src={dropdown_icon} alt="" /></button>
                                                <div className={toggleLogout ? "dropdown-content-show" : "dropdown-content"}>
                                                    <Link onClick={this.signOut.bind(this)}>Logout</Link>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </li>

                            </ul>
                        </div>
                    </div>

                </Container-fluid>

                <Navbar color="dark" dark expand="md" className="menu-bar">
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem onClick={() => this.activeClass("home")}>
                                <Link className={activeMenu == "home" || this.props.parm.children.props.match.path == "/" ? "active" : "inactive"} to="/" >Home</Link>
                            </NavItem>
                            <NavItem onClick={() => this.activeClass("listings")}>
                                <Link className={activeMenu == "listings" && this.props.parm.children.props.match.path == "/property/:propertyId/:name/" || this.props.parm.children.props.match.path == "/property/search/" || this.props.parm.children.props.match.path == "/property/search/:keyword" ? "active" : "inactive"} to="/property/search/" >Properties</Link>
                            </NavItem>
                            {/* <NavItem onClick={() => this.activeClass("vendor")}>
                                <Link className={activeMenu == "vendor" || this.props.parm.children.props.match.path == "/profilelist/:parentCategoryID/:parentCategoryName/:childCategoryID/:childCategoryName" || this.props.parm.children.props.match.path == "/categorylist/" ? "active" : "inactive"} to="/categorylist" >Vendors</Link>
                            </NavItem> */}
                            {/* <NavItem onClick={() => this.activeClass("service")}>
                                <Link className={activeMenu == "service" || this.props.parm.children.props.match.path == "/schedules/" ? "active" : "inactive"} to="/schedules" >Services</Link>
                            </NavItem> */}
                            <UncontrolledDropdown nav inNavbar >
                                <DropdownToggle nav caret onClick={() => this.activeClass("service")} className="menu-link-drop">
                                    <Link className={activeMenu == "service" && this.props.parm.children.props.match.path == "/buyerservices" || this.props.parm.children.props.match.path == "/sellerservices" ? "active left-remove-padd" : "inactive left-remove-padd"} >Services</Link>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <div className="linkdropdown"><Link className={activeMenu == "buyerservices" && this.props.parm.children.props.match.path == "/buyerservices" ? "active" : "inactive"} to="/buyerservices" >Buyer Services</Link></div>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <div className="linkdropdown"><Link className={activeMenu == "sellerservices" && this.props.parm.children.props.match.path == "/sellerservices" ? "active" : "inactive"} to="/sellerservices" >Seller Services</Link></div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem onClick={() => this.activeClass("about")}>
                                <Link className={activeMenu == "about" && this.props.parm.children.props.match.path == "/aboutus" ? "active" : "inactive"} to="/aboutus" >Team</Link>
                            </NavItem>
                            <NavItem onClick={() => this.activeClass("press")}>
                                <Link className={activeMenu == "press" && this.props.parm.children.props.match.path == "/press/" ? "active" : "inactive"} to="/press/" target="_blank" rel="noopener noreferrer" >Press</Link>
                            </NavItem>
                            <NavItem onClick={() => this.activeClass("faq")}>
                                <Link className={activeMenu == "faq" && this.props.parm.children.props.match.path == "/faq" ? "active" : "inactive"} to="/faq" title="Frequently Asked Questions" >FAQ</Link>
                            </NavItem>
                            <NavItem onClick={() => this.activeClass("contact")}>
                                <Link className={activeMenu == "contact" && this.props.parm.children.props.match.path == "/contactus" ? "active" : "inactive"} to="/contactus" >Contact Us</Link>
                            </NavItem>
                            {/* <NavItem>
                                <Link className="inactive" onClick={this.sellMyProperty.bind(this)}>Sell My Property</Link>
                            </NavItem> */}
                        </Nav>
                        <Nav className="my-dashboard-menu">
                            {userType ?
                                <NavItem>
                                    <Link to={userType == USERTYPES.Buyer ? "/buyer/dashboard" : userType == USERTYPES.Seller ? "/seller/dashboard" : "/admin/dashboard/1"}> My DashBoard</Link>
                                </NavItem>
                                : ''}
                        </Nav>
                    </Collapse>
                </Navbar>

                <Modal size="md" isOpen={this.state.sellMyPropertyModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="modal-sell-my-property edit-market-dashboard">
                    <ModalHeader toggle={this.toggleClose.bind(this)}>Sell My Property
                    </ModalHeader>
                    <ModalBody>
                        <AvForm onSubmit={this.sellMyPropertyRequest.bind(this)} ref={c => (this.myFormRef = c)}>
                            <Row>
                                <Col md={12}>
                                    <AvField name="Name" label="Name:*" type="text" validate={{
                                        required: { value: true, errorMessage: 'Name is required' }
                                    }} />
                                </Col>
                                <Col md={12}>
                                    <AvField name="Phone" label="Phone:*" placeholder="xxx-xxx-xxxx" validate={{
                                        required: { value: true, errorMessage: 'Phone is required' },
                                        pattern: { value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone is invalid' }
                                    }}
                                        value={this.state.phone}
                                        onChange={(e) => this.handlePhoneChange(e)}
                                    />
                                </Col>
                                <Col md={12}>
                                    <AvField name="Email" label="Email:*" validate={{
                                        required: { value: true, errorMessage: 'Email is required' },
                                        pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                    }} />
                                </Col>
                                <Col md={12}>
                                    <AvField type="textarea" name="Message" className="description-box-height" label="Message:*"
                                        validate={{
                                            required: { value: true, errorMessage: 'Message is required' },
                                        }} />
                                </Col>
                            </Row>
                            <Row className="save-right">
                                <Col md={12}>
                                    <Button className="cancle-btn-msg" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                                    <Button className="btn btn-primary sec-btn-clr">Submit</Button>
                                    <br />
                                    <span className="sign-error">{error}</span>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

export default Header;