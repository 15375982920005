import React from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Table } from 'reactstrap';
import { getFormattedInt } from '../../../../../Utils/utils';
import './DealViewModal.css';
import moment from 'moment';

export default class DealViewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            deal: {},
        }
           
    }
    componentDidMount() {
    }
    

    getDeal(deal) {

        if (deal.EarnestMoneyDeposit) {
            var earnestMoneyDeposit = deal.EarnestMoneyDeposit.split('.');
            var formattedEarnestMoneyDeposit = getFormattedInt(earnestMoneyDeposit[0].replace(/,/g, ''));
            formattedEarnestMoneyDeposit = earnestMoneyDeposit.length > 1 ? formattedEarnestMoneyDeposit + "." + earnestMoneyDeposit[1] : formattedEarnestMoneyDeposit;
            deal.EarnestMoneyDeposit = formattedEarnestMoneyDeposit;
        }
        this.setState({ isOpen: true, deal });
    }

    toggleClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const { isOpen, deal } = this.state;
        return (
            <div>
                <Modal size="md" isOpen={isOpen} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new deal-view-modal edit-market-dashboard">
                    <ModalHeader toggle={this.toggleClose.bind(this)}>{deal.DealName}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="row-width-model">
                            <Col md={6} className="half-width-model">
                                <div className="box-model-deal">
                                    <Table className="table-dealviewmodal">
                                        <tr>
                                            <td>Offer Price</td>
                                            <td>:</td>
                                            <td>{deal.Price > 0 ? <span>${getFormattedInt(deal.Price)}</span> : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>LOI Name</td>
                                            <td>:</td>
                                            <td><span>{deal.LOIName}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Company Name</td>
                                            <td>:</td>
                                            <td><span>{deal.LOICompanyName}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Diligence Period</td>
                                            <td>:</td>
                                            <td><span>{deal.DiligencePeriod}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Earnest Money Deposit</td>
                                            <td>:</td>
                                            <td>{deal.EarnestMoneyDeposit > 0 ? <span>{deal.EarnestMoneyDeposit}%</span> : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Source of Financing</td>
                                            <td>:</td>
                                            <td><span>{deal.SourceOfFinancing}</span></td>
                                        </tr>
                                        {
                                            deal.OfflineBuyerFirstName != null && deal.OfflineBuyerFirstName != '' ?
                                            <>
                                                <tr>
                                                    <td>Buyer First Name(Offline)</td>
                                                    <td>:</td>
                                                    <td>{deal.OfflineBuyerFirstName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Buyer Last Name(offline)</td>
                                                    <td>:</td>
                                                    <td><span>{deal.OfflineBuyerLastName}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Buyer Email</td>
                                                    <td>:</td>
                                                    <td><span>{deal.OfflineBuyerEmail}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Buyer City</td>
                                                    <td>:</td>
                                                    <td><span>{deal.OfflineBuyerCity}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Buyer State</td>
                                                    <td>:</td>
                                                    <td><span>{deal.OfflineBuyerState}</span></td>
                                                </tr>
                                                </>
                                        : ''
                                        }
                                    </Table>
                                </div>
                                {/* <p>LOI Name:{deal.LOIName}</p>
                            
                                <p>Company Name:{deal.LOICompanyName}</p>
                           
                                <p>Diligence Period:{deal.DiligencePeriod}</p>
                           
                                <p>Earnest Money Deposit:${getFormattedInt(deal.EarnestMoneyDeposit)}</p> */}
                            </Col>
                            <Col md={6} className="half-width-model">
                                <div className="box-model-deal">
                                    <Table className="table-dealviewmodal">

                                        <tr>
                                            <td>NDA Name</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAName}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Last Name</td>
                                            <td>:</td>
                                            <td><span>{deal.NDALastName}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Email</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAEmail}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Address</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAAddress}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Address2</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAAddress2}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA City</td>
                                            <td>:</td>
                                            <td><span>{deal.NDACity}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA State</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAState}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Zip Code</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAZipCode}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Phone</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAPhone}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Entity</td>
                                            <td>:</td>
                                            <td><span>{deal.NDAEntity}</span></td>
                                        </tr>
                                        <tr>
                                            <td>NDA Signed Date</td>
                                            <td>:</td>
                                            <td><span>{moment(deal.NDASignedDate).format("L")}</span></td>
                                        </tr>

                                    </Table>
                                </div>
                                {/* <p>Source Of Financing:{deal.SourceOfFinancing}</p>
                           
                                <p>NDA Name:{deal.NDAName}</p>
                           
                                <p>NDA Company Name:{deal.NDACompanyName}</p>
                           
                                <p>NDASignedDate:{moment(deal.NDASignedDate).format("L")}</p> */}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}