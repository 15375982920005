import React from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Table } from 'reactstrap';
import { getFormattedInt } from '../../../../../Utils/utils';
import './DealList.css';
import moment from 'moment';
import editicon from '../../../../../Assets/Img/edit.png';
import { DealViewModal } from '../DealView';
import { LOIViewModal } from '../LOIView';
import { OfferPDFModal } from '../OfferPDF';
import view_icon from '../../../../../Assets/Img/view.png';
import { CONFIG, DEALSTATUS, PROPERTYSTATUS } from '../../../../../Utils/config';
import axios from 'axios';
import disable from '../../../../../Assets/Img/disabel.png';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { confirmAlert } from 'react-confirm-alert';
import CurrencyFormat from 'react-currency-format';

export default class DealList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dealStatusList: [],
            deal: {},
            isLoading: false,
            isEdit: false,
            dealId: 0,
            storeId: 0,
            buyerId: 0,
            price: 0,
            storeDeals: [],
            dealStatusID: 0,
            isDealTracker: false
        }

        this.dealView = React.createRef();
        this.loiView = React.createRef();
        this.offerPDF = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        return { storeDeals: props.deal.Deals, isDealTracker: props.isDealTracker };
    }

    componentDidMount() {
        this.getLookupDealStatus();
    }

    getLookupDealStatus() {
        axios.get(CONFIG.API_URL + 'lookup/deal/status').then(response => {
            if (response.status === 200) {
                this.setState({ dealStatusList: response.data, isLoading: false });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });

    }

    editDeal = (id, storeId, buyerId, price) => {
        this.setState({ isEdit: true, dealId: id, storeId: storeId, buyerId: buyerId, price: price });
    };

    statusChange(e) {
        var value = e.target.value;
        this.setState({ dealStatusID: value });
        this.updateDealStatus(value);
    }

    updateDealStatus = (statusId) => {
        confirmAlert({
            title: 'Update Deal Status',
            message: 'Are you sure want to update this deal status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ isLoading: true });
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }

                        let endpoint = '';

                        switch (statusId) {
                            case 1:
                                endpoint = "accept";
                                break;

                            case 2:
                                endpoint = "reject";
                                break;

                            case 5:
                                endpoint = "sold";
                                break;

                            default:
                                endpoint = "process";
                                break;
                        }
                        const { dealId, storeId, buyerId, price } = this.state;

                        let data = {
                            Id: dealId,
                            StoreId: storeId,
                            BuyerId: buyerId,
                            Price: price,
                            DealStatusID: statusId
                        }

                        axios.post(CONFIG.API_URL + 'admin/deal/' + endpoint, data)
                            .then(res => {
                                this.setState({ isLoading: false, isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0 });
                                this.props.triggerFromChild();
                            })
                            .catch(err => {
                                this.setState({ isLoading: false, isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0 });
                                console.log(err);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        this.setState({ isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0 });
                    }
                }
            ]
        });
    };

    viewDeal(deal) {
        this.dealView.current.getDeal(deal);
    }

    viewLOI(deal) {
        this.loiView.current.getDeal(deal);
    }

    viewReport(buyerStoreId) {
        this.offerPDF.current.generateReport(buyerStoreId);
    }

    render() {
        //const storeDeals = this.props.deal.Deals;
        const { dealStatusList, isEdit, dealId, dealStatusID, isLoading, storeDeals, isDealTracker } = this.state;
        return (
            <React.Fragment>
                {isLoading ? <div className="loader-wrap">< div className="page-loading"></div></div> : ''
                }
                <DealViewModal ref={this.dealView} />
                <LOIViewModal ref={this.loiView} />
                <OfferPDFModal ref={this.offerPDF} />
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell className="property-name-width">{isDealTracker ? 'Proprty Name' : 'Deal Name'}</TableCell>
                        <TableCell>Buyer</TableCell>
                        {isDealTracker ?
                            <React.Fragment>
                                <TableCell>City</TableCell>
                                <TableCell>State</TableCell>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <TableCell className="phonenumber-width">Phone</TableCell>
                                <TableCell className="property-email-width">Email</TableCell>
                            </React.Fragment>}
                        <TableCell>Proposed</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {storeDeals ? storeDeals.map((deal) =>
                        <TableRow key={deal.ID}>
                            <TableCell>{moment(deal.StatusDate).format("L")}</TableCell>
                            <TableCell>{isDealTracker ? deal.StoreName : deal.DealName} </TableCell>
                            <TableCell>{deal.OfflineBuyerFirstName != null && deal.OfflineBuyerFirstName != '' ?
                                deal.OfflineBuyerFirstName + " " + deal.OfflineBuyerLastName + "(Offline)"
                                :
                                deal.Buyer
                            }</TableCell>
                            {isDealTracker ?
                                <React.Fragment>
                                    <TableCell>{deal.OfflineBuyerFirstName != null && deal.OfflineBuyerFirstName != '' ?
                                        deal.OfflineBuyerCity : deal.City}
                                    </TableCell>
                                    <TableCell>{deal.OfflineBuyerFirstName != null && deal.OfflineBuyerFirstName != '' ?
                                        deal.OfflineBuyerState : deal.State}
                                    </TableCell>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <TableCell>{deal.PhoneNumber}</TableCell>
                                    <TableCell>{deal.Email}</TableCell>
                                </React.Fragment>}
                            <TableCell>
                                <CurrencyFormat value={deal.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </TableCell>
                            <TableCell>
                                {isEdit == true && dealId === deal.ID ?
                                    <Select labelId="demo-simple-select-filled-label" className="select-deal" id="demo-simple-select-filled"
                                        value={dealStatusID} onChange={(e) => this.statusChange(e)}>
                                        <MenuItem value="0">-Select-</MenuItem>
                                        {dealStatusList ? dealStatusList.map(n => {
                                            if (n.ID != DEALSTATUS.NDAExecuted && n.ID != DEALSTATUS.Evaluation && n.ID != DEALSTATUS.OnHold) {
                                                return (
                                                    <MenuItem key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                        disabled={n.ID === '' ? true : false}
                                                        value={n.ID}>
                                                        {n.Name}
                                                    </MenuItem>
                                                );
                                            } else {

                                            }
                                        }) : ''}
                                    </Select>
                                    : deal.DealStatus
                                }
                            </TableCell>
                            <TableCell>
                                <div className="button-dealbtn">
                                    {(deal.StatusID === PROPERTYSTATUS.Sold || (deal.DealStatusID === DEALSTATUS.Closed || deal.DealStatusID === DEALSTATUS.OnHold || deal.DealStatusID === DEALSTATUS.NDAExecuted)) ?
                                        <Button className="margin-bottom-edit no-button-background"><img src={disable} alt="edit" title="No Edit" /></Button>
                                        : <Button type="edit" className="button-common background-edit deallist-btn-update" onClick={() => this.editDeal(deal.ID, deal.StoreID, deal.BuyerID, deal.Price)}>Update deal stage</Button>
                                    }
                                    <div>
                                        <Button type="view" className="button-common background-view " onClick={this.viewDeal.bind(this, deal)}>View</Button>
                                    </div>
                                    {
                                        isDealTracker && deal.StatusID !== PROPERTYSTATUS.Sold && deal.DealStatusID !== DEALSTATUS.Closed ?
                                        <div><Button type="view" className="button-common background-view " onClick={this.viewLOI.bind(this, deal)}>Offline LOI</Button></div>
                                        : ''
                                    }
                                    {
                                        deal.ID ?
                                        <div>
                                            <Button type="PDF Download" className="button-common background-view " onClick={this.viewReport.bind(this, deal.ID)}>PDF Download</Button>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    ) :
                        <TableRow></TableRow>
                    }
                </TableBody>
            </React.Fragment>
        );
    }
}