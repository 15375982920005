import React, { Component } from 'react';
import { CONFIG } from '../../../../../Utils/config';
import { DATERANGE } from "../../../../../Utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import './UserSession.css';
import { Button, Label, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area } from 'recharts';

export default class UserSession extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      rangeID: "1",
      toDateError: '',
      dateRangeData: [],
      minDate: '',
      fromDate: '',
      toDate: '',
      recordData: [],
      toDisable: true,
      result: DATERANGE[1],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
    this.handleRange = this.handleRange.bind(this);
  }

  handleFromDate(date) {
    let to = this.state.toDate;
    if (to === '') {
      let fromDate = moment(date).format('L');
      if (fromDate.length < 11) {
        this.setState({
          fromDate: date, toDisable: false
        });
      }
    }
    else {
      if (date < this.state.toDate) {
        let toDate = moment(date).format('L');
        if (toDate.length < 11) {
          this.setState({
            fromDate: date, toDisable: false, toDateError: ''
          });
        }
      }
      else {
        this.setState({ toDateError: 'Please select valid To Date', toDate: '' })
      }
    }
  }

  handleRange() {
    this.setState({ fromDate: '', toDate: '' });
  }

  handleToDate(date) {
    if (date >= this.state.fromDate) {
      let toDate = moment(date).format('L');
      if (toDate.length < 11) {
        this.setState({
          toDate: date, toDateError: ''
        });
      }
    }
    else {
      this.setState({ toDateError: 'Please select valid To Date' });
    }
  }

  resetForm(event) {
    this.form.reset();
    this.setState({ fromDate: '', toDate: '', toDateError: '',result: 'Last week(by day)' })
    this.componentDidMount();
  }

  handleSubmit(event, errors, values) {
    if ((this.state.fromDate !== "" && this.state.toDate === "")) {
      this.setState({ toDateError: 'Please select To Date' })
    }
    else {
      if (errors.length === 0) {
        this.setState({ isLoading: true });
        let result = '';
        if ((this.state.fromDate !== "" && this.state.toDate !== "")) {
          result = moment(this.state.fromDate).format("L") + ' to ' + moment(this.state.toDate).format("L");
        }
        else {
          result = values.range == '' ? '' : DATERANGE[parseInt(values.range)];
        }
        const data = {
          'rangeId': values.range == '' ? this.state.rangeId : parseInt(values.range),
          'startDate': this.state.fromDate,
          'endDate': this.state.toDate
        }
        this.getReport(data);
        this.setState({ result: result })

      }
    }
  }

  getReport(param) {
    if (param.startDate != "") {
      param.rangeId = '';
    }
    const data = {
      'DateRangeID': param.rangeId,
      'StartDate': param.startDate !== '' ? moment(param.startDate).format('YYYY-MM-DD') : '',
      'EndDate': param.endDate !== '' ? moment(param.endDate).format('YYYY-MM-DD') : ''
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'analysis/report/session/', data)
      .then(response => {
        if (response.status === 200) {
          let recordData = response.data.report;
          this.setState({ isLoading: false, recordData: recordData });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const error = err.response.data;
        }
        else {
          const error = "Something went wrong.";
        }

      });

  }

  getLookupValues() {

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'lookup/daterange')
      .then(res => {
        let dateRangeData = res.data.daterange;
        this.setState({ dateRangeData })

      })
      .catch((err) => {
        console.log(err);
      })


  }

  componentDidMount() {
    var today = new Date();
    let minDate = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate());

    this.setState({ minDate: minDate })
    this.getLookupValues();
    const data = {
      'rangeId': 1,
      'startDate': '',
      'endDate': ''
    }
    this.getReport(data);
  }



  render() {
    document.title = CONFIG.PAGE_TITLE + 'Analytics - Sessions';
    const { isLoading, dateRangeData, rangeID, fromDate, result, minDate, toDateError, toDisable, toDate, recordData } = this.state;

    return (
      <main className="">
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <div className="admin-dash-details">

          <AvForm onSubmit={this.handleSubmit.bind(this)} onReset={this.resetForm.bind(this)} ref={c => (this.form = c)}>
            <Row className="form-analytics-fields form-back-shadow">
              <Col md={3}>
                <AvField type="select" name="range" value={rangeID} onChange={this.handleRange.bind(this)}

                  label="Date Range:">
                  {dateRangeData ? dateRangeData.map(n => {
                    return (
                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                        disabled={n.ID === '' ? true : false}
                        value={n.ID}>
                        {n.Name}
                      </option>
                    );
                  }) : ''}
                </AvField>
              </Col>

              <Col md={3}>
                <Label>From Date:</Label>
                <DatePicker className="is-touched is-pristine av-valid form-control" selected={fromDate} onChange={this.handleFromDate} maxDate={new Date()} minDate={minDate} />
              </Col>

              <Col md={3}>
                <Label>To Date:</Label>
                <DatePicker className="is-touched is-pristine av-valid form-control" selected={toDate} onChange={this.handleToDate} maxDate={new Date()} minDate={minDate} disabled={toDisable} />
                <p className="analtics-error">{toDateError}</p>
              </Col>
              <Col md={3} className="btn-submit-search">
                <Button className="button-11s" type="submit">Submit</Button>
                <Button className="btn-reset" type="reset">Clear</Button>
              </Col>
            </Row>
          </AvForm>
          <p><span style={{ color: "#4c4c4c" }}>Showing result: </span>{result}</p>
          {recordData.length === 0 ? <h6 className="no-records-found-in-filter">No data found</h6> :
            <Col md={12} className="text-align-left remove-padd-search search-graph">
              <AreaChart
                width={1045}
                height={300}

                data={recordData}
                syncId="anyId"
                margin={{
                  top: 10, right: 20, left: -30, bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="TimePeriod" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="Count" stroke="#82ca9d" fill="#82ca9d" />
              </AreaChart>
            </Col>
          }

        </div>
      </main>
    );
  }
}
