import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { CONFIG } from '../../Utils/config';
import about_icon from './../../Assets/Img/about-us-icon.png';
import teamDefaultImg from './../../Assets/Img/ventor-list.png';
import "./TeamDetails.css";

const TeamDetails = () => {
    const [isLoading, setisLoading] = useState(false);
    const [teamMemberDts, setTeamMemberDts] = useState();

    let { teamID } = useParams();

    const aboutUsApi = async () => {
        setisLoading(true);
        await axios.get(`${CONFIG.API_URL}team/member/${teamID}`)
        .then((res) => {
            if(res.status === 200){
                setisLoading(false);
                setTeamMemberDts(res.data[0]);
            }
        })
        .catch((error) => {
            setisLoading(false);
            console.log(error);
        })    
    };

    useEffect(() => {
        aboutUsApi();
    }, []);

    const errorImg = (e) => {
        e.target.onerror = null; 
        e.target.src= teamDefaultImg;
    };

    document.title = CONFIG.PAGE_TITLE + (teamMemberDts?.FirstName ?? "Team") + " " + (teamMemberDts?.LastName ?? "Details");
    
    return (
        <Fragment>
            {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> :
            <div>
                <div className="vendor-profilelist addpadding-lr">
                    <Container-fluid>
                        <div className="item">
                            <img src={about_icon} className="" alt="" />
                            <div className="content">  <h2 className="header-space">Meet the Team<br /> <span><Link to="/">Home</Link> / <Link to='/aboutus'>Team</Link> / <span>{ (teamMemberDts?.FirstName ?? "") + " " + (teamMemberDts?.LastName ?? "") }</span></span></h2></div>
                        </div>
                    </Container-fluid>
                </div>
                <div className="team-det-cont">
                        <div className="row">
                            <div className="col-md-5">
                                <img src={`/TeamImages/${(teamMemberDts?.ImageName ?? "")}`} alt={(teamMemberDts?.FirstName ?? "") + ' ' +(teamMemberDts?.LastName ?? "")} onError={(e)=>{ errorImg(e) }} />
                            </div>
                            <div className="col-md-7">
                                <div className="member-name">{(teamMemberDts?.FirstName ?? "") + ' ' +(teamMemberDts?.LastName ?? "")}</div>
                                <div className="team-position">
                                    {teamMemberDts?.Title ? <>{teamMemberDts.Title} <br/></> : ""}
                                    {teamMemberDts?.PhoneNumber ? <>{teamMemberDts.PhoneNumber} <br/></> : ""}
                                    {teamMemberDts?.Email ? <>{teamMemberDts.Email} <br/></> : ""}
                                </div>
                                <div className="team-descr" dangerouslySetInnerHTML={{ __html: (teamMemberDts?.Description ?? "")}}></div>                                
                            </div>
                        </div>
                </div>
            </div> 
            }
        </Fragment>
    )
}

export default TeamDetails