import React, { Component } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG, PROPERTYSTATUS } from '../../Utils/config';
import axios from 'axios';
import './Home.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import feature_home from './../../Assets/Img/noimglist.png';
import banner_src from './../../Assets/Img/logo-white.png';
import address_f from './../../Assets/Img/location-black.png';
import arrow_f from './../../Assets/Img/arrow.png';
import base64 from 'react-native-base64'
import { getFormattedInt, PRICEOPTION } from "../../Utils/utils";
import { Carousel } from 'react-responsive-carousel';
import CurrencyFormat from 'react-currency-format';
import plus_icon from './../../Assets/Img/icon-plus.png';
import area from './../../Assets/Img/area.png';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      featuredListings: [],
      propertyNameList: [],
      userInput: undefined,
      showPropertyNameList: false,
      page: 1,
      pageLength: 4,
      pagination: {},
      hasMore: false,
      showLoadMore: false,
      message: ''
    };
    this.getFeaturedListings = this.getFeaturedListings.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onKeyFocus = this.onKeyFocus.bind(this);

  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getFeaturedListings();
  }


  getFeaturedListings() {
    const { page, pageLength, featuredListings } = this.state;
    let featuredListingsL = featuredListings;
    var showLoadMore = false;
    const data = {
      "Page": page,
      "PageLength": pageLength
    }

    this.setState({ isLoading: true });
    axios.post(CONFIG.API_URL + 'home/featuredlistings', data).then(response => {
      if (response.status === 200) {
        featuredListingsL = featuredListingsL.concat(response.data.Store)
        var pagination = response.data.Pagination;
        // if (featuredListingsL.length >= (pageLength + pageLength)) {
        //   showLoadMore = false;
        // }
        // else if (pagination.TotalRecords > pageLength) {
        //   showLoadMore = true;
        // }
        // this.setState({ featuredListings: featuredListingsL, isLoading: false, pagination: pagination, showLoadMore });
        this.setState({ featuredListings: featuredListingsL, isLoading: false, pagination: pagination, hasMore: true });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  onSearchClick() {
    const { userInput } = this.state;

    const { from } = { from: { pathname: "/property/search/" + encodeURIComponent(userInput) } };
    this.props.history.push(from);
  }

  onKeyFocus() {
    const { showPropertyNameList } = this.state;
    this.setState({ showPropertyNameList: !showPropertyNameList });
  }


  handleKeyChange(e) {
    const userInput = e.currentTarget.value;

    this.setState({ userInput });
    if (userInput.length >= 2) {
      const data = {
        "Keyword": userInput,
        "StoreTypeID": null,
        "StateID": null,
        "PriceStart": null,
        "PriceEnd": null,
        "RentableSQFTStart": null,
        "RentableSQFTEnd": null,
        "City": null,
        "SortBy": null,
        "SortOrder": null,

      };
      axios.post(CONFIG.API_URL + 'home/property/search', data).then(response => {
        if (response.status === 200) {
          this.setState({ propertyNameList: response.data, showPropertyNameList: true });
        }
      })
        .catch(err => {
          this.setState({ isLoading: false });
        });
    }
  }

  loadMoreListings() {
    const { page } = this.state;
    this.setState({ hasMore: true }, this.getData(page + 1));
  }

  getData(nextpage) {
    const { pagination } = this.state;
    if (nextpage <= pagination.TotalPages) {
      this.setState({ page: nextpage },
        () => this.getFeaturedListings());
    } else {
      this.setState({ hasMore: false, message: 'End of Listings.' });
      return;
    }

  }

  render() {
    document.title = CONFIG.PAGE_TITLE + 'Home';
    const { isLoading, featuredListings, userInput, showPropertyNameList, propertyNameList, hasMore, page, showLoadMore, message } = this.state;


    return (
      <React.Fragment>
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <InfiniteScroll className="overflow-hidden"
          dataLength={featuredListings.length}
          next={() => this.getData(page + 1)}
          hasMore={hasMore}
          loader={<Col><Col className="padding-addleftright"><h6>Loading...</h6></Col></Col>}
        // endMessage={
        //   <p style={{ textAlign: "center", margin: "5px 0 20px 0" }}>
        //     <b>{message}</b>
        //   </p>
        // }
        >
          <div class="banner-sektion banner-overlay ">
            <Container-fluid>
              <div class="banner-heading ">
                <img src={banner_src} className="" alt="" />
                <h2>Data Driven. Value Focused.</h2>
                {/* <h4>Search Properties</h4> */}
                {/* <div><span className="heading-broder"></span></div> */}
                <div class="header-form">
                  <form>
                    <div class="form-box">
                      <React.Fragment>
                        <input type="text"
                          onChange={this.handleKeyChange}
                          onClick={this.onKeyFocus}
                          value={userInput} class="search-field business user-signin" placeholder="Enter Location, City or State" />

                        {showPropertyNameList && userInput ?
                          <React.Fragment>
                            <ul class="suggestions">
                              {propertyNameList.length > 1 ?
                                <React.Fragment>
                                  {propertyNameList.map((property, index) => {
                                    if (index === 0) {
                                      // return (
                                      //   <li key={property.StoreID}>
                                      //     <span className="view-all"><Link onClick={this.onSearchClick}> {property.StoreName}</Link></span>
                                      //   </li>);
                                    } else {
                                      return (
                                        <li key={property.StoreID}>
                                          <span className="view-list-all"> <Link to={"/property/" + property.StoreID + "/" + encodeURIComponent(property.StoreName)} >{property.StoreName + " - " + property.City + ", " + property.State}</Link></span>
                                        </li>
                                      );
                                    }

                                  })}
                                </React.Fragment>
                                : <li class="no-suggestions">
                                  <em><h6 className="no-records-found">No records found</h6></em>
                                </li>
                              }
                            </ul>

                          </React.Fragment>
                          : ''}
                      </React.Fragment>
                      <button class="search-btn" type="submit" disabled={propertyNameList.length <= 1}
                        onClick={this.onSearchClick}>Property Search</button>
                    </div>
                  </form>
                </div>
              </div>
            </Container-fluid>
          </div>
          <div className="home-featured page-height-fixed">
            <Row>
              <div className="width-100">
                <div className="heading">
                  <h3>Current Listings</h3>
                  {/* <div><span className="heading-broder"></span></div> */}
                </div>
              </div>

            </Row>
            <Row>
              {featuredListings.map(stores => {
                return (

                  <div className="feature-box width-25">
                    <div className="feature-home">
                      <div className="featured-offers-container">
                        <div className="featured-offer-col">
                          <div className="featured-offer-front">

                            <div className="featured-offer-photo">
                              <Link to={"/property/" + stores.StoreID + "/" + encodeURIComponent(stores.StoreName)}>{stores.StoreImages.length === 0 ? <img src={feature_home} alt="" className="width-292" />
                                : <div className="size img-slider-size">
                                  <Carousel showThumbs={false} autoPlay={true} interval="3000">
                                    {stores.StoreImages.map((image) =>
                                      <div className="img-resize">
                                        <img src={'/StoreImages/' + image.FileName} onError={(e) => { e.target.onerror = null; e.target.src = feature_home }} />
                                      </div>
                                    )}
                                  </Carousel>
                                </div>
                              }  </Link>
                              <div class="type-container">
                                {stores.StatusID == PROPERTYSTATUS.DueDiligence ? <div class="estate-type">Under Contract</div> :
                                  stores.StatusID == PROPERTYSTATUS.Available ? <div class="transaction-type">For Sale</div> :
                                   stores.StatusID == PROPERTYSTATUS.UnderLOI ? <div class="underloi-type">Under LOI</div> :
                                    <div class="sold-type">Closed</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="feature-details">
                          <h5 title={stores.StoreName}><Link to={"/property/" + stores.StoreID + "/" + encodeURIComponent(stores.StoreName)}>{stores.StoreName}</Link></h5>
                          <ul className="listitem">
                            <li>
                              <div className="item">
                              <img src={address_f} alt="" />
                                <div class="content"> {stores.Address1 + ((stores.Address2 != null && stores.Address2 != '') ? (', ' + stores.Address2) : '')}<br /> {stores.City}, {stores.State} {stores.ZipCode}</div>
                              </div>
                            </li>
                          </ul>
                          <p> <img src={area} alt="" className="areaiconsize" /> {stores.RentableSQFT ? getFormattedInt(stores.RentableSQFT) + " sq. ft" : stores.Acerage + " Acre(s)"}</p>
                        </div>
                        <div className={stores.StatusID != PROPERTYSTATUS.Sold ? "featured-offer-params" : "featured-offer-params full-width"}>
                          <div class="featured-area"> <p><Link to={"/property/" + stores.StoreID + "/" + encodeURIComponent(stores.StoreName)}>Read More</Link></p></div>
                        </div>
                        {stores.StatusID != PROPERTYSTATUS.Sold ?
                         <>{stores.AlternatePriceOptionID !== null ? <div className={stores.AlternatePriceOptionID === 3 ? "featured-price price-market" :"featured-price"}>{stores.AlternatePriceOptionName} {stores.AlternatePriceOptionID === PRICEOPTION.Auction && stores.AlternatePriceOptionDate !== null ? ' : ' + moment(stores.AlternatePriceOptionDate).format("L") :''}</div>:<div className="featured-price">
                          ${getFormattedInt(stores.Price)}
                        </div>}</>: null} 
                      </div>
                    </div>
                  </div>
                )
              })}
            </Row>
            {/* <Row>
            <Col sm={10}>
            </Col>
            <Col sm={2}>
              {showLoadMore ? <Typography className="text-align-right-home" onClick={() => this.loadMoreListings()} ><img src={plus_icon} alt="" className="img-plus-more" /> More Properties</Typography> : ''}
            </Col>
          </Row> */}
          </div>
        </InfiniteScroll>
      </React.Fragment >
    )
  }
}
