import React, { Component } from 'react';
import { CONFIG } from '../../../../Utils/config';
import { Link } from "react-router-dom";
import './BuyerServices.css';
import logo1 from '../../../../Assets/Img/industry.png';
import logo2 from '../../../../Assets/Img/Relationships.png';
import logo3 from '../../../../Assets/Img/Maximum-Transparency.png';
import logo4 from '../../../../Assets/Img/Storage-Facility.png';
import logo5 from '../../../../Assets/Img/Qualified.png';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Form, FormText } from 'reactstrap';

export default class BuyerServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }        

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Buyer Services';
        const { isLoading } = this.state;

        return (
            <main className="page-height-fixed">
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="vendor-profilelist">
                    <Container-fluid>
                        <div className="item">
                           
                            <div class="content">  <h2> Buyer Services<br /> <span><Link to="/">Home</Link> / <Link to=''>Buyer Services</Link></span></h2></div>
                        </div>
                    </Container-fluid>
                </div>
                <div className="buyerservice-se">
                    <Row>
                        <Col>
                            <h3 className="buyerheading">As a value focused real estate brokerage platform, Storage Exchange leverages the power of empirical data to create maximum investment visibility and efficiency. Through unparalleled experience in self-storage ownership and management, Storage Exchange is uniquely qualified to understand the subtle nuances of each transaction to unlock value for all clients.</h3>
                            <p className="buyerpara">Our extensive background in self-storage acquisitions and operations ensures thorough underwriting and proprietary data utilization to assess every investment opportunity with an emphasis on transparency and value creation.  Our team’s vast experience within the capital markets makes Storage Exchange particularly adept at serving the needs of existing operators, private equity, family offices, high-net worth individuals, and 1031 buyers.</p>
                        </Col>
                    </Row>
                </div>
                 <div className="buyerservicespage">
                    <Container-fluid>
                        <div className="item">
                            <div class="content">  <h2> OUR APPROACH</h2></div>
                        </div>
                        <Col className="buyer-approach">
                        <p>Over the last decade, the storage industry has experienced an unprecedented influx of both public and private capital. During this same period, data aggregation has helped bring an end to the era of data asymmetry between buyers and sellers.</p>
                        <p>At Storage Exchange, we aim to filter out the noise created by unproven buyers and subjective sell-side broker data. By delivering highly vetted investment opportunities to the most qualified investors with the best available operator-centric data, we facilitate high probability, high efficiency transactions that deliver maximum value to buyer and seller.</p>
                        </Col>
                    </Container-fluid>
                </div>
                <div className="buyerservice-se">
                    <Row>
                    <div className="card width-30">
                       <div className="box">
                           <div className="img">
                             <img src={logo1} alt="" />
                           </div>
                           <h2>Industry Experience</h2>
                           <p>As experienced storage industry owners & operators, we are uniquely qualified to provide operational and management expertise to facilitate smart transactions focused on both buyer and seller needs.</p>
                       </div>
                    </div>
                    <div className="card width-30">
                       <div className="box">
                           <div className="img">
                             <img src={logo2} alt="" />
                           </div>
                           <h2>Capital Relationships</h2>
                           <p>We work closely with a small pool of highly qualified buyers, including private equity, family office, pension + wealth funds, to bring capital directly to sellers.</p>
                       </div>
                    </div>
                    <div className="card width-30">
                       <div className="box">
                           <div className="img">
                             <img src={logo3} alt="" />
                           </div>
                           <h2>Maximum Transparency</h2>
                           <p>We believe transparency leads to transaction efficiency. To that end, we leverage the power of objective data and filter every transaction through the lens of a true storage operator.</p>
                       </div>
                    </div>
                    </Row>
                </div>
                <div className="buyerservicespage2">
                    <Container-fluid>
                        <div className="item">
                            <div class="content">  <h2> WORK WITH US</h2></div>
                        </div>
                        <Col className="buyer-approach">
                        <p>By utilizing off-market lead generation, operator underwriting, and proprietary market research, our team of self-storage investment professionals supports capital deployment needs for private equity, family offices, sovereign wealth, pension funds, existing owner/operators, and 1031 buyers. To learn more about how Storage Exchange can support your self-storage investment needs, <Link to="/contactus" className="color-link">please get in touch.</Link></p>
                        </Col>
                    </Container-fluid>
                </div>
                <div className="buyerservice-se">
                    <Row>
                    <div className="card width-40">
                       <div className="box">
                           <div className="img img-height-change">
                             <img src={logo4} alt="" />
                           </div>
                           <h2>Storage Facility Owners & Operators</h2>
                           <p>Existing Owners & Operators in the self storage industry who are looking to expand or sell their portfolio through an off-market acquisitions strategy.</p>
                       </div>
                       <div class="row margin-center">
	                      <Link className="btn btn-primary slide_right_alt" to="/contactus" role="button">Expand Your Portfolio</Link>
                        </div>
                    </div>
                    <div className="card width-40">
                       <div className="box">
                           <div className="img img-height-change">
                             <img src={logo5} alt="" />
                           </div>
                           <h2>Qualified Capital & Individuals</h2>
                           <p>Private Equity, Family Office, Pension + Wealth Funds who are looking to make  investments in the self-storage industry marketplace.</p>
                       </div>
                       <div class="row margin-center">
	                      <Link className="btn btn-primary slide_right_alt" to="/contactus" role="button">Make an Investment</Link>
                        </div>
                    </div>
                    </Row>
                </div>
            </main>
        );
    }
}