import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG, USERTYPES } from '../../Utils/config';
import rightarw from './../../Assets/Img/right-arw.png';
import axios from 'axios';
import './Signin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

export default class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            signinError: '',
            storeID: this.props.match.params.storeID,
            storeName: decodeURIComponent(this.props.match.params.StoreName),
        };
    }

    handleSubmit(event, errors, values) {
        if (errors.length === 0) {
            this.setState({ isLoading: true });
            let storeID = this.state.storeID;
            let storeName = this.state.storeName;
            const loginIp = {
                'UserName': values.UserName,
                'Password': values.Password,
                BuyerStore: {
                    "StoreID": storeID,
                    "DealName": storeName,
                }
                // 'UserTypeId': values.UserTypeID
            }

            axios.post(CONFIG.API_URL + 'Account/login/', loginIp)
                .then(response => {

                    if (response.status === 200) {

                        if (response.data.IsConfirmed) {
                            const getTokvalues = response.data.Token;
                            let defaultUser = response.data.DefaultRole;

                            localStorage.setItem('accessKey', response.data.Token);
                            localStorage.setItem('id', response.data.Id);
                            localStorage.setItem('name', response.data.FirstName + (response.data.LastName != null ? ' ' + response.data.LastName : ''));
                            localStorage.setItem('email', response.data.Email);

                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.Token;

                            if (defaultUser[0].RoleId === USERTYPES.Admin) {
                                localStorage.setItem('role', response.data.Role[0]);

                                const { from } = { from: { pathname: "/admin/dashboard/1" } };
                                this.props.history.push(from);
                            }
                            else {
                                localStorage.setItem('sessionId', response.data.SessionId);

                                let buyerDefault = defaultUser[0].IsDefault === true ? true : false;
                                let sellerDefault = defaultUser[1].IsDefault === true ? true : false;

                                localStorage.setItem('buyerDefault', buyerDefault);
                                localStorage.setItem('sellerDefault', sellerDefault);


                                if (sellerDefault === true && buyerDefault === false) {
                                    localStorage.setItem('role', response.data.Role[1]);
                                    const { from } = { from: { pathname: "/seller/dashboard" } };
                                    this.props.history.push(from);
                                }
                                else if (buyerDefault === true && sellerDefault === false) {
                                    localStorage.setItem('role', response.data.Role[0]);
                                    const { from } = { from: { pathname: "/buyer/dashboard" } };
                                    this.props.history.push(from);
                                }
                                else {
                                    localStorage.setItem('role', response.data.Role[0]);
                                    const { from } = { from: { pathname: "/buyer/dashboard" } };
                                    this.props.history.push(from);
                                }
                            }
                        } else {
                            const { from } = { from: { pathname: "/resetpassword/false/" + encodeURIComponent(response.data.PasswordResetToken) } };
                            this.props.history.push(from);
                        }
                    }
                })
                .catch(err => {

                    this.setState({ isLoading: false });
                    if (err.response != null && err.response.status === 400) {
                        var signinError = err.response.data;
                        if (signinError == "INACTIVE-USER") {
                            signinError = "Your account is inactive. To reactivate, please contact us at 720-310-0113 or info@storageexchange.com.";
                        }
                        this.setState({ signinError });
                    }
                    else {
                        const signinError = "Something went wrong.";
                        this.setState({ signinError });
                    }

                });
        }
    }

    goToRegister() {
        const { from } = { from: { pathname: "/buyer-registration/" } };
        this.props.history.push(from);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Sign In';
        const { isLoading, signinError } = this.state;
        return (
            <div className="page-height-fixed">
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <Row>
                    <Col>
                        <div className="sign-in">
                            <div className="heading">
                                <h3>Account Login</h3>
                                {/* <div><span className="heading-broder"></span></div> */}
                            </div>
                            <Row>
                                <div className="wrapper">
                                    <div className="wrapper-content">
                                        <div>
                                            <h4>User Login</h4>
                                            {/* <span className="left-border"></span> */}
                                        </div>
                                        <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.form = c)}>
                                            <AvField name="UserName" label="" type="email" className="user-signin" placeholder="Email" validate={{
                                                required: { value: true, errorMessage: 'Email is required' }
                                            }} />
                                            <AvField name="Password" label="" type="password" className="pass-signin" placeholder="Password" validate={{
                                                required: { value: true, errorMessage: 'Password is required' }
                                            }} />
                                            {/* <AvRadioGroup inline name="UserTypeID" className="sign-option" label="" required>
                                                <AvRadio label="Buyer" value="2" />
                                                <AvRadio label="Seller" value="3" />
                                            </AvRadioGroup> */}
                                            <Button color="primary" className="sign-btn">Login</Button>
                                            <Link to="/forgotpassword" className="forgot-right"><FontAwesomeIcon icon={faLongArrowAltRight} /> Forgot Password?</Link>
                                            <div className="crt-sign"><Link onClick={this.goToRegister.bind(this)} className="sign-create">Create an Account</Link></div>
                                            <span className="sign-error error-login-signin">{signinError}</span>
                                        </AvForm>
                                        
                                    </div>

                                </div>

                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );

    }
}
