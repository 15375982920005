import React, { Component } from 'react';
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from '../../../../Utils/config';
import { normalizeInput } from '../../../../Utils/utils';
import { Link } from "react-router-dom";
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './AdminView.css';
import { Pagination } from '../../../Shared/Pagination';

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

const AdminViewTableWrapped = withStyles(actionsStyles, { withTheme: true })(
  Pagination,
);

export default class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModal: false,
      userDetail: [],
      pageCount: 0,
      userList: [],
      userData: [],
      userType: 1,
      stateList: [],
      stateId: 0,
      searchStateId: 0,
      positionList: [],
      positionId: 0,
      AgentLocationID: 0,
      position: '',
      showPosition: false,
      locations: [],
      statusList: [
        {
          "id": -1,
          "name": "All"
        },
        {
          "id": 2,
          "name": "Approved"
        },
        {
          "id": 3,
          "name": "Rejected"
        }
      ],
      isLoading: false,
      phone: ''
    }
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    //  this.viewAdmin = this.viewAdmin.bind(this);

  }
  handlePhoneChange({ target: { value } }) {
    this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
  };

  stateChange(e) {
    var value = e.target.value;
    this.setState({ stateId: value })
  }

  addtoggleModal() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.get(CONFIG.API_URL + 'lookup/state')
      .then(res => {
        let stateList = res.data;
        this.setState(state => ({ addModal: !state.addModal, stateList, userData: [] }))

      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      });

    this.getPositionValue();
    this.getLocationValue();
  }

  getPositionValue() {
    axios.get(CONFIG.API_URL + 'lookup/user/position')
      .then(res => {
        let positionList = res.data;
        this.setState({ positionList });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLocationValue() {
    axios.get(CONFIG.API_URL + 'lookup/agent/locations')
      .then(res => {
        let locations = res.data;
        this.setState(state => ({ locations }));
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
  }

  toggleClose = () => this.setState(state => ({ addModal: false, phone: '', positionId: '', AgentLocationID: '' }))

  createUser(event, errors, values) {
    if (errors.length > 0) {
      console.log(errors);
    }
    else {
      if ((values.position == null || values.position.trim() === '') && (this.state.positionId === "5" || this.state.positionId === 5)) {
        alert("Title is required");
        errors.push('position');
      }
      if (errors.length > 0) {
        console.log(errors);
      }
      else {
        this.setState({ isLoading: true });
        if (values.ID == "") {
          const data = {
            "FirstName": values.FirstName,
            "LastName": values.LastName,
            "Email": values.Email,
            "PhoneNumber": values.PhoneNumber,
            'PositionID': values.positionId,
            'CompanyName': values.CompanyName,
            "UserTypeID": this.state.userType,
            "Address1": '',
            "Address2": '',
            "City": '',
            "State": '',
            "ZipCode": '',
            // "StateId": 0,
            "IsConfirmed": 1,
            "IsActive": 1,
            "StatusID": 2,
            "Position": values.position,
            "AgentLocationId": values.AgentLocationID
          }
          let token = localStorage.getItem('accessKey');
          if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
          }
          axios.post(CONFIG.API_URL + 'account/register/', data)
            .then(res => {

              this.myFormRef && this.myFormRef.reset();
              this.state.userList.Criteria.Page = 1;
              this.setState({ isLoading: false, pageCount: 0 });
              this.getDetails(null);
              this.toggleClose();

            })
            .catch((err) => {
              this.setState({ isLoading: false });
              console.log(err);
              alert(err.response.data);

            })
        }
        else {
          const data = {
            "ID": parseInt(values.ID),
            "FirstName": values.FirstName,
            "LastName": values.LastName,
            "Email": values.Email,
            "UserTypeID": this.state.userData.UserTypeID,
            "PhoneNumber": values.PhoneNumber,
            'PositionID': values.positionId,
            'AgentLocationId': values.AgentLocationID,
            'CompanyName': values.CompanyName,
            'Position': values.position
          }
          let token = localStorage.getItem('accessKey');
          if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
          }
          axios.post(CONFIG.API_URL + 'admin/user/edit/', data)
            .then(res => {
              this.myFormRef && this.myFormRef.reset();
              this.setState({ isLoading: false });
              this.getDetails(null);
              this.toggleClose();
            })
            .catch((err) => {
              console.log(err);
              this.setState({ isLoading: false });
              alert(err.response.data);
            })
        }
      }
    }
  }

  getDetails(params) {
    this.setState({ isLoading: true })
    if (params == null) {
      params = this.state.userList.Criteria;
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'admin/users', params)
      .then(res => {
        let users = res.data;
        this.setState({ userList: users, isLoading: false });

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);

      })
  }
  deleteAdmin(id, adminId) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'admin/user/status/' + id + '/' + adminId)
      .then(res => {
        this.getDetails(null);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  deleteUser(id) {
    confirmAlert({
      title: 'Delete User',
      message: 'Are you sure want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true })
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.delete(CONFIG.API_URL + 'admin/user/' + id)
              .then(res => {
                this.setState({ isLoading: false })
                this.getDetails(null);
              })
              .catch((err) => {
                this.setState({ isLoading: false })
                console.log(err);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  restoreUser(id) {

    confirmAlert({
      title: 'Delete User',
      message: 'Are you sure want to restore this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true })
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.request({
              url: CONFIG.API_URL + 'admin/user/' + id,
              method: 'put',
            })
              .then(res => {
                this.setState({ isLoading: false })
                this.getDetails(null);

              })
              .catch(err => {
                this.setState({ isLoading: false })
                console.log(err);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });

  }

  viewAdmin(adminId) {
    this.getPositionValue();
    this.getLocationValue();

    let userId = adminId;
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/users/' + userId)
      .then(res => {
        let userData = res.data.User;
        let phone = userData.PhoneNumber;
        let positionId = userData.PositionID;
        let AgentLocationID = userData.AgentLocationId;
        let position = userData.Position;
        let showPosition = positionId === "5" || positionId === 5 ? true : false;

        // let firstName = userData.FirstName;
        // let lastName = userData.LastName;
        // let phone = userData.PhoneNumber;
        // let statusRejected;
        // let statusApproved;

        // if (userData.IsActive == true) {
        //     statusRejected = false;
        //     statusApproved = true;
        // }
        // else {
        //     statusApproved = true;
        //     statusRejected = false;
        // }

        let userEmail = userData.Email.toLowerCase();
        let sellerData = res.data.SellerStores;
        let stateList = res.data.States;

        this.setState({ userData, positionId: positionId, AgentLocationID: AgentLocationID, addModal: true, stateList, phone, position, showPosition });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  search(event, errors, values) {
    // let userData = this.state.userData;
    // if (values.Status == "0") {
    //   userData = userData.filter(i => i.IsConfirmed === false && i.IsActive === true)
    //   console.log(userData);
    // }
    // else if (values.Status == "1") {
    //   userData = userData.filter(i => i.IsConfirmed === true && i.IsActive === true)
    // }
    // else if (values.Status == "2") {
    //   userData = userData.filter(i => i.IsConfirmed === false && i.IsActive === false)
    // }
    // this.setState({ userData: userData });
    const params = {
      'Name': values.Name,
      'Status': values.Status,
      'UserType': this.state.userType,
      'PageLength': CONFIG.PAGE_LENGTH,
      'Page': 1
    }
    this.setState({ pageCount: 0 });
    this.getDetails(params);
  }

  componentDidMount() {
    const params = {
      'Name': '',
      'Status': -1,
      'UserType': this.state.userType,
      'PageLength': CONFIG.PAGE_LENGTH,
      'Page': 1
    }

    this.getDetails(params);
  }

  resetSearch() {
    this.myFormSearch && this.myFormSearch.reset();
    const params = {
      'Name': '',
      'Status': -1,
      'UserType': this.state.userType,
      'PageLength': CONFIG.PAGE_LENGTH,
      'Page': 1
    }
    this.setState({ pageCount: 0 });
    this.getDetails(params);
  }

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
    this.state.userList.Criteria.Page = pageCount + 1;
    this.getDetails(null);

  }
  handleChangeLocation(e) {
    this.setState({ value: e.target.value });
  }

  handleChange(e) {
    if (e.target.name === 'positionId') {
      var position = '';
      var showPosition = e.target.value === "5" ? true : false;
      var userData = this.state.userData;
      if (userData) {
        userData.Position = position;
      }
      this.setState({ [e.target.name]: e.target.value, position, showPosition, userData });
    }
    else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  render() {
    document.title = CONFIG.PAGE_TITLE + 'User Management - Admin Search';
    const { pageCount, userList, statusList, stateList, userData, isLoading, positionList, positionId, position, showPosition, locations, AgentLocationID } = this.state;
    return (
      <div className="admin-dash-details">
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <AvForm onSubmit={this.search.bind(this)} ref={c => (this.myFormSearch = c)} className="form-back-shadow">
          <Row>
            <Col md={4}>
              <AvField name="Name" label="Name:" />
            </Col>
            <Col md={4}>
              <AvField type="select" name="Status" label="Status:" helpMessage="" value="-1">
                {statusList ? statusList.map((c) =>
                  <option key={c.id} value={c.id}>{c.name}</option>
                ) : ''}
              </AvField>
            </Col>
            <Col md={4} className="btn-search">
              <Button id="btn" color="primary" className="search-butn">Search</Button>
              <Button onClick={this.resetSearch.bind(this)} className="btn-reset">Reset</Button>
            </Col>
          </Row>
        </AvForm>

        <Row className="padding-col">
          <Col md={6} className="total-numb">
            {userList.Users && userList.Users.length > 0 ? <p>Total Admins: {userList.Pagination.TotalRecords}</p> : ''}
          </Col>
          <Col md={6} className="add-new-btn">
            <Link onClick={this.addtoggleModal.bind(this)}>[+] Add New Admin</Link>
          </Col>
        </Row>

        <div className="table-model">
          {userList.Users && userList.Users.length > 0 ?
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.Users ? userList.Users.map((admin) =>
                  <TableRow key={admin.ID}>
                    <TableCell>{moment(admin.CreatedDate).format("L")}</TableCell>
                    <TableCell>{admin.FirstName} {admin.LastName}</TableCell>
                    <TableCell>{admin.Email}</TableCell>
                    <TableCell>
                      <Button type="edit" className="button-common background-edit" onClick={this.viewAdmin.bind(this, admin.ID)}>Edit</Button>
                      {/* {admin.IsActive == true ?
                        <Button type="delete" className="no-button-background" onClick={this.deleteUser.bind(this, admin.ID)}><img src={delete_icon} alt="delete" title="Delete" /></Button> :
                        <Button type="restore" className="no-button-background" onClick={this.restoreUser.bind(this, admin.ID)}><img src={restore_icon} alt="restore" title="Restore" /></Button>
                      } */}

                      {admin.StatusID == 2 ?
                        <Button type="delete" className="button-common background-delete" onClick={this.deleteUser.bind(this, admin.ID)}>delete</Button> : admin.StatusID == 3 ?
                          <Button type="restore" className="button-common" onClick={this.restoreUser.bind(this, admin.ID)}>Restore</Button> : ''
                      }
                    </TableCell>
                  </TableRow>
                ) :
                  <TableRow></TableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[1]}
                    colSpan={5}
                    count={userList.Users ? userList.Pagination.TotalRecords : 0}
                    rowsPerPage={CONFIG.PAGE_LENGTH}
                    page={pageCount}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handleChangePageTrack}
                    onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                    ActionsComponent={AdminViewTableWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            : <h6 className="no-records-found">No records found</h6>}
        </div>
        <div>
          <Modal size="md" isOpen={this.state.addModal} toggle={this.toggleClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
            <ModalHeader toggle={this.toggleClose.bind(this)}>{userData && userData.ID > 0 ? 'Update Admin' : 'Create Admin'}
            </ModalHeader>
            <ModalBody className="overflow-scroll basic-details">
              <AvForm onSubmit={this.createUser.bind(this)} ref={c => (this.myFormRef = c)}>
                <Row>
                  <Col md={6} className="hidden-field-admin">
                    <AvField type="hidden" name="ID" value={userData ? userData.ID : 0} />
                    <AvField name="FirstName" label="First Name:*" maxLength="255" type="text" value={userData.FirstName ? userData.FirstName : ''} validate={{
                      required: { value: true, errorMessage: 'First Name is required' }
                    }} />

                  </Col>
                  <Col md={6}>

                    <AvField name="LastName" label="Last Name:*" maxLength="255" value={userData.LastName ? userData.LastName : ''} validate={{
                      required: { value: true, errorMessage: 'Last Name is required' }
                    }} />

                  </Col>
                  <Col md={6}>

                    <AvField name="Email" label="User Email:*" type="email" maxLength="256" value={userData.Username ? userData.Username : ''} validate={{
                      required: { value: true, errorMessage: 'Email is required' }
                    }} />
                  </Col>
                  <Col md={6}>
                    <AvField name="PhoneNumber" label="Phone Number:*" placeholder="xxx-xxx-xxxx" type="text"
                      validate={{
                        required: { value: true, errorMessage: 'Phone Number is required' },
                        pattern: { value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, errorMessage: 'Phone Number is invalid' }
                      }}
                      value={this.state.phone}
                      onChange={(e) => this.handlePhoneChange(e)}
                    />
                  </Col>
                  <Col md={6}>
                    <AvField name="CompanyName" label="Company Name:*" placeholder="" type="text" maxLength="1000" value={userData.CompanyName ? userData.CompanyName : ''} validate={{
                      required: { value: true, errorMessage: 'Company Name is required' }
                    }} />
                  </Col>
                  <Col md={6}>
                    <AvField type="select" name="positionId" value={positionId}
                      onChange={(e) => this.handleChange(e)}
                      label="Title:*"
                      validate={{
                        required: { value: true, errorMessage: 'Title is required' },
                      }}>
                      <option value="0">--Select--</option>
                      {positionList ? positionList.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  <Col md={6}>
                    <AvField type="select" name="AgentLocationID" value={AgentLocationID}
                      onChange={(e) => this.handleChangeLocation(e)}
                      label="Location:*"
                      validate={{
                        required: { value: true, errorMessage: 'Location is required' },
                      }}>
                      <option value="0">--Select--</option>
                      {locations ? locations.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>

                  {
                    showPosition ?
                      <Col md={6}>
                        <AvField name="position" label="Title:*" placeholder="" type="text" maxLength="100" value={userData.Position ? userData.Position : ''} validate={{
                          required: { value: true, errorMessage: 'Title is required' }
                        }} />
                      </Col>
                      : ''}
                </Row>

                <Row className="save-right">
                  <Col md={12}>
                    <Button id="btn" className="next-btn submit-btn btn-design">Save</Button>
                    <Button className="btn-reset" onClick={this.toggleClose.bind(this)}>cancel</Button>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>

      </div>
    );
  }
}
