import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Form, FormText } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG, CONTACT } from '../../Utils/config';
import { formatPhoneNumber } from '../../Utils/utils';
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Label, Input } from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import contact_icon from './../../Assets/Img/contact-us.png';
import address_icon from './../../Assets/Img/location-black.png';
import phone_icon from './../../Assets/Img/phone-details.png';
import mail_icon from './../../Assets/Img/mail-details.png';
import './ContactUs.css';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            optionName: '',
            fieldValid: { phone: false, email: false },
            formErrors: { first_name: true, last_name: true, phone: true, company: true, email: true, option: true, message: true },
            isTouched: { first_name: true, last_name: true, phone: true, company: true, email: true, option: true, message: true },
            formValue: { first_name: '', last_name: '', phone: '', company: '', email: '', option: '', message: '', street: '', city: '', state: '', zip: '' },
        };
        this.btnRef = React.createRef();
        // this.contactUsRequest = this.contactUsRequest.bind(this);
        // this.optionChange = this.optionChange.bind(this);
        // this.resetForm = this.resetForm.bind(this);
        // this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    // handlePhoneChange({ target: { value } }) {
    //     this.setState(prevState => ({ phone: normalizeInput(value, prevState.phone) }));
    // };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    resetForm = () => {
        this.setState({
            fieldValid: { phone: false, email: false },
            formErrors: { first_name: true, last_name: true, phone: true, company: true, email: true, option: true, message: true },
            isTouched: { first_name: true, last_name: true, phone: true, company: true, email: true, option: true, message: true },
            formValue: { first_name: '', last_name: '', phone: '', company: '', email: '', option: '', message: '', street: '', city: '', state: '', zip: '' }
        });
    }

    // optionChange(e) {
    //     var value = e.target.value;
    //     this.setState({ optionName: value })
    // }

    // resetForm() {
    //     this.myFormRef && this.myFormRef.reset();
    // }

    // contactUsRequest(event, errors, values) {
    //     this.setState({ error: '' });
    //     if (errors.length === 0) {
    //         const data = {
    //             'FirstName': values.FirstName,
    //             'LastName': values.LastName,
    //             'Company': values.Company,
    //             'Phone': values.Phone,
    //             'Email': values.Email,
    //             'Option': this.state.optionName,
    //             'Message': values.Message
    //         }

    //         axios.post(CONFIG.API_URL + 'home/contactus', data)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     if (response.data != "success") {
    //                         const error = response.data;
    //                         this.setState({ error, isLoading: false });
    //                     } else {
    //                         this.setState({ isLoading: false });
    //                         this.resetForm();
    //                         alert("Thank you for reaching out. One of our agents will be in touch shortly.");
    //                     }
    //                 }
    //             })
    //             .catch(err => {
    //                 this.setState({ isLoading: false });
    //                 if (err.response != null && err.response.status === 400) {
    //                     const error = err.response.data;
    //                     this.setState({ error });
    //                 }
    //                 else {
    //                     const error = "Something went wrong.";
    //                     this.setState({ error });
    //                 }
    //             });
    //     }
    // };

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.validateField(name, value);
    }

    blurHandler = (e) => {
        let fieldName = e.target.name;
        let value = e.target.value;
        let isTouched = this.state.isTouched;
        switch (fieldName) {
            case 'first_name':
                isTouched.first_name = value ? true : false;
                break;
            case 'last_name':
                isTouched.last_name = value ? true : false;
                break;
            case 'phone':
                isTouched.phone = value ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value) : false;
                break;
            case 'email':
                isTouched.email = value ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/.test(value) : false;
                break;
            case 'company':
                isTouched.company = value ? true : false;
                break;
            case '00NDn000007Ra56':
                isTouched.message = value ? true : false;
                break;
            case '00NDn000007Ra57':
                isTouched.option = value ? true : false;
                break;

            default:
                break;
        }
        this.setState({ isTouched }, this.validateField(fieldName, value));
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let fieldValid = this.state.fieldValid;
        let formValues = this.state.formValue;
        switch (fieldName) {
            case 'first_name':
                fieldValidationErrors.first_name = value ? true : false;
                formValues.first_name = value;
                break;
            case 'last_name':
                fieldValidationErrors.last_name = value ? true : false;
                formValues.last_name = value;
                break;
            case 'phone':
                let phoneInput = (formatPhoneNumber(value));
                fieldValidationErrors.phone = phoneInput ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phoneInput) : false;
                fieldValid.phone = phoneInput ? true : false;
                formValues.phone = phoneInput;
                break;
            case 'email':
                fieldValidationErrors.email = value ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/.test(value) : false;
                fieldValid.email = value ? true : false
                formValues.email = value;
                break;
            case 'company':
                fieldValidationErrors.company = value ? true : false;
                formValues.company = value;
                break;
            case '00NDn000007Ra56':
                fieldValidationErrors.message = value ? true : false;
                formValues.message = value;
                break;
            case '00NDn000007Ra57':
                fieldValidationErrors.option = value ? true : false;
                formValues.option = value;
                break;
            case 'street':
                formValues.street = value;
                break;
            case 'city':
                formValues.city = value;
                break;
            case 'state':
                formValues.state = value;
                break;
            case 'zip':
                formValues.zip = value;
                break;
            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors, formValue: formValues, fieldValid });
    }

    validateForm = () => {
        let formErrors = this.state.formErrors;
        let formValues = this.state.formValue;
        let valueArr = [formValues.first_name, formValues.last_name, formValues.company, formValues.email, formValues.phone, formValues.option, formValues.message];
        let errorArr = [formErrors.first_name, formErrors.last_name, formErrors.company, formErrors.email, formErrors.phone, formErrors.option, formErrors.message];

        let checkValue = valueArr.filter(Boolean).length;
        let checkError = errorArr.every(Boolean);
        if (checkError === true && checkValue == 7) {
            alert("Thank you for reaching out. One of our agents will be in touch shortly.");
            this.btnRef.current.click();
            this.resetForm();
        }
        else if (checkValue == 0) {
            this.setState({
                formErrors: { first_name: false, last_name: false, phone: false, company: false, email: false, option: false, message: false },
                isTouched: { first_name: false, last_name: false, phone: false, company: false, email: false, option: false, message: false },
            });
        }
        else {
            formErrors.first_name = formValues.first_name ? true : false;
            formErrors.last_name = formValues.last_name ? true : false;
            formErrors.company = formValues.company ? true : false;
            formErrors.phone = formValues.phone ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(formValues.phone) : false;
            formErrors.email = formValues.email ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/.test(formValues.email) : false;
            formErrors.message = formValues.message ? true : false;
            formErrors.option = formValues.option ? true : false;
            this.setState({
                formErrors,
                isTouched: formErrors
            });
        }
    }

    errorClass(error, isTouched) {
        return (((error === false) && (isTouched === false)) === true ? 'is-touched is-pristine av-invalid is-invalid form-control' : '');
    }

    render() {
        document.title = CONFIG.PAGE_TITLE + 'Contact Us';
        const { isLoading, formErrors, isTouched, formValue, fieldValid, error } = this.state;

        return (
            <div>
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
                <div className="vendor-profilelist addpadding-lr">
                    <Container-fluid>
                        <div className="item">
                            {/* <img src={contact_icon} className="" alt="" /> */}
                            <div class="content">  <h2>Contact Us<br /> <span><Link to="/">Home</Link> / <Link to=''>Contact Us</Link></span></h2></div>
                        </div>
                    </Container-fluid>
                </div>
                <div className="contactuspage page-height-fixed">
                    {/* <div className="heading">
                        <h3>Contact Us</h3>*/}
                    {/* <div><span className="heading-broder"></span></div> 
                    </div> */}
                    <Row>
                        <div className="width-20">
                            <div>
                                <h6 className="getintouch">Contact Info</h6>
                            </div>
                            <div className="contact-info">
                                <ul className="listitem">
                                    <li>
                                        <div className="item">
                                            <img src={address_icon} className="" alt="" />
                                            <div class="content">Storage Exchange Colorado Office: <br />{CONTACT.Address1}, {CONTACT.Address2}<br />Boulder, CO 80303</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <img src={address_icon} className="" alt="" />
                                            <div class="content">Storage Exchange California Office:<br />{CONTACT.Address3}, {CONTACT.Address4}<br />La Jolla, CA 92037</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <img src={mail_icon} className="" alt="" />
                                            <div class="content"><a href="mailto:info@storageexchange.com">info@storageexchange.com</a></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <img src={phone_icon} className="" alt="" />
                                            <div class="content">720-310-0113</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="width-80">
                            <div>
                                <h6 className="getintouch">Get in Touch</h6>
                            </div>
                            <div className="form-contact-box">
                                <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" >
                                    <Row>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <input type="hidden" name="oid" value="00D4P000001dDZq" />
                                                <input type="hidden" name="retURL" value="https://storageexchange.com/contactus" />
                                                <label for="first_name">First Name:*</label><input id="first_name" maxlength="40" name="first_name" size="20" value={formValue.first_name} onBlur={this.blurHandler} onChange={this.handleUserInput} type="text" className={`form-control ${this.errorClass(formErrors.first_name, isTouched.first_name)}`} />
                                                <div className='invalid-feedback'>First Name is required</div>
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="last_name">Last Name:*</label><input id="last_name" maxlength="80" name="last_name" size="20" type="text" value={formValue.last_name} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.last_name, isTouched.last_name)}`} />
                                                <div className='invalid-feedback'>Last Name is required</div>
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="company">Company:*</label><input id="company" maxlength="40" name="company" size="20" type="text" value={formValue.company} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.company, isTouched.company)}`} />
                                                <div className='invalid-feedback'>Company is required</div>
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="phone">Phone:*</label><input id="phone" maxlength="40" name="phone" size="20" type="text" placeholder="xxx-xxx-xxxx" value={formValue.phone} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.phone, isTouched.phone)}`} />
                                                {fieldValid.phone === true ?
                                                    <div className='invalid-feedback'>Phone is invalid</div> :
                                                    <div className='invalid-feedback'>Phone is required</div>}
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="email">Email:*</label><input id="email" maxlength="80" name="email" size="20" type="text" value={formValue.email} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.email, isTouched.email)}`} />
                                                {fieldValid.email === true ?
                                                    <div className='invalid-feedback'>Email is invalid</div> :
                                                    <div className='invalid-feedback'>Email is required</div>}
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="option">Option:*</label>   <select id="00NDn000007Ra57" name="00NDn000007Ra57" title="Option" value={formValue.option} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.option, isTouched.option)}`}><option value="">--None--</option><option value="List my property">List my property</option> <option value="Buy a property">Buy a property</option>
                                                    <option value="Connect with agent">Connect with agent</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <div className='invalid-feedback'>Option is required</div>
                                            </div>
                                        </div>
                                        <div className='width-full'>
                                            <div className='form-group'>
                                                <label for="message">Message:*</label> <textarea id="00NDn000007Ra56" name="00NDn000007Ra56" rows="10" type="text" wrap="soft" value={formValue.message} onBlur={this.blurHandler} onChange={this.handleUserInput} className={`form-control ${this.errorClass(formErrors.message, isTouched.message)}`} ></textarea>
                                                <div className='invalid-feedback'>Message is required</div>
                                            </div>
                                        </div>
                                        <div className='width-full'>
                                            <div className='form-group'>
                                                <label for="street">Street:</label> <textarea name="street" className='form-control' value={formValue.street} onChange={this.handleUserInput} ></textarea>
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <div className='form-group'>
                                                <label for="city">City:</label><input id="city" maxlength="40" name="city" size="20" type="text" className='form-control' value={formValue.city} onChange={this.handleUserInput} />
                                            </div>
                                        </div>
                                        <div className='width-half'>
                                            <Row className="contact-form-row">
                                                <div className='width-half'>
                                                    <div className='form-group'>
                                                        <label for="state">State/Province:</label><input id="state" maxlength="20" name="state" size="20" type="text" className='form-control' value={formValue.state} onChange={this.handleUserInput} />
                                                    </div>
                                                </div>
                                                <div className='width-half'>
                                                    <div className='form-group'>
                                                        <label for="zip">Zip:</label><input id="zip" maxlength="20" name="zip" size="20" type="text" className='form-control' value={formValue.zip} onChange={this.handleUserInput} />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <input id="lead_source" name="lead_source" type="hidden" value="Website" />
                                        <input type="submit" className="submit-btn-contactusform" name="submit" ref={this.btnRef} />
                                        <div className='left-submit-btn row'>
                                            <Col md={12}>
                                                <input onClick={this.validateForm} className='button-contactus btn btn-secondary' type="button" value="Submit" />
                                                <Button className="btn-reset" onClick={this.resetForm.bind(this)}>Reset</Button>
                                            </Col>
                                        </div>
                                    </Row>
                                </form>

                                {/* <Form>
                        <FormGroup>
                            <Label>Name *</Label>
                            <Input type="text" name="name" placeholder="Name" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Phone Number *</Label>
                            <Input type="text" name="phonenumber"  placeholder="000-000-0000" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Email *</Label>
                            <Input type="email" name="email" id="exampleEmail" placeholder="Email" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Select Option *</Label>
                            <Input type="select" name="select" id="exampleSelect">
                            <option>List my property</option>
                            <option>Looking to buy a property</option>
                            <option>Connect with agent</option>
                            <option>Other</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleText">Message *</Label>
                            <Input type="textarea" name="text" placeholder="" />
                          </FormGroup>
                          <Button className="button-contactus"><span>Send Email </span></Button>
                        </Form> */}
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}
