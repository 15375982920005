import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody, Label, FormGroup, Container } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { PropertyUpload } from '../PropertyUpload';
import { CONFIG, PROPERTYSTATUS } from '../../../../../Utils/config';
import { getFormattedInt, PRICEOPTION } from "../../../../../Utils/utils";
import { Editor } from "@tinymce/tinymce-react";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './PropertyDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { FilePond, registerPlugin } from 'react-filepond';
import CurrencyFormat from 'react-currency-format';
import edit_r from '../../../../../Assets/Img/edit-buy.png';
import arrow_r from '../../../../../Assets/Img/arrow-right.png';
import upload_r from '../../../../../Assets/Img/upload.png';
import 'filepond/dist/filepond.min.css';
import user_icon from '../../../../../Assets/Img/user-detail.png';
import status_icon from '../../../../../Assets/Img/status-details.png';
import address_icon from '../../../../../Assets/Img/location-details.png';
import phone_icon from '../../../../../Assets/Img/phone-details.png';
import mail_add from '../../../../../Assets/Img/mail-details.png';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import deleteicon from '../../../../../Assets/Img/delete.png';
import vendor_no from '../../../../../Assets/Img/ventor-list.png';
import view_icon from '../../../../../Assets/Img/view.png';
import editicon from '../../../../../Assets/Img/edit.png';
import feature_home from '../../../../../Assets/Img/noimglist.png';
import disable from '../../../../../Assets/Img/disabel.png';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { DealViewModal } from '../../Shared/DealView';
import { DealList } from '../../Shared/DealList';
import Geocode from "react-geocode";
import { AddressNotFound } from '../../Shared/AddressNotFound';
import { AssignSeller } from '../../Shared/AssignSeller';
import { AssignAgent } from '../../Shared/AssignAgent';
import { Pagination } from '../../../../Shared/Pagination';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerPlugin(FilePondPluginFileValidateType);

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

const PropertyWrapped = withStyles(actionsStyles, { withTheme: true })(
  Pagination,
);

const validateRentableSQFT = (value, ctx) => {
  // if (isNaN(ctx.RentableSQFT)) {
  //   return "Rentable Square Feet should be a number";
  // } else 
  if (ctx.RentableSQFT <= 0 && ctx.RentableSQFT != "") {
    return "Rentable Square Feet should be greater than zero";
  }
  return true;
}

const validateAcreage = (value, ctx) => {
  // if (isNaN(ctx.Acreage)) {
  //   return "Acreage should be a number";
  // } else 
  if (ctx.Acreage <= 0 && ctx.Acreage != "") {
    return "Acreage should be greater than zero";
  }
  return true;
}



const propertyValidation = (value, ctx) => {
  if (ctx.PropertyTypeId == "0" || ctx.PropertyTypeId == "") {
    return "Property Type is required";
  }
  return true;
}
// const propertyAltnativeValidation = (value, ctx) => {
//   if (ctx.PriceAlternative == "0" || ctx.PriceAlternative == "") {
//     return "Property Type is required";
//   }
//   return true;
// }


export default class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEdit: false,
      dealId: 0,
      storeId: 0,
      buyerId: 0,
      price: null,
      statusId: 0,
      storeId: parseInt(this.props.match.params.propertyId),
      descError: '',
      store: '',
      storeEdit: '',
      seller: '',
      images: [],
      storeDeals: [],
      documents: [],
      documentTypes: [],
      pageCount: 0,
      addModal: false,
      propertyTypes: [],
      states: [],
      documentTypes: [],
      stateId: 0,
      storeTypeId: 0,
      nestedModal: false,
      descModal: false,
      formModal: false,
      mvpRadius: 0,
      file: '',
      documentTypeId: 0,
      storeDocuments: [],
      description: '',
      sellers: [],
      sellerPageCount: 0,
      sellerCriteria: '',
      sellerPagination: '',
      sellerModal: false,
      agentModal: false,
      searchSellerError: '',
      sellerSelectionInProgress: false,
      isAddressNotFound: false,
      storePrice: '',
      storeRentableSF: '',
      storeAcreage: '',
      isEditVideo: true,
      video: '',
      alternatePriceList: [],
      AlternatePriceOptionID: null,
      AlternatePriceOptionDate: null,
      AlternatePriceOptionDateStr: null,
      AlternatePriceOptionName: '',
      show: false,
      errors: '',
      OffersDueDates:null,
      OffersDueDateStr:null,
      Offerdue:null,
      AlternatePriceOptionDates:null,
    };
    this.getStore = this.getStore.bind(this);
    this.handler1 = this.handler1.bind(this);
    this.toggleDescModal = this.toggleDescModal.bind(this);
    this.toggleFormModal = this.toggleFormModal.bind(this);
    this.valuetext = this.valuetext.bind(this);
    this.refreshImageSection = this.refreshImageSection.bind(this);
    this.deleteStoreDocument = this.deleteStoreDocument.bind(this);
    this.dealView = React.createRef();
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleRentableSQFTChange = this.handleRentableSQFTChange.bind(this);
    this.handleAcreageChange = this.handleAcreageChange.bind(this);
    this.descOnChange = this.descOnChange.bind(this);
    this.onAuctionDateChange = this.onAuctionDateChange.bind(this);
    this.onOfferdueDateChange = this.onOfferdueDateChange.bind(this);
    this.validatePrice = this.validatePrice.bind(this);
  }

  handlePriceChange({ target: { value } }) {
    this.setState(prevState => ({ storePrice: getFormattedInt(value.replace(/,/g, ''), prevState.storePrice) }));
  };

  handleRentableSQFTChange({ target: { value } }) {
    this.setState(prevState => ({ storeRentableSQFT: getFormattedInt(value.replace(/,/g, ''), prevState.storeRentableSQFT) }));
  };

  handleAcreageChange({ target: { value } }) {
    var acreage = value.split('.');
    var formattedacreage = getFormattedInt(acreage[0].replace(/,/g, ''));
    formattedacreage = acreage.length > 1 ? formattedacreage + "." + acreage[1] : formattedacreage;

    this.setState(prevState => ({ storeAcreage: formattedacreage }));
  };

  stateChange(e) {
    var value = e.target.value;
    this.setState({ stateId: value })
  }

  validatePrice(value, ctx) {
    if (ctx.Price.toString().trim() == "" && (ctx.AlternatePriceOptionID == null || ctx.AlternatePriceOptionID == "0")) {

      return "Price or Price Alternative is required";
    }
    return ctx.Price.toString().trim() == "" ? true : true;
  }

  storeTypeChange(e) {
    var value = e.target.value;
    this.setState({ storeTypeId: value })
  }
  alternatePriceOption(e) {
    const { storeEdit } = this.state;
    var value = e.target.value;
    var datePickers = e.target.value === "6" ? true : false;
    this.setState({ AlternatePriceOptionID: value, storeEdit, show: datePickers })
  }
  descOnChange(articleDescription, editor) {
    //var value = e.target.value;
    this.setState({ description: articleDescription });
  }
  onAuctionDateChange(date) {
    const { storeEdit } = this.state;
    var today = date !== undefined && date !== null ? new Date(date) : new Date();
    var dates = date !== undefined && date !== null ? new Date(today.getFullYear(), today.getMonth(), today.getDate()) : null;
    storeEdit.AlternatePriceOptionDate = dates;
    storeEdit.AlternatePriceOptionDateStr = dates;
    this.setState({ AlternatePriceOptionDate: dates, storeEdit });
  }
  onOfferdueDateChange(offerdate) {
    const { storeEdit } = this.state;
    var todays =  offerdate !== undefined && offerdate !== null ? new Date(offerdate) : new Date();
    var offerdue = offerdate !== undefined && offerdate !== null ? new Date(todays.getFullYear(),todays.getMonth(),todays.getDate()) : null;
    storeEdit.OffersDueDate = offerdue;
    storeEdit.OffersDueDateStr = offerdue;
    this.setState({ OffersDueDates : offerdue, storeEdit });
  }

  videoOnChange(e) {
    var value = e.target.value;
    this.setState({ video: value });
  }

  statusChange(e) {
    var value = e.target.value;
    this.setState({ statusId: value });
    this.updateDealStatus(value);
  }

  initialLookup() {
    axios.get(CONFIG.API_URL + 'lookup/propertylookup')
      .then(response => {
        if (response.status === 200) {

          let lookups = response.data;
          this.setState({ propertyTypes: lookups.storeTypes, states: lookups.states, documentTypes: lookups.documentTypes });

        }
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const sighinerror = err.response.data;
          this.setState({ sighinerror });
        }
        else {
          const sighinerror = "Something went wrong.";
          this.setState({ sighinerror });
        }

      });
  }

  updateDesc(event, errors, values) {
    if (errors.length === 0) {
      const data = {
        'Description': this.state.description,
        'StoreID': this.state.storeId
      }
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      this.setState({ isLoading: true });
      axios.post(CONFIG.API_URL + 'admin/store/desc', data)
        .then(response => {

          this.setState({
            descModal: false
          }, () => {
            this.getStore();
          });
        })
        .catch(err => {

          this.setState({ isLoading: false });
          if (err.response != null && err.response.status === 400) {
            const propertyError = err.response.data;
            this.setState({ propertyError });
          }
          else {
            const propertyError = "Something went wrong.";
            this.setState({ propertyError });
          }
        });
    }
  }

  videoAction() {
    const { video, storeId, store } = this.state;
    if (store.VideoCode || (video && video.trim() != '')) {
      const data = {
        'VideoCode': video,
        'StoreID': storeId
      }
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      this.setState({ isLoading: true });
      axios.post(CONFIG.API_URL + 'admin/store/video', data)
        .then(response => {
          this.getStore();
        })
        .catch(err => {

          this.setState({ isLoading: false });
          if (err.response != null && err.response.status === 400) {
            const propertyError = err.response.data;
            this.setState({ propertyError });
          }
          else {
            const propertyError = "Something went wrong.";
            this.setState({ propertyError });
          }
        });
    }
  }

  // markFormGroupTouched(formGroup: FormGroup) {
  //   Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach(control => {
  //     control.markAsTouched();

  //     if (control.controls) {
  //       this.markFormGroupTouched(control);
  //     }
  //   });
  // }
  updateProperty(event, errors, values) {
    //this.myFormRef.markAsTouched();
    const { isRoomForExpansion, storeEdit, AlternatePriceOptionDate } = this.state;
    var priceAlternative = values.Price !== "" ? values.Price : values.AlternatePriceOptionID;
    if ((errors.length === 0 && priceAlternative)) {
      var acreageError = "";
      if (values.Acreage.trim() == "" && values.RentableSQFT.trim() == "") {
        acreageError = 'Acreage or Rentable Square Feet is required';
      }
      var errors = "";
      if (values.AlternatePriceOptionID == "6" && this.state.AlternatePriceOptionDate === null && storeEdit.AlternatePriceOptionDate === null) {
        errors = 'Date is required';
      }
      storeEdit.AlternatePriceOptionDate = values.AlternatePriceOptionID == "6" && storeEdit.AlternatePriceOptionDate !== null ? storeEdit.AlternatePriceOptionDate : null;
      this.state.AlternatePriceOptionDate = storeEdit.AlternatePriceOptionDate ? storeEdit.AlternatePriceOptionDate : this.state.AlternatePriceOptionDate;
      if (values.AlternatePriceOptionID == "6") {
        this.state.AlternatePriceOptionDate = storeEdit.AlternatePriceOptionDate !== null ? storeEdit.AlternatePriceOptionDate : this.state.AlternatePriceOptionDate;
      }
      if (values.AlternatePriceOptionID == "6" || storeEdit.AlternatePriceOptionDateStr !== null) {
        this.state.AlternatePriceOptionDate = storeEdit.AlternatePriceOptionDateStr !== null ? storeEdit.AlternatePriceOptionDateStr : this.state.AlternatePriceOptionDate;
      }
      if (storeEdit.OffersDueDateStr !== null) {
        this.state.OffersDueDates = storeEdit.OffersDueDateStr ? storeEdit.OffersDueDateStr : this.state.OffersDueDateStr ;
      }
      // else {
      //   if (values.Acreage.trim() != "" && isNaN(values.Acreage)) {
      //     acreageError = "Acreage should be a number";
      //   } else if (values.RentableSQFT.trim() != "" && isNaN(values.RentableSQFT)) {
      //     acreageError = "Rentable Square Feet should be a number";
      //   }

      // }

      this.setState({ acreageError, errors });
      if (acreageError == "" && errors === "") {
        const data = {
          'StoreID': this.state.storeId,
          'StoreName': values.StoreName ? values.StoreName.trim() : '',
          'Address1': values.Address1 ? values.Address1.trim() : '',
          'Address2': values.Address2 ? values.Address2.trim() : '',
          'City': values.City ? values.City.trim() : '',
          'StateID': this.state.stateId,
          'ZipCode': values.ZipCode ? values.ZipCode.trim() : '',
          'Country': 'USA',//values.Country,
          'StoreTypeID': this.state.storeTypeId,
          'RentableSQFT': values.RentableSQFT ? values.RentableSQFT.trim().replace(/,/g, '') : '',
          'Acerage': values.Acreage ? values.Acreage.trim().replace(/,/g, '') : '',
          'Price': values.Price !== "" ? values.Price.trim().replace(/,/g, '') : null,
          'IsRoomForExpansion': isRoomForExpansion,
          'IsFeatureListing': values.IsFeatureListing,
          "Latitude": "",
          "Longitude": "",
          'ISActive': storeEdit.ISActive,
          'StatusID': storeEdit.StatusID,
          'AlternatePriceOptionID': this.state.AlternatePriceOptionID === "0" ? null : this.state.AlternatePriceOptionID,
          'AlternatePriceOptionDate': this.state.AlternatePriceOptionID === "6" || values.AlternatePriceOptionID === 6 && this.state.AlternatePriceOptionDate !== null ? this.state.AlternatePriceOptionDate : null,
          'AlternatePriceOptionDateStr': this.state.AlternatePriceOptionID === "6" || values.AlternatePriceOptionID === 6 && this.state.AlternatePriceOptionDate !== null ? this.state.AlternatePriceOptionDate: null,
          'OffersDueDate': this.state.OffersDueDates !== null ? this.state.OffersDueDates :null,
          'OffersDueDateStr': this.state.OffersDueDates !== null ? this.state.OffersDueDates:null,
        }
        const { states, stateId } = this.state;
        var state = states.filter(s => s.ID == stateId);

        var address = values.Address1 + "," + values.City + "," + state[0].Name + "," + "USA";//values.Country;

        Geocode.fromAddress(address).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            data.Latitude = lat;
            data.Longitude = lng;
            this.setState({ isLoading: true });
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.post(CONFIG.API_URL + 'admin/store', data)
              .then(response => {

                this.setState({
                  formModal: false
                }, () => {

                  this.getStore();
                });
              })
              .catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                  const sighinerror = err.response.data;
                  this.setState({ sighinerror });
                }
                else {
                  const sighinerror = "Something went wrong.";
                  this.setState({ sighinerror });
                }

              });
          },
          error => {
            console.error(error);
            //this.saveProperty(event, errors, values, "", "");
            this.setState({ isAddressNotFound: true });
          }
        );
      }
    }
  }



  handler1() {
    this.getStore();
  }

  refreshImageSection() {
    this.getStoreImages();
  }

  getStoreImages() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/storeimage/' + this.state.storeId)
      .then(response => {
        if (response.status === 200) {
          this.setState({ images: response.data });
        }
      }).catch(err => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const propertyError = err.response.data;
          this.setState({ propertyError });
        }
        else {
          const propertyError = "Something went wrong.";
          this.setState({ propertyError });
        }
      });
  }

  getStoreDeals(params) {
    this.setState({ isLoading: true });

    if (params == null) {
      params = this.state.storeDeals.Criteria;
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.post(CONFIG.API_URL + 'admin/store/deals', params)
      .then(res => {
        let storeDeals = res.data;
        this.setState({ storeDeals, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      })
  }

  removeStoreImage(image) {
    confirmAlert({
      title: 'Delete Image',
      message: 'Are you sure want to delete this image?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.delete(CONFIG.API_URL + 'admin/delete/image/' + image + "/" + this.state.storeId)
              .then(response => {
                if (response.status === 200) {
                  this.setState({ images: response.data, isLoading: false });
                }
              }).catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                  const imageError = err.response.data;
                  this.setState({ imageError });
                }
                else {
                  const imageError = "Something went wrong.";
                  this.setState({ imageError });
                }
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });

  }

  getStore() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/store/' + this.state.storeId)
      .then(response => {
        if (response.status === 200) {
          let property = response.data;
          let isRoomForExpansion = property.Store.IsRoomForExpansion != null ? property.Store.IsRoomForExpansion.toString() : null;
          this.setState({
            sellerModal: false, agentModal: false, isLoading: false, video: property.Store.VideoCode, store: property.Store, storeEdit: property.Store, stateId: property.Store.StateID, storeTypeId: property.Store.StoreTypeID, seller: property.Seller,
            images: property.ListedImages, documents: property.Documents, documentTypes: property.DocumentTypes,
            description: property.Store.Description, mvpRadius: property.Store.MVPRadius, isRoomForExpansion: isRoomForExpansion, AlternatePriceOptionID: property.Store.AlternatePriceOptionID, AlternatePriceOptionName: property.Store.AlternatePriceOptionName,
          }, () => {
            const { storeEdit } = this.state;
            storeEdit.Price = storeEdit.Price !== null ? getFormattedInt(storeEdit.Price.toString()) : "";
            storeEdit.AlternatePriceOptionID = storeEdit.AlternatePriceOptionID ? storeEdit.AlternatePriceOptionID : this.state.AlternatePriceOptionID;
            if (storeEdit.Acerage) {
              var acreage = (storeEdit.Acerage.toString()).split('.');
              var formattedacreage = getFormattedInt(acreage[0]);
              storeEdit.Acreage = storeEdit.Acerage ? acreage.length > 1 ? formattedacreage + "." + acreage[1] : formattedacreage : '';
            }
            if (storeEdit.AlternatePriceOptionDate) {
              var today = new Date(storeEdit.AlternatePriceOptionDate);
              storeEdit.AlternatePriceOptionDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            }
            if (storeEdit.OffersDueDate) {
              var today = new Date(storeEdit.OffersDueDate);
              storeEdit.OffersDueDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            }
            // if(storeEdit.AlternatePriceOptionID !== 6){
            //       this.state.AlternatePriceOptionDate = null;
            //        storeEdit.AlternatePriceOptionDate = null;
            // }
            if (storeEdit.AlternatePriceOptionID === PRICEOPTION.Auction) {
              this.setState({ show: true });
            }
            // storeEdit.Acreage = storeEdit.Acerage ? getFormattedInt(storeEdit.Acerage.toString()) : '';
            storeEdit.RentableSQFT = storeEdit.RentableSQFT ? getFormattedInt(storeEdit.RentableSQFT.toString()) : '';
            this.setState({ storeEdit });
          });
          if (property.Documents == null || property.Documents.length == 0
            || !property.Documents.some(doc => (doc.DocumentTypeID == 8))) {
            alert("NDA Document is required to make this property available for the buyer. Please upload.");
          }
        }
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const propertyError = err.response.data;
          this.setState({ propertyError });
        }
        else {
          const propertyError = "Something went wrong.";
          this.setState({ propertyError });
        }
      });
  }

  initialLookup() {
    axios.get(CONFIG.API_URL + 'lookup/propertylookup')
      .then(response => {
        if (response.status === 200) {

          let lookups = response.data;
          this.setState({ propertyTypes: lookups.storeTypes, states: lookups.states, documentTypes: lookups.documentTypes });

        }
      })
      .catch(err => {

        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const propertyError = err.response.data;
          this.setState({ propertyError });
        }
        else {
          const propertyError = "Something went wrong.";
          this.setState({ propertyError });
        }

      });
  }

  addtoggleModal() {
    this.setState(state => ({ addModal: !state.addModal }))
  }

  toggleClose = () => {
    this.myFormRef && this.myFormRef.reset();
    this.setState(state => ({ addModal: false }))
  };

  toggleNestedModal() {
    this.getStoreDocuments();
    this.setState(state => ({ nestedModal: !state.nestedModal, isLoading: false }));
  }

  toggleNestedClose = () => this.setState(state => ({ nestedModal: false, isLoading: false }));

  toggleDescModal() {
    this.setState(state => ({ descModal: !state.descModal }));
  }

  toggleFormModalClose() {
    const { storeEdit } = this.state;
    var datepicker = storeEdit.AlternatePriceOptionID === PRICEOPTION.Auction ? true : false;
    storeEdit.OffersDueDate = this.state.Offerdue !== null ? this.state.Offerdue : null;
    storeEdit.AlternatePriceOptionDate = this.state.AlternatePriceOptionDates !== null ? this.state.AlternatePriceOptionDates : null;
    this.setState(state => ({ formModal: false, isLoading: false, storeEdit, show: datepicker}));
  }
  toggleFormModal() {
    let list = [];
    const { storeEdit, storePrice} = this.state;
    var datepicker = storeEdit.AlternatePriceOptionID === PRICEOPTION.Auction ? true : false;
    var storeEditvalue = storeEdit.Price !== null || storeEdit.Price !== "" ? storeEdit.Price : storePrice;
    this.state.Offerdue = storeEdit.OffersDueDate;
    this.state.AlternatePriceOptionDates = storeEdit.AlternatePriceOptionDate;
    this.setState(state => ({ formModal: !state.formModal, storeEdit, show: datepicker, storePrice: storeEditvalue }));
  }

  toggleDescModalClose = () => this.setState(state => ({ descModal: false, isLoading: false }));

  toggleSellerModal() {
    this.setState(state => ({ sellerModal: !state.sellerModal }));
  }

  toggleAgentModal() {
    this.setState(state => ({ agentModal: !state.agentModal }));
  }


  editDeal = (id, storeId, buyerId, price) => {
    this.setState({ isEdit: true, dealId: id, storeId: storeId, buyerId: buyerId, price: price });
  };

  reFreshGridFromChild = () => {
    this.getStoreDeals(null);
  }

  updateDealStatus = (statusId) => {
    confirmAlert({
      title: 'Update Deal Status',
      message: 'Are you sure want to update this deal status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }

            let endpoint = '';

            switch (statusId) {
              case 1:
                endpoint = "accept";
                break;

              case 2:
                endpoint = "reject";
                break;

              case 3:
                endpoint = "sold";
                break;

              default: break;
            }

            let data = {
              Id: this.state.dealId,
              StoreId: this.state.storeId,
              BuyerId: this.state.buyerId,
              Price: this.state.price
            }

            axios.post(CONFIG.API_URL + 'admin/deal/' + endpoint, data)
              .then(res => {
                this.setState({ isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0, isLoading: false });
                this.getStoreDeals(null);
              })
              .catch(err => {
                this.setState({ isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0, isLoading: false });
                console.log(err);
              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            this.setState({ isEdit: false, dealId: 0, storeId: 0, buyerId: 0, price: 0 });
          }
        }
      ]
    });
  };

  componentDidMount() {
    if ((localStorage.getItem('accessKey') == null || localStorage.getItem('accessKey') == '' || localStorage.getItem('role') != 'Admin')) {
      this.props.history.push({
        pathname: "/admin"
      });
    }
    window.scrollTo(0, 0);
    this.getStore();
    this.initialLookup();
    this.getAlternatePriceOption();
    this.initializeStoreDealParams();
    // this.searchStores(this.initialParam());
    Geocode.setApiKey(CONFIG.GeolocationApiKey);
    Geocode.setLanguage("en");
    Geocode.enableDebug();
  }
  getAlternatePriceOption() {
    axios.get(CONFIG.API_URL + 'lookup/store/alternateprice').then(response => {
      if (response.status === 200) {
        this.setState({ alternatePriceList: response.data });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }
  initializeStoreDealParams() {
    const params = {
      'StoreID': this.state.storeId,
      'PageLength': CONFIG.PAGE_LENGTH,
      'Page': 1
    }

    this.getStoreDeals(params);
  }

  initialParam() {
    const params = {
      'StoreName': '',
      'City': '',
      'State': '',
      'Status': ''
    }
  }

  handleInit() {


  }

  getStoreDocuments() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let storeId = this.storeId != undefined ? this.storeId : this.state.storeId;
    axios.get(CONFIG.API_URL + 'admin/store/documents/' + storeId)
      .then(response => {

        let documents = response.data;
        this.setState({ documents: documents, descModal: false }, () => {
          //this.toggleNestedModal();
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.response != null && err.response.status === 400) {
          const propertyError = err.response.data;
          this.setState({ propertyError });
        }
        else {
          const propertyError = "Something went wrong.";
          this.setState({ propertyError });
        }
      });
  }

  deleteStoreDocument(docId) {
    confirmAlert({
      title: 'Delete Document',
      message: 'Are you sure want to delete this document?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            let storeId = this.storeId != undefined ? this.storeId : this.state.storeId;
            axios.delete(CONFIG.API_URL + 'admin/store/document/' + docId)
              .then(response => {
                this.getStoreDocuments();
              })
              .catch(err => {
                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                  const propertyError = err.response.data;
                  this.setState({ propertyError });
                }
                else {
                  const propertyError = "Something went wrong.";
                  this.setState({ propertyError });
                }
              });
          }
        },
        {
          label: 'No'
          //onClick: () => alert('Click No')
        }
      ]
    });
  }

  valuetext(value) {
    if (value != this.state.mvpRadius) {
      // 
      const data = {
        'StoreID': this.state.storeId,
        'MVPRadius': value
      };
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.post(CONFIG.API_URL + 'admin/store/radius', data)
        .then(response => {
          if (response.status === 200) {
            let mvpRadius = value;
            this.setState({ mvpRadius: mvpRadius }, () => {
              setTimeout(mvpRadius = value, 500);
            });
          }
        })
        .catch(err => {

          if (err.response != null && err.response.status === 400) {
            const propertyError = err.response.data;
            this.setState({ propertyError });
          }
          else {
            const propertyError = "Something went wrong.";
            this.setState({ propertyError });
          }

        });
    }

    return `$${value}`;
  }

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };

  handleDealChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
    this.state.storeDeals.Criteria.page = pageCount + 1;
    this.getStoreDeals(null);
  };

  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  roomExpansionChange = (e) => {
    this.setState({ isRoomForExpansion: e.target.value });
  }

  viewDeal(deal) {
    this.dealView.current.getDeal(deal);
  }


  render() {
    document.title = CONFIG.PAGE_TITLE + 'Property View';
    const { isLoading, isEdit, dealId, store, storeEdit, seller, images, pageCount, storeDeals, documents, states, propertyTypes, documentTypes, storeDocuments,
      descError, description, searchSellerError, sellers, sellerSelectionInProgress, acreageError, imageError, isRoomForExpansion, isAddressNotFound, agentModal, sellerModal, storeId, isEditVideo, video, alternatePriceList, AlternatePriceOptionID, AlternatePriceOptionDate, AlternatePriceOptionName, errors } = this.state;
    return (
      <main className="dashboard-layout-height background-clr-admin">
        {isAddressNotFound ? <AddressNotFound isOpen={isAddressNotFound} /> : ''}
        {sellerModal ? <AssignSeller isOpen={sellerModal} parentMethod={this.getStore} storeID={storeId} /> : ''}
        {agentModal ? <AssignAgent isOpen={agentModal} parentMethod={this.getStore} storeID={storeId} agentIDs={store.BrokerIds} /> : ''}
        <DealViewModal ref={this.dealView} />
        {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
        <div className="property-details-list">
          {/* <PropertyAdd
          isOpen={this.state.isModalOpen}
          toggle={this.state.isModalOpen}
        /> */}

          <div className="leftandright-nomargin">
            {
              store ?
                <React.Fragment>
                  <Row className="property-details-admin">
                    <Col md={8} className="padding-left-right-remove">
                      <div className="heading">
                        <h5>{store.StoreName}<span><Link onClick={() => this.toggleFormModal()}><img src={edit_r} className=" img-icon-buy " title="Edit" alt="edit" /> Edit</Link></span></h5>
                        {/* <div><span className="heading-broder"></span></div> */}
                      </div>

                    </Col>
                    <Col md={4} className="heading-right-buyer">
                      <h6><img src={arrow_r} className="buyer-img" alt="back" title="back to dashboard" /><Link to="/admin/properties" > Back to Dashboard</Link></h6>
                    </Col>
                    <h3></h3>
                    <Modal size="md" id="mdForm" name="mdForm" isOpen={this.state.formModal} toggle={this.toggleFormModalClose.bind(this)} className="create-new edit-market-dashboard price-alternative-added">
                      <ModalHeader toggle={this.toggleFormModalClose.bind(this)}>
                        Update Property
                      </ModalHeader>
                      <ModalBody className="overflow-scroll basic-details">
                        <AvForm model={storeEdit} onSubmit={this.updateProperty.bind(this)} ref={c => (this.myFormRef = c)}>
                          <Row>

                            <Col md={6}>
                              <Col md={12} className="padding-left-0">
                                <AvField name="StoreName" label="Property Name:*" type="text" validate={{
                                  required: { value: true, errorMessage: 'Property Name is required' },
                                  maxLength: { value: 200 }
                                }} />
                              </Col>
                              <Col md={12} className="padding-left-0">
                                <AvField name="Address1" label="Address Line 1:*" validate={{
                                  required: { value: true, errorMessage: 'Address Line 1 is required' },
                                  maxLength: { value: 300 }
                                }} />
                              </Col>
                              <Col md={12} className="padding-left-0">
                                <AvField name="City" label="City:" value={storeEdit.City} validate={{
                                  maxLength: { value: 100 }
                                }} />
                              </Col>
                              <Col md={12} className="padding-left-0">
                                <AvField name="ZipCode" label="Zip Code:" value={storeEdit.ZipCode} validate={{
                                  maxLength: { value: 20 }
                                }} />
                              </Col>
                              <Col md={12} className="padding-left-0">
                                <AvField type="text" name="RentableSQFT" label="Rentable Sqare Feet:" maxLength="9"
                                  validate={{
                                    pattern: { value: /^[0-9,]*$/, errorMessage: 'Rentable Sqare Feet is invalid' }
                                  }}
                                  value={this.state.storeRentableSQFT}
                                  onChange={(e) => this.handleRentableSQFTChange(e)}
                                />
                              </Col>
                              <Col md={12} className="padding-left-0">
                                {/* <AvRadioGroup className="room-for-expansion-font" inline name="IsRoomForExpansion" label="Room For Expansion:">
                                <AvRadio label="Yes" value="true" />
                                <AvRadio label="No" value="false" />
                              </AvRadioGroup> */}
                                <div className="radio-mui-group">
                                  <div className="ch-ra-zip">Room For Expansion:</div>
                                  <div className="clear"></div>
                                  <FormControlLabel control={<Radio
                                    checked={isRoomForExpansion === "true"}
                                    onChange={this.roomExpansionChange.bind(this)}
                                    value="true"
                                    name="IsRoomForExpansion"
                                  />} label="Yes" />
                                  <FormControlLabel control={<Radio
                                    checked={isRoomForExpansion === "false"}
                                    onChange={this.roomExpansionChange.bind(this)}
                                    value="false"
                                    name="IsRoomForExpansion"
                                  />} label="No" />
                                </div>
                              </Col>
                            </Col>
                            <Col md={6}>
                              <Row>
                                <Col xs={6}>
                                  <AvField name="Price" label="Property Value:" type="text" maxLength="13"
                                    validate={{
                                      pattern: { value: /^[0-9,]*$/, errorMessage: 'Property Value is invalid' },
                                      validatePrice: this.validatePrice.bind(this)
                                    }}
                                    value={storeEdit.Price === null ? "" : this.state.storePrice}
                                    onChange={(e) => this.handlePriceChange(e)}
                                  />
                                </Col>
                                <Col xs={6} className="padding-right-0">
                                  <AvField type="select" name="AlternatePriceOptionID" label="Price Alternative:"
                                    value={storeEdit.AlternatePriceOptionID ? storeEdit.AlternatePriceOptionID : this.state.AlternatePriceOptionID}
                                    onChange={(e) => this.alternatePriceOption(e)}
                                  >
                                    <option value="0">--Select--</option>
                                    {alternatePriceList ? alternatePriceList.map(n => {
                                      return (
                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                          disabled={n.ID === '' ? true : false}
                                          value={n.ID}>
                                          {n.Name}
                                        </option>
                                      );
                                    }) : ''}
                                  </AvField>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={6}></Col>
                                {this.state.show ? <Col md={6} className="padding-right-0">
                                  <div className="auction-date">
                                    <DatePicker className="is-touched is-pristine av-valid form-control" placeholderText="Date" selected={storeEdit.AlternatePriceOptionDate  !== null ? new Date(storeEdit.AlternatePriceOptionDate) : this.state.AlternatePriceOptionDate !== null ? new Date(this.state.AlternatePriceOptionDate): this.state.AlternatePriceOptionDate}
                                      dateFormat={"MM-dd-yyyy"} onChange={this.onAuctionDateChange.bind(this)} minDate={new Date()} />
                                  </div>
                                  {errors ? <p className="error error-position">{errors}</p> : ''}
                                </Col>

                                  : ''}

                              </Row>
                              <Col md={12} className="padding-right-0">
                                <AvField name="Address2" label="Address Line 2:" value={storeEdit.Address2} validate={{
                                  maxLength: { value: 100 }
                                }} />

                              </Col>
                              <Col md={12} className="padding-right-0">
                                <AvField type="select" name="State" value={this.state.stateId}
                                  onChange={(e) => this.stateChange(e)}
                                  label="State:*"
                                  validate={{
                                    required: { value: true, errorMessage: 'State is required' },
                                  }}>
                                  <option value="0">--Select--</option>
                                  {states ? states.map(n => {
                                    return (
                                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                        disabled={n.ID === '' ? true : false}
                                        value={n.ID}>
                                        {n.Name}
                                      </option>
                                    );
                                  }) : ''}
                                </AvField>
                              </Col>
                             
                              {/* <Col md={6}>
                              <AvField name="Country" label="Country:" value={storeEdit.Country} validate={{
                                maxLength: { value: 20 }
                              }} />
                            </Col> */}
                              <Col md={12} className="padding-right-0">
                                <AvField type="select" name="PropertyTypeId" value={this.state.storeTypeId}
                                  onChange={(e) => this.storeTypeChange(e)}
                                  label="Property Type:"
                                  validate={{
                                    propertyValidation: propertyValidation
                                  }} >
                                  <option value="0">--Select--</option>
                                  {propertyTypes ? propertyTypes.map(n => {
                                    return (
                                      <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                        disabled={n.ID === '' ? true : false}
                                        value={n.ID}>
                                        {n.Name}
                                      </option>
                                    );
                                  }) : ''}
                                </AvField>
                              </Col>

                              <Col md={12} className="padding-right-0">
                                <AvField name="Acreage" label="Acreage:"
                                  maxLength="9"
                                  validate={{
                                    pattern: { value: /^[0-9,.]*$/, errorMessage: 'Acreage is invalid' }
                                  }}
                                  value={this.state.storeAcreage}
                                  onChange={(e) => this.handleAcreageChange(e)}
                                />
                              </Col>
                              <Col md={12} className="padding-right-0">
                              <div className="auction-date">
                                <Label>Offers Due:</Label>
                                    <DatePicker className="is-touched is-pristine av-valid form-control" placeholderText="Date" selected={storeEdit.OffersDueDate !== null ? new Date(storeEdit.OffersDueDate) : this.state.OffersDueDates  !== null ? new Date(this.state.OffersDueDates) : this.state.OffersDueDates}
                                      dateFormat={"MM-dd-yyyy"} onChange={this.onOfferdueDateChange.bind(this)} minDate={new Date()} />
                                  </div>
                              </Col>
                              <Col md={12} className="property-box">
                                <AvField type="checkbox" name="IsFeatureListing" label="Featured Listing:" value={storeEdit.IsFeatureListing} />
                              </Col>

                            </Col>
                            <Col md={12}>
                              <Typography id="discrete-slider" className="font-mvp-12" gutterBottom>
                                Explorer Radius
                              </Typography>
                              <Slider className="radius-scroll"
                                defaultValue={this.state.mvpRadius}
                                getAriaValueText={this.valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="on"
                                step={1}
                                marks
                                min={1}
                                max={10}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className="save-right">
                              {acreageError ? <p className="error error-position">{acreageError}</p> : ''}
                              <Button id="btn" className="next-btn submit-btn btn-design">Save</Button>
                              <Button className="btn-reset" onClick={this.toggleFormModalClose.bind(this)}>Cancel</Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>

                    </Modal>

                  </Row>
                  <div className="container-padding-remove">
                    <Row>
                      <Col md={8} className="buyer-view-details">
                        <Row className="form-back-shadow">
                          <Col sm={12}>
                            <h4>Property Details</h4>
                          </Col>
                          <Col sm={6} className="half-buyer-view">
                            <h5><span>Address:</span></h5>
                            <h5><Label>{store.Address1}{store.Address2 ? `, ${store.Address2}` : ''}</Label></h5>
                            <h5><span>City and State:</span></h5>
                            <h5><Label>{store.City ? ` ${store.City}` : ''}</Label></h5>
                            <h5><Label>{store.StateCode ? ` ${store.StateCode}` : ''}</Label></h5>
                            <h5><span>Zip Code:</span></h5>
                            <h5><Label>{store.ZipCode ? ` ${store.ZipCode}` : ''}</Label></h5>
                          </Col>
                          <Col sm={6} className="half-buyer-view border-right-remove">
                            <h5><span>Date Listed:</span></h5>
                            <h5><Label>{moment(store.CreatedDate).format("L")}</Label></h5>
                            {store.Price ?
                              <>
                                <h5><span>Price Value:</span></h5>
                                <h5><Label>${store.Price}</Label></h5></> : ""}
                            {store.AlternatePriceOptionID !== null ?
                              <>
                                <h5><span>Price Alternative Value:</span></h5>
                                <h5><Label>{store.AlternatePriceOptionName} {store.AlternatePriceOptionID == PRICEOPTION.Auction && store.AlternatePriceOptionDate !== null ? ' : ' + moment(store.AlternatePriceOptionDate).format("L") : ''}</Label></h5>
                              </> : ""}
                              {store.OffersDueDate !== null ?
                              <>
                              <h5><span>Offers Due:</span></h5>
                              <h5><Label>{moment(store.OffersDueDate).format("L")}</Label></h5>
                              </>:''}
                            <h5><span>Status:</span></h5>
                            <h5><Label>{store.Status}</Label></h5>
                            {/* <h5><Label>{store.StatusID === PROPERTYSTATUS.Available ? 'On Sale' : store.StatusID === PROPERTYSTATUS.DueDiligence ? 'Under Contract' : 'Sold'}</Label></h5> */}
                            <h5><span>Explorer Radius:</span></h5>
                            <h5><Label>{store.MVPRadius}</Label></h5>
                          </Col>
                          <Col sm={12}>
                            <h4 className="display-inline">Listing Agent(s): <h5> <Label>{store.BrokerNames} <Link onClick={this.toggleAgentModal.bind(this)}><img src={edit_r} className=" img-icon-buy " title="Edit" alt="edit" /> Change</Link> </Label></h5></h4>
                          </Col>
                        </Row>
                      </Col>
                      {
                        seller ?
                          <Col md={4} className=" buyer-view-details ">
                            <div className={(store.OffersDueDate && store.Price && store.AlternatePriceOptionName ) ? "form-back-shadow lg-increase height-increase" : (store.OffersDueDate && store.AlternatePriceOptionName ) || (store.Price && store.AlternatePriceOptionName ) || (store.OffersDueDate && store.Price )? "form-back-shadow length-increase height-increase" : "form-back-shadow height-increase"}>
                              <p className="details-heading">Details</p>
                              <h5><span><img src={user_icon} className="img-bottom" alt="" /> Property Owner:</span></h5>
                              <h5><Label>{seller.FirstName + " " + seller.LastName} <Link onClick={this.toggleSellerModal.bind(this)}><img src={edit_r} className=" img-icon-buy " title="Edit" alt="edit" /> Edit</Link> </Label></h5>
                              <h5><span><img src={phone_icon} className="img-bottom" alt="" /> Phone:</span></h5>
                              <h5><Label>{seller.PhoneNumber}</Label></h5>
                              <h5><span><img src={mail_add} className="img-bottom" alt="" /> Email:</span></h5>
                              <h5><Label>{seller.Email}</Label></h5>
                              <h5><span><img src={status_icon} className="img-bottom" alt="" /> Status: {seller.StatusID == 2 ? 'Active' : 'InActive'}</span></h5>
                            </div>
                          </Col>
                          : <Col md={4} className=" buyer-view-details ">
                            <div className={(store.OffersDueDate && store.Price && store.AlternatePriceOptionName ) ? "form-back-shadow lg-increase height-increase" : (store.OffersDueDate && store.AlternatePriceOptionName ) || (store.Price && store.AlternatePriceOptionName ) || (store.OffersDueDate && store.Price ) ? "form-back-shadow length-increase height-increase" : "form-back-shadow height-increase"}>
                              <p className=""><Link className="assign-seller-button" onClick={this.toggleSellerModal.bind(this)}>[+] Assign Seller</Link></p>
                            </div>
                          </Col>}
                    </Row>
                  </div>
                  <div className="form-back-shadow">
                    <Row className="button-property-image">
                      <Col>
                        <h5>Listed Images <PropertyUpload parentMethod={this.refreshImageSection} storeId={this.state.storeId} documentId={0} /></h5>
                      </Col>
                    </Row>
                    <Row className=" box-shadow-border img-border-property">

                      {imageError ? <p>{imageError}</p> : ''}

                      {
                        images && images.length > 0 ?
                          images.map((image) =>
                            <Col md={2} className="mobile-property">
                              <div className="image-resize-propertydetails">
                                <img className="img-size-propertydetails" src={'/StoreImages/' + image.FileName} onError={(e) => { e.target.onerror = null; e.target.src = feature_home }} alt={store.StoreName} />
                              </div>
                              <div class="type-container" onClick={() => this.removeStoreImage(image.ID)}><img src={deleteicon} alt="delete" title="Delete" /></div>
                            </Col>
                          )
                          : <Container><Row><Col md={12} className="img-center-property"><img src={vendor_no} ></img></Col></Row></Container>
                      }

                    </Row>

                    <Row className="property-details-description">
                      <Col md={12}>
                        <h5>Property Description <Link onClick={() => this.toggleDescModal()}><span><img src={edit_r} className=" img-icon-buy " title="Edit" alt="edit" /> Edit</span></Link></h5>
                        {/* <pre>
                          {store.Description}
                        </pre> */}
                        <div className="p-tag-property" dangerouslySetInnerHTML={{ __html: store.Description }}></div>
                      </Col>
                      <Col md={12}>
                        <h5>Property Video <Link onClick={() => this.videoAction(isEditVideo)}><span><FontAwesomeIcon icon={faSave} className="icon-clr" /> Save</span></Link></h5>
                        <textarea className="table-text-area border-clr" onChange={(e) => this.videoOnChange(e)} value={video} />
                      </Col>

                      <Modal size="md" id="mdDisc" name="mdDisc" isOpen={this.state.descModal} toggle={this.toggleDescModalClose.bind(this)} backdrop="static" className="create-new edit-market-dashboard">
                        <ModalHeader toggle={this.toggleDescModalClose.bind(this)}>
                          Update Description
                        </ModalHeader>
                        <ModalBody className="overflow-scroll basic-details">

                          <AvForm onSubmit={this.updateDesc.bind(this)} ref={c => (this.form = c)}>
                            <Row>
                              <Col md={12} className="property-description">
                                {/* <AvField name="Description" label="Description" type="text" /> */}
                                {/* <textarea className="table-text-area" onChange={(e) => this.descOnChange(e)} value={description} /> */}
                                <Editor
                                  value={description}
                                  apiKey={CONFIG.TINY_MCE_KEY}
                                  init={{
                                    height: 300,
                                    menubar: true,
                                    plugins: "link",
                                  }}
                                  onEditorChange={this.descOnChange}
                                />
                              </Col>
                              <Col md={12} className="save-right">
                                <Button color="primary" className="update-button-descripe">Update</Button>
                                <span className="sign-error">{descError}</span>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                    </Row>

                    <Row>
                      <Col md={12} className="table-model table-padding heading-table-name margintop-10 marginbottom-10 publish-property-table">
                        <h5>Deal Information</h5>
                        {storeDeals.Deals && storeDeals.Deals.length > 0 ?
                          <Table>
                            <DealList deal={storeDeals} triggerFromChild={this.reFreshGridFromChild} isDealTracker={false} />
                            {/* <TableHead>
                              <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Deal Name</TableCell>
                                <TableCell>Buyer</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Proposed</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {storeDeals.Deals ? storeDeals.Deals.map((deal) =>
                                <TableRow key={deal.ID}>
                                  <TableCell>{moment(deal).format("L")}</TableCell>
                                  <TableCell>{deal.DealName} </TableCell>
                                  <TableCell>{deal.Buyer}</TableCell>
                                  <TableCell>{deal.City}</TableCell>
                                  <TableCell>{deal.State}</TableCell>
                                  <TableCell>${deal.Price}</TableCell>
                                  <TableCell>
                                    {isEdit == true && dealId === deal.ID ?
                                      <Select labelId="demo-simple-select-filled-label" className="select-deal" id="demo-simple-select-filled"
                                        value={this.state.statusId} onChange={(e) => this.statusChange(e)}>
                                        <MenuItem value="0">-Select-</MenuItem>
                                        {status ? status.map(n => {
                                          return (
                                            <MenuItem key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                              disabled={n.ID === '' ? true : false}
                                              value={n.ID}>
                                              {n.Name}
                                            </MenuItem>
                                          );
                                        }) : ''}
                                      </Select>
                                      : deal.DealStatus
                                    }
                                  </TableCell>
                                  <TableCell>
                                  <div className="disply-dealbtn">
                                  
                                    {(deal.DealStatusID === 1 || deal.DealStatusID === 4) && store.StatusID !== 2 ?
                                      <Button className="no-button-background" onClick={() => this.editDeal(deal.ID, deal.StoreID, deal.BuyerID, deal.Price)}><img src={editicon} alt="edit" title="Edit" /></Button>
                                      : <Button className="no-button-background"><img src={disable} alt="edit" title="No Edit" /></Button>}
                                      
                                    <Button type="view" className="no-button-background" onClick={this.viewDeal.bind(this, deal)}><img src={view_icon} alt="view" title="View" /></Button>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ) :
                                <TableRow></TableRow>
                              }
                            </TableBody> */}
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  rowsPerPageOptions={[1]}
                                  colSpan={8}
                                  count={storeDeals.Deals ? storeDeals.Pagination.TotalRecords : 0}
                                  rowsPerPage={CONFIG.PAGE_LENGTH}
                                  page={pageCount}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  onChangePage={this.handleDealChangePageTrack}
                                  onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                                  ActionsComponent={PropertyWrapped}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                          : <h6 className="no-records-found no-found">No records found</h6>}
                      </Col>
                    </Row>

                    <Row>

                      <Col md={12} className="table-model table-padding heading-table-name">
                        <h5>Property Documents</h5>
                        {documentTypes && documentTypes.length > 0 ?
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Document Name</TableCell>
                                <TableCell>Action</TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                documentTypes.map((docType) =>
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell>{docType.Name}</TableCell>
                                      <TableCell>
                                        {docType.ID == 8 ?
                                          documents.some(doc => (doc.DocumentTypeID == 8)) ? '' : <PropertyUpload parentMethod={this.handler1} storeId={this.state.storeId} documentId={docType.ID} />
                                          : <PropertyUpload parentMethod={this.handler1} storeId={this.state.storeId} documentId={docType.ID} />
                                        }
                                      </TableCell>

                                    </TableRow>
                                    <TableRow key={docType.ID}>
                                      <TableCell colspan="2" className="padding-add-b10">
                                        {documents.some(doc =>
                                          (doc.DocumentTypeID == docType.ID)) ?
                                          <Table className="inside-table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>Uploaded Date</TableCell>
                                                <TableCell>View</TableCell>

                                                <TableCell>Action</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {documents.map((doc) =>
                                                doc.DocumentTypeID === docType.ID ?
                                                  <TableRow>
                                                    <TableCell>
                                                      {doc.DocumentTypeID === 8 && doc.ModifiedDate !== null ?
                                                        moment(doc.ModifiedDate).format("L")
                                                        :
                                                        moment(doc.CreatedDate).format("L")
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      <a target="_blank" rel="noopener noreferrer" href={'/StoreDocuments/' + doc.FileName} download={doc.ActualFileName != null ? doc.ActualFileName : doc.FileName} className="link-view">View</a>
                                                    </TableCell>

                                                    <TableCell>
                                                      {doc.DocumentTypeID == 8 ?
                                                        <PropertyUpload parentMethod={this.handler1} storeId={this.state.storeId} documentId={docType.ID} documentName="NDA" />
                                                        : <Link onClick={this.deleteStoreDocument.bind(this, doc.ID)}>Remove</Link>
                                                      }
                                                    </TableCell>
                                                  </TableRow>
                                                  : '')}
                                            </TableBody>
                                          </Table>
                                          : ''}
                                      </TableCell>
                                    </TableRow>

                                  </React.Fragment>
                                )
                              }
                            </TableBody>
                          </Table>
                          : ''
                        }
                      </Col>

                    </Row>
                  </div>
                </React.Fragment>
                : ''}

          </div>
        </div>
      </main >
    );
  }
}
