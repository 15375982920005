import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../../../../Utils/config';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { CreateUser } from '../CreateUser';
import { Link } from "react-router-dom";
import './AssignSeller.css';
import { Pagination } from '../../../../Shared/Pagination';

const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});


const StoreWrapped = withStyles(actionsStyles, { withTheme: true })(
    Pagination,
);

export default class AssignSeller extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createUser: false,
            isOpen: props.isOpen,
            pageCount: 0,
            storeId: props.storeID,
            sellerSelectionInProgress: false,
        }
        this.getAllSellers = this.getAllSellers.bind(this);
        this.getSellers = this.getSellers.bind(this);
    }

    componentDidMount() {
        const { isOpen } = this.state;
        if (isOpen) {
            this.getAllSellers();
        }
    }

    toggleSellerModalClose = () => {
        this.setState({ isOpen: false, isLoading: false }, () => {
            this.props.parentMethod();
        });
    }

    searchSellers(event, errors, values) {
        if (errors.length === 0) {
            const params = {
                'CompanyName': values.CompanyName,
                'City': values.City,
                'State': values.State,
                'StoreId': this.state.storeId,
                'Page': 1,
                'PageLength': CONFIG.PAGE_LENGTH,
                'SortBy': 'FirstName',
                'SortOrder': 'ASC'
            }
            this.setState({ pageCount: 0 });
            this.getSellers(params);
        }
    }

    getAllSellers() {
        let data = {
            CompanyName: '',
            City: '',
            State: '',
            StoreID: this.state.storeId,
            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: 'FirstName',
            SortOrder: 'ASC'
        }
        this.setState({ pageCount: 0, createUser: false, isOpen: true, createUser: false });
        this.getSellers(data);
    }

    getSellers(data) {
        if (data == null) {
            data = this.state.sellerCriteria;
        }

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.setState({ searchSellerError: '' });

        axios.post(CONFIG.API_URL + 'admin/sellers', data)
            .then(response => {

                let result = response.data;
                this.setState({
                    sellers: result.Sellers, sellerCriteria: result.Criteria, sellerPagination: result.Pagination
                });
            })
            .catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                    const searchSellerError = err.response.data;
                    this.setState({ searchSellerError });
                }
                else {
                    const searchSellerError = "Something went wrong.";
                    this.setState({ searchSellerError });
                }

            });
    }

    resetSellerSearch() {
        this.formSeller && this.formSeller.reset();
        this.getAllSellers();
    }

    setSeller(sellerId) {

        this.setState({ sellerSelectionInProgress: true });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        let data = {
            SellerId: sellerId,
            StoreIds: [this.state.storeId],
            "IsAssignSeller": true
        }

        axios.post(CONFIG.API_URL + 'admin/seller/store', data)
            .then(response => {

                this.setState({
                    sellerSelected: true
                }, () => {
                    this.toggleSellerModalClose();
                });
            })
            .catch(err => {

                this.setState({ isLoading: false });
                if (err.response != null && err.response.status === 400) {
                    const searchSellerError = err.response.data;
                    this.setState({ searchSellerError, sellerSelectionInProgress: false });
                }
                else {
                    const searchSellerError = "Something went wrong.";
                    this.setState({ searchSellerError, sellerSelectionInProgress: false });
                }

            });
    }

    handleSellerChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount });
        this.state.sellerCriteria.page = pageCount + 1;
        this.getSellers(null);
    };

    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    createUserModel() {
        this.setState({ createUser: true });
    }

    render() {
        const { isOpen, sellers, createUser, searchSellerError,
            sellerSelectionInProgress, sellerSelected, pageCount } = this.state;
        if (createUser) {
            return (
                <CreateUser isOpen={createUser} parentMethod={this.getAllSellers} />
            );
        } else {
            return (
                <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isOpen} toggle={this.toggleSellerModalClose.bind(this)} className="create-new edit-market-dashboard">
                    <ModalHeader toggle={this.toggleSellerModalClose.bind(this)}>Assign Seller
                  </ModalHeader>
                    <ModalBody className="overflow-scroll basic-details">

                        <AvForm onSubmit={this.searchSellers.bind(this)} ref={c => (this.formSeller = c)}>
                            <Row>
                                <Col md={6}>
                                    <AvField name="CompanyName" label="First Name:" type="text" />
                                </Col>
                                <Col md={6}>
                                    <AvField name="City" label="City:" type="text" />
                                </Col>
                                <Col md={12} className="save-right">
                                    <Button color="primary" >Search</Button>
                                    <Button className="btn-reset" onClick={this.resetSellerSearch.bind(this)}>Reset</Button>
                                    <span className="sign-error">{searchSellerError}</span>
                                </Col>
                            </Row>
                            <Row className="add-new-btn">
                                <Link onClick={this.createUserModel.bind(this)}>[+] Add New Seller</Link>
                            </Row>
                        </AvForm>
                        <div className="table-scroll table-padding-assignseller table-assign-seller">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="width-80seller">Select</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell className="email-width">Email</TableCell>
                                        <TableCell>State</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sellers && sellers.length > 0 ? sellers.map((seller) =>
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell className="width-80seller">
                                                    {
                                                        sellerSelectionInProgress === false ?
                                                            <Button className="view-btn" onClick={() => this.setSeller(seller.ID)}>Select</Button>
                                                            :
                                                            <Label>Select</Label>
                                                    }
                                                </TableCell>
                                                <TableCell>{seller.FirstName}</TableCell>
                                                <TableCell>{seller.LastName}</TableCell>
                                                <TableCell className="email-width">{seller.Email}</TableCell>
                                                <TableCell>{seller.StateName}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                        :
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell colSpan="5">
                                                    <h6 className="no-records-found">No sellers found</h6>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={1}
                                            colSpan={8}
                                            count={sellers ? this.state.sellerPagination.TotalRecords : 0}
                                            rowsPerPage={CONFIG.PAGE_LENGTH}
                                            page={pageCount}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            onChangePage={this.handleSellerChangePageTrack}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                                            ActionsComponent={StoreWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </ModalBody>
                </Modal>
            );
        }
    }
}