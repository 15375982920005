export function getFormattedFloat(number) {
    return parseFloat(number).toLocaleString('en-US');
}

export function getFormattedInt(number) {
    var value = parseInt(number).toLocaleString('en-US');

    return value != "NaN" ? value : '';
}

export function getFormattedDecimal(number) {
    let dotSplit = number.split(".");
    var formattedNumber = getFormattedInt(dotSplit[0].replace(/,/g, ''));
    formattedNumber = dotSplit.length > 1 ? formattedNumber + "." + dotSplit[1] : formattedNumber;

    return formattedNumber != "NaN" ? formattedNumber : '';
}

export const DATERANGE = {
    1: "Last week (by day)",
    2: "Last 30 days (by week)",
    3: "Last 90 days (by week)",
    4: "Last 6 months (by month)",
    5: "Last 12 months (by month)"
}

export function getPager(totalItems, currentPage = 1, pageSize) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage, endPage;
    if (totalPages <= 5) {
        // less than 5 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 5 total pages so calculate start and end pages
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // calculate start and end record count
    const startRecordCount = (currentPage - 1) * pageSize + 1;
    let endRecordCount = totalItems;
    if (pageSize < totalItems) {
        endRecordCount = pageSize * currentPage;
        if (endRecordCount > totalItems) {
            endRecordCount = totalItems;
        }
    }

    // create an array of pages to ng-repeat in the pager control
    const range = (start, end) => Array.from(Array(end - start + 1), (_, i) => start + i);
    const pages = range(startPage, endPage);
    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        startRecordCount: startRecordCount,
        endRecordCount: endRecordCount,
        pages: pages
    };
}

export function normalizeInput(value, previousValue) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    } else {
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

export function formatPhoneNumber(phoneNumberString) {
    let cleaned = phoneNumberString.replace(/[^\d]/g, '');
    const cvLength = cleaned.length;
    if (cvLength > 7 && cvLength < 10) {
        return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    } else if (cvLength > 4 && cvLength < 7) {
        return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    }
    return phoneNumberString;
};

export const PRICEOPTION = {
    MarketBid: 1,
    PriceUndisclosed: 2,
    PriceDeterminedbyMarket: 3,
    ContactBrokerforPrice: 4,
    CallforOffers: 5,
    Auction: 6
}